import Repository from "./Repository";
import Category from '@/models/Category';

const resource = "/v1/categories/";

class CategoryRepository {
  async get(): Promise<Category[]> {
    const response = await Repository.get(resource);
    return await response.json();
  }
}

export default new CategoryRepository();
