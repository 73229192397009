





























import { YCMediaSource } from "@/models/Media"
import { Vue, Component, Prop, Watch } from "vue-property-decorator"

@Component({})
export default class EventVideo extends Vue {
  @Prop({ type: Object, required: true }) src!: YCMediaSource
  showVideo: boolean = false // used for Safari

  get thumbnailUrl(): string | null {
    const url = new URL(this.src.url)
    let filename = url.searchParams.get("filename")
    if (!filename) return null
    filename = filename.substring(0, filename.lastIndexOf("."))
    url.searchParams.set("filename", `${filename}_thumb.webp`)
    return url.toString()
  }

  get isVideoCompatibleWithBrowser(): boolean {
    /* We are only supporting mp4 for any browser and mov for safari only. 
    In any other case, display of video needs to be blocked */
    const filename = new URL(this.src.url).searchParams.get("filename")
    const extension = filename?.substring(filename.lastIndexOf(".") + 1).toLowerCase()
    const isMp4: boolean = extension == "mp4"
    const isMov: boolean = extension == "mov"
    const isSafari: boolean = this.$browserDetect.isSafari
    return isMp4 || (isMov && isSafari)
  }

  @Watch("showVideo")
  onShowVideoChange() {
    this.$nextTick(() => {
      ;(this.$refs.video as HTMLVideoElement).play()
    })
  }
}
