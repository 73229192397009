import Repository from "./Repository";
import ProgressMarker from '@/models/ProgressMarker';

const resource = "/v1/progressmarker/";

class ProgressMarkerRepository {
  async get(): Promise<ProgressMarker | null> {
    const response = await Repository.get(resource);

    if(response.status == 200)
        return await response.json();
    return null
  }
}

export default new ProgressMarkerRepository();
