import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"yc_eventform_div"},[_c('h3',[_vm._v(_vm._s(_vm.$t("location")))]),_c(VContainer,{staticClass:"yc_eventform_cards"},[_c(VRow,{attrs:{"justify":"center"}},_vm._l((_vm.locations),function(location,index){return _c(VCol,{key:'locations' + index,staticClass:"col-sm-4 col-md-3 col-lg-3 col-xl-2",attrs:{"data-cy":"locationdetails","cols":6}},[_c(VCard,{staticClass:"yc_eventform_card",class:[
            { yc_eventform_card_selected: _vm.value == location },
            location === 'clinic' ? 'clinic_event' : 'home_event' ],attrs:{"outlined":"","raised":""},on:{"click":function($event){_vm.$emit('input', location), _vm.$emit('nextStep')}}},[_c('div',{staticClass:"yc_card_vertical_center"},[_c('i',{staticClass:"yc_eventform_location_icon",class:location === 'clinic' ? 'ico-stationary' : 'ico-home'}),_c('div',{staticClass:"mt-1"},[_c('i18n',{attrs:{"path":location}},[_vm._v("­")])],1)])])],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }