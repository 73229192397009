




















































































import TrackingRepository from "@/api/TrackingRepository"
import { Vue, Component, Watch } from "vue-property-decorator"
import PrivacySettings from "../../models/PrivacySettings"

@Component({})
export default class Privacy extends Vue {
  settingPresets: SettingPreset[] = [
    { name: "just_me", show: false, advanced: false },
    { name: "meet_others", show: false, advanced: true }
  ]
  showAdvancedSettings: boolean = false
  optionsRadioGroup: string = ""

  get availableSettings(): PrivacySettings {
    return this.$store.getters.getPrivacySettings
  }
  get settingKeys(): string[] {
    return Object.keys(this.availableSettings)
  }
  get userIsSearchable(): boolean {
    return this.$store.getters.userIsSearchable
  }

  async updateSetting(name: keyof PrivacySettings, type: String): Promise<void> {
    let newSetting = {} as PrivacySettings
    let currSetting = this.availableSettings[name]
    if (type === "friend")
      newSetting[name] = { friend: !currSetting.friend, foreign: currSetting.foreign }
    else newSetting[name] = { friend: currSetting.friend, foreign: !currSetting.foreign }
    this.$store.dispatch("updatePrivacySettings", newSetting)

    const groupID = "SETT:"
    let setting = "" + this.$t("settings." + name)
    setting = setting.replaceAll("\n", " ")
    await TrackingRepository.createLog(
      groupID +
        "Ändern der Privacy '" +
        setting +
        "' für Freunde '" +
        newSetting[name].friend +
        "' und Fremde '" +
        newSetting[name].foreign +
        "'"
    )
  }

  @Watch("optionsRadioGroup")
  onOptionsRadioGroupChange(): void {
    let searchable = this.optionsRadioGroup === "just_me" ? false : true
    this.$store.dispatch("changeUserSearchable", searchable)
  }

  mounted() {
    // if(this.$store.getters.userIsSearchable)
    this.optionsRadioGroup = this.userIsSearchable ? "meet_others" : "just_me"
  }
}

interface SettingPreset {
  name: string
  show: boolean
  advanced: boolean
}
