















import { Component, Vue, Prop } from 'vue-property-decorator'
import LoadingSpinner from "@/components/LoadingSpinner.vue";

@Component({
    components: {
        LoadingSpinner
    }
})
export default class StepperBar extends Vue {
    @Prop({type: Number, default: 7}) length!: number
    @Prop({type: Number, default: 4}) active!: number
    @Prop({type: Boolean, default: false}) disableLeft!: boolean
    @Prop({type: Boolean, default: false}) disableRight!: boolean
    @Prop({type: Boolean, default: true}) showDots!: boolean
    @Prop({type: Boolean, default: false}) disableSpecial!: boolean
    @Prop({type: Boolean, default: false}) showSpecial!: boolean
    @Prop({type: Boolean, default: false}) processingSpecial!: boolean
}
