import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VOverlay,{attrs:{"value":_vm.showDialog || _vm.showInstallGuide}},[_c(VDialog,{attrs:{"persistent":""},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[(!_vm.showInstallGuide)?_c(VCard,[_c(VCardTitle,[_vm._v(_vm._s(_vm.$t("pwa.install.title")))]),_c(VCardText,[_vm._v(_vm._s(_vm.$t("pwa.install.prompt")))]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){return _vm.close(false)}}},[_vm._v(" "+_vm._s(_vm.$t("pwa.install.cancel"))+" ")]),_c(VBtn,{attrs:{"color":"green darken-1","text":""},on:{"click":_vm.install}},[_vm._v(" "+_vm._s(_vm.$t("pwa.install.button"))+" ")])],1)],1):(_vm.$browserDetect.isFirefox)?_c('GuideFirefox',{on:{"close":function($event){return _vm.close(false)}}}):(_vm.$browserDetect.isIOS)?_c('GuideSafari',{on:{"close":function($event){return _vm.close(false)}}}):(_vm.$browserDetect.isOpera)?_c('GuideOpera',{on:{"close":function($event){return _vm.close(false)}}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }