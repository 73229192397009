import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardTitle,[_vm._v(_vm._s(_vm.$t("pwa.install.instructions.title", { browser: "Safari" })))]),_c(VCardText,[_c('div',[_c('i18n',{attrs:{"path":"pwa.install.instructions.safari[0]"}},[_c(VIcon,[_vm._v("mdi-export-variant")])],1),_c('img',{attrs:{"src":_vm.instructionImages[0]}}),_vm._v(" "+_vm._s(_vm.$t("pwa.install.instructions.safari[1]"))+" "),_c('img',{attrs:{"src":_vm.instructionImages[1]}}),_vm._v(" "+_vm._s(_vm.$t("pwa.install.instructions.safari[2]"))+" "),_c('img',{attrs:{"src":_vm.instructionImages[2]}}),_vm._v(" "+_vm._s(_vm.$t("pwa.install.instructions.final[1]"))+" ")],1)]),_c(VCardActions,[_c(VBtn,{attrs:{"text":""},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(_vm._s(_vm.$t("close")))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }