import { Module } from "vuex";
import { ConnectionState, RootState } from "../types";

const connectionModule: Module<ConnectionState, RootState> = {
    state: {
        isLoading: false
    },
    mutations: {
        changeIsLoading(state, value: Boolean){
            state.isLoading = value;
        }
    },
    actions: {
        changeIsLoading({commit}, value: Boolean){
            commit('changeIsLoading', value);
        }
    },
    getters: {
        loadingInProgress(state){
            return state.isLoading;
        }
    }
}

export default connectionModule;