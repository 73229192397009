


























































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import LocationInput from "@/components/event_form/LocationInput.vue";
import CategoryInput from "@/components/event_form/CategoryInput.vue";
import ActivityInput from "@/components/event_form/ActivityInput.vue";
import DateInput from "@/components/event_form/DateInput.vue";
import MoodInput from "@/components/event_form/MoodInput.vue";
import DifficultyInput from "@/components/event_form/DifficultyInput.vue";
import MediaInput from "@/components/event_form/MediaInput.vue";
import XPNotification from "@/components/onboarding/XPNotification.vue";
import StepperBar from "@/components/StepperBar.vue";
import EventRepository from "@/api/EventRepository";
import CategoryRepository from "@/api/CategoryRepository";
import Content from "@/models/Content";
import UploadMediaContent from "@/models/UploadMediaContent";
import Event from "@/models/Event";
import Category from "@/models/Category";
import OnboardingHints from "../models/OnboardingHints";
import TrackingRepository from "@/api/TrackingRepository";
import Activity from "@/models/Activity";

@Component({
  components: {
    LocationInput,
    CategoryInput,
    ActivityInput,
    DateInput,
    MoodInput,
    DifficultyInput,
    MediaInput,
    // LoadingSpinner,
    XPNotification,
    StepperBar,
    // PublicationInput
  }
})
export default class EventForm extends Vue {
  @Prop({type: Object}) oldEvent: Event | undefined
  @Prop({type: Boolean, default: false}) firstEvent!: boolean
  @Prop({type: String, required: true}) headline!: string
  @Prop({type: Boolean, default: false}) showEventForm!: boolean
  @Prop({type: Boolean, default: false}) fullscreen!: boolean
  step: number = 1
  prevSteps: number[] = []
  numOfSteps: number = 7
  disableBottomButtons: boolean = false
  //dateChosen: boolean = false
  //timeChosen: boolean = false
  event: any = {
    id: "",
    location: "",
    category: -1,
    activity: null,
    datetime: new Date(),
    mood: -1,
    difficulty: -1,
    text: null,
    publication: false,
    publicationMedia: false,
  }
  categories: Category[] = []
  editMode: boolean = false
  showDiscardDialog: boolean = false
  contents: UploadMediaContent[] = []
  contentId: number = -1
  submitting: boolean = false
  showXPHint: boolean = false

  created(): void {
    this.fetchCategories();
    this.editMode = this.oldEvent ? true : false;
    if (this.editMode) this.fillEventValues();
  }
  fillEventValues(): void {
    this.event.id = this.oldEvent!.id;
    this.event.location = this.oldEvent!.location;
    this.event.category = (this.oldEvent!.activity as Activity).category;
    this.event.mood = this.oldEvent!.mood;
    this.event.difficulty = this.oldEvent!.difficulty;
    this.event.datetime = new Date(this.oldEvent!.datetime);
    this.event.text = this.oldEvent!.text;
    this.event.activity = this.oldEvent!.activity;
    this.dispatchContents();
  }
  dispatchContents(): void {
    var contents = this.oldEvent!.contents as Content[];
    if (contents.length == 0) return;
    this.contents = contents.map(
      (c) =>
        ({
          media_id: c.id,
          action_id: 0,
          url: c.file,
          content_type: c.content_type,
        } as UploadMediaContent)
    );
  }
  updateContents(mediaContents: UploadMediaContent[]): void {
    JSON.stringify(mediaContents, null, 2);
    this.contents = mediaContents;
  }
  prev(): void {
    var prev = this.prevSteps.pop();
    if (!prev) {
      prev = 1;
    }
    this.step = prev;
  }
  next(): void {
    this.prevSteps.push(this.step);

    if (this.step == 1) {
      if (this.event.location !== "clinic") {
        this.loadOtherCategory();
        this.step += 2;
        return;
      }
    }

    this.step++;
  }
  isValid(): boolean {
    if (this.disableBottomButtons) return false;
    switch (this.step) {
      case 1:
        return this.event.location && this.event.location.length > 0;
      case 2:
        return this.event.category > -1;
      case 3:
        return this.event.activity !== null;
      case 5:
        return this.event.mood != -1;
      case 6:
        return this.event.difficulty > -1;
      default:
        return true;
    }
  }

  async submit(): Promise<void> {
    this.submitting = true;
    const newEvent = this.event;
    let activity = newEvent.activity || { id: -1 };
    const payload = {
      location: newEvent.location,
      activity: activity.id,
      datetime: newEvent.datetime.toISOString(),
      mood: newEvent.mood,
      difficulty: newEvent.difficulty,
      text: newEvent.text || "",
    } as Event;

    try {
      let createdEvent: Event;
      if (!this.editMode) {
        createdEvent = await EventRepository.createEvent(payload);
        this.$store.dispatch("addEvent", createdEvent);
          this.logSave(false)
      } else {
        createdEvent = await EventRepository.updateEvent(
          this.oldEvent!.id,
          payload
        );
        this.$store.commit("updateEvent", createdEvent);
        this.logSave(true)
      }

      const addContents = this.getContentFilesToAdd();

      let promises = [];

      if (addContents.length > 0) {
        let formData = new FormData();
        addContents.forEach((content) => {
          formData.append("file", content);
        });
        promises.push(
          EventRepository.createContent(createdEvent.id, formData)
        );
      }

      const removeContentIds = this.getContentToDelete();
      removeContentIds.forEach((c) => {
        promises.push(
          EventRepository.deleteContent(createdEvent.id, c.url, c.media_id)
        );
      });
      await Promise.all(promises);

      if (this.$store.getters.showOnboardingChat) this.showXPHint = true;
      else this.$emit("eventChanged", createdEvent.id);
    } catch (error) {
      this.$store.dispatch(
        "alert/error",
        this.$t("error_messages.submit_event")
      );
      /*eslint-disable no-console */
      console.error("error while submitting event: " + error);
    } finally {
      this.submitting = false;
    }
  }
  getNumMedia(type: string, suffix: String): string {
    let numMedias = this.contents.filter(
      (i) => i.action_id >= 0 && i.content_type.includes(type)
    ).length;

    if(numMedias > 0) 
      return ", "+numMedias+suffix
    return ""
  }
  async logSave(isUpdate: Boolean): Promise<void> {
      let action = "editiert"
      if(!isUpdate)
        action = "angelegt"

      const hasText = (this.event.text != "") ? ", mit Text" : ""
      const numVideos = this.getNumMedia("video", " Video(s)") 
      const numImages = this.getNumMedia("image"," Bild(er)") 
      const numAudios = this.getNumMedia("audio", " Audio(s)") 
      let groupID = "HOME:"
      await TrackingRepository.createLog(groupID+"Event "+action+" mit Stimmung '"+this.$t('moods.mood_' +this.event.mood)+"', Schwierigkeit '"+this.$t('difficultyLevel.difficulty_'+this.event.difficulty)+"', Timestamp '"+this.event.datetime+"', Ort '"+this.event.location+"', Event '"+this.$t('activities.'+this.event.activity!['description'])+"'"+hasText+numImages+numVideos+numAudios)
  }
  getContentFilesToAdd(): File[] {
    return this.contents
      .filter((c) => c.action_id == 1 && c.file)
      .map((c) => c.file);
  }
  getContentToDelete(): Array<UploadMediaContent> {
    return this.contents.filter((c) => c.action_id == -1 && c.media_id >= 0);
  }
  async fetchCategories(): Promise<void> {
    try {
      this.categories = await CategoryRepository.get();
    } catch (error) {
      this.$store.dispatch(
        "alert/error",
        this.$t("error_messages.categories")
      );
      /*eslint-disable no-console */
      console.error("error while fetching categories: " + error);
    }
  }
  getActivitiesForCategory(cat_id: Number): Activity[] {
    return this.categories
      .filter((c) => c.id == cat_id)
      .map((c) => c.activities)[0];
  }
  loadOtherCategory(): void {
    let other: any = this.categories.find(
      (category: any) => category.description == "other"
    );
    this.event.category = other.id;
  }
  checkCategoryChange(id: number): void {
    if (this.event.category != id) {
      this.event.activity = null;
      this.event.category = id;
    }
  }
  checkXPandClose(): void {
    this.$store.dispatch(
      "finishOnboardingPart",
      "xp" as keyof OnboardingHints
    );
    this.$emit("eventChanged");
  }

  get getContentClasses(): string {
    if(this.fullscreen) {
      return "yc_dialog_eventform fullscreenDialog"
    }
    return "yc_dialog_eventform"
  }
  get category_activities(): Activity[] {
    return this.categories
      .filter((c) => c.id == this.event.category)
      .map((c) => c.activities)[0];
  }
  get event_location(): string {
    return this.event.location;
  }
  get showDialog(): boolean {
    return this.showEventForm;
  }
  set showDialog(value: boolean) {
    if(!value) {
        // @ts-ignore
        this.$emit('disableEventform')
      }
  }
  get displayNumSteps(): number {
    return this.event.location == 'clinic' ? 7 : 6
  }
  get displayStep(): number {
    return this.event.location == 'clinic' || this.step == 1 ? this.step : this.step - 1
  }
}
