import { Vue } from 'vue-property-decorator'

export default class VueOnbHint extends Vue {
    hintStep: number = 0
    hintOrder: string[] = []
    hintClasses: string[] = []
    onboardingSetting: object | null = null
    cssClass: string = ""

    step(){
        this.hintStep++;
        this.setHighlight();

        if(this.hintStep == this.hintOrder.length && this.onboardingSetting){
            this.$store.dispatch('updateOnboardingSettings', this.onboardingSetting);
        }
    }
    setHighlight(): void{
        if(this.hintClasses[this.hintStep] === "")
            return 

        let id = this.hintClasses[this.hintStep];
        let el = document.getElementById(id);
        let prev = null;

        if (this.hintStep > 0)
            prev = document.getElementById(this.hintClasses[this.hintStep - 1]);
        else if (this.hintStep == this.hintOrder.length - 1)
            prev = document.getElementById(this.hintClasses[this.hintStep]);

        if (el) {
            el.classList.add(this.cssClass);
            el.style.zIndex = 250 + "!important";
        }

        // remove highlight from prev if pev is set
        if(prev){
            prev.classList.remove(this.cssClass);
            prev.style.zIndex = "100";
        }
    }
    removeAllHighlights() {
        let els = document.querySelectorAll<HTMLElement>(`.${this.cssClass}`);
        for(let i = 0; i < els.length; i++){
            els[i].style.zIndex = '100';
            els[i].classList.remove(this.cssClass);
        }
    }
    disableAnchors(disable: boolean){
        // disable / enable navigation anchors 
        let nav = document.getElementsByClassName('v-bottom-navigation theme--light')[0] as HTMLElement;
        if(!nav)
            nav = document.getElementsByClassName('v-app-bar theme--light')[0] as HTMLElement;

        if(nav){
            let anchors = nav.getElementsByTagName('a');
            for(let a of anchors)
                if(disable)
                    a.classList.add('unclickable');
                else
                    a.classList.remove('unclickable');
        }

        // disable / enable tabs
        let tabs = document.getElementsByClassName('v-tab');
        if(tabs)
            for(let tab of tabs)
                if(disable)
                    tab.classList.add('unclickable');
                else if(!disable)
                    tab.classList.remove('unclickable');
    }
    changeFixedNav(remove: boolean){
        let tabs = document.getElementsByClassName('v-tabs-bar')[0] as HTMLElement;
        if(tabs && remove) {
            tabs.style.zIndex = 'auto';
            if(screen.width < 960){
                tabs.style.position = 'absolute';
            }
        } else if (tabs && !remove) {
            tabs.style.zIndex = '10';
            if(screen.width < 960){
                tabs.style.position = 'fixed';
            }
        }
    }
    disableScrolling(disable: boolean){
        let app = document.getElementById('app');
        if(app && disable){
            app.style.overflow = 'hidden';
        }else if(app && !disable){
            app.style.overflow = 'scroll';
        }
    }
}