import {
  setAuthTokens,
  clearAuthTokens,
  TokenRefreshRequest,
  IAuthTokens
} from "@/utils/jwt_interceptor";
import {
  IAuthResponse,
  authResponseToAuthTokens
} from "@/models/IAuthResponse";
import { ILoginRequest } from "@/models/ILoginRequest";
import { NonAuthRepository as Repository } from "@/api/Repository";


// define token refresh function
export const requestRefresh: TokenRefreshRequest = async (
  refreshToken: string
): Promise<IAuthTokens> => {
  // perform refresh
  const response = await Repository.post('/token/refresh', { refresh: refreshToken });

  const res: IAuthResponse = await response.json();
  return authResponseToAuthTokens(res);
}

class LoginRepository {
  async login(params: ILoginRequest) {
    const response = await Repository.post('/token/', params);

    const res: IAuthResponse = await response.json();
    setAuthTokens(authResponseToAuthTokens(res));
  }

  logout() {
    // to reset auth tokens
    clearAuthTokens();
  }

  async requestPasswordReset(email: string) {
    const body ={
      email: email,
      lang: navigator.language
    };
    return await Repository.post('/v1/users/reset-password/', body);
  }

  async resetPassword(token: string, password: string) {
    const body = {
      password: password,
      token: token
    };
    return await Repository.post('/v1/users/confirm-reset-password/', body);
  }
}

export default new LoginRepository();
