























import { Component, Vue } from "vue-property-decorator"
import ProfileDetails from "@/components/social/ProfileDetails.vue"
import User from "@/models/User"

@Component({
  components: {
    ProfileDetails
  }
})
export default class UserProfileOverlay extends Vue {
  get user(): User {
    return this.$store.getters.getUserProfileOverlayData
  }

  created(): void {
    this.$store.dispatch("fetchRelations")
  }

  closeOverlay(): void {
    this.$store.dispatch("closeUserProfileOverlay")
  }
}
