import { VuexModule, Module, Mutation } from "vuex-module-decorators"

@Module({ namespaced: true, name: "mobile" })
export default class Mobile extends VuexModule {
  pwaPrompt: any | null = null

  @Mutation
  setPwaPrompt(prompt: any | null) {
    this.pwaPrompt = prompt
  }
}
