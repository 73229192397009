









import {Component, Vue} from 'vue-property-decorator'

@Component({})
export default class HintDistanceSlider extends Vue {
    min: number = 0
    max: number = 5
    hint_dist: number = 0
    created(){
        this.hint_dist = this.$store.getters.getExperienceSettings.min_hint_distance;
    }
}
