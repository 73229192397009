import Vue from "vue"
import VueRouter, { Route } from "vue-router"
import Home from "../views/Home.vue"
import Onboarding from "@/views/Onboarding.vue"
import Profile from "@/components/social/Profile.vue"
import Tracking from "@/views/Tracking.vue"
// @ts-ignore
import store from "@/store/index.ts"
// @ts-ignore
import { LoginMode } from "@/utils/login_modes.ts"
import { isRefreshTokenExpired } from "../utils/jwt_interceptor"
import i18n from "@/locales/i18n"

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/onboarding",
    name: "onboarding",
    component: Onboarding,
    meta: {
      requiresAuth: true,
      hideNav: true
    }
  },
  {
    path: "/search",
    name: "search",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/Search.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/profile",
    name: "profile",
    component: Profile,
    meta: {
      requiresAuth: true
    }
  },
  /*

  Components are now dialogs 
  {
    path: '/profile/settings',
    name: 'settings',
    component: () => import("@/components/social/ProfileSettings.vue"),
    meta: {
      requiresAuth: true
    }
  },
  */

  {
    path: "/profile/eventchain",
    name: "eventchain",
    component: () => import("@/components/social/profile/EventChain.vue"),
    meta: {
      requiresAuth: true
    }
  },

  /*
  !!! Unused route -> component is deleted from this repo but archived in 'archive' repo !!! 

  {
    path: '/profile/settings',
    name: 'settings',
    component: () => import("@/components/social/ProfileSettings.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/profile/eventchain',
    name: 'eventchain',
    component: () => import("@/views/EventChain.vue"),
    meta: {
      requiresAuth: true
    }
  },
  */
  {
    path: "/tracking",
    name: "tracking",
    component: Tracking,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/information",
    name: "information",
    component: () => import("../components/info/InfoTextView.vue"),
    meta: {
      requiresAuth: true
    }
  },
  /*
  {
    path: "/events/new/",
    name: "newEvent",
    component: () => import("../views/EventForm.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  */
  {
    path: "/login",
    name: "login",
    component: () => import("../views/Login.vue"),
    meta: {
      requiresAuth: false,
      hideNav: true
    }
  },
  {
    path: "/reset-password",
    name: "reset-password",
    component: () => import("../views/Login.vue"),
    props: { mode: LoginMode.REQUEST_RESET },
    meta: {
      requiresAuth: false,
      hideNav: true
    }
  },
  {
    path: "/reset-password/confirm",
    name: "reset-password-confirm",
    component: () => import("../views/Login.vue"),
    props: { mode: LoginMode.RESET },
    meta: {
      requiresAuth: false,
      hideNav: true
    }
  },
  {
    path: "*",
    name: "pageNotFound",
    component: () => import("../views/PageNotFound.vue"),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: "/registration",
    name: "registration",
    component: () => import("../views/Login.vue"),
    props: { mode: LoginMode.REGISTRATION },
    meta: {
      requiresAuth: false,
      hideNav: true
    }
  },
  /*
  {
    path: "/chat/:partnerId",
    name: "chat",
    props: true,
    component: () => import("../components/social/Chat.vue"),
    meta: {
      requiresAuth: true
    }
  },
  */
  {
    path: "/chat",
    name: "chatList",
    component: () => import("../components/social/ChatList.vue"),
    meta: {
      requiresAuth: true
    }
  }
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  mode: "history",
  routes
})

router.beforeEach(async (to: Route, from: Route, next: Function) => {
  store.dispatch("alert/clear")

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // restrict access to onboarding, if finsihed once
    if (to.name === "onboarding" && !store.getters.getOnboardingPending) {
      next({
        path: "/",
        params: { nextUrl: to.fullPath }
      })
    }

    if (store.getters.isAuthenticated == false || isRefreshTokenExpired()) {
      next({
        path: "/login",
        params: { nextUrl: to.fullPath }
      })
    } else {
      // settings need to be requested from BE again, when page is reloaded
      store.dispatch("integrateBackendSettings")
      await store.dispatch("integrateUserDataFromBackend")
      if (!store.state.application.appSetupRan) {
        i18n.locale = store.getters.getUserData("language") || navigator.language.substring(0, 2)
        store.commit("application/setAppSetupRan", true)
      }
      next()
    }
  } else {
    next()
  }
})

export default router
