








































































import { Vue, Prop, Component, Watch } from "vue-property-decorator"
import HomeBack from "@/components/HomeBack.vue"
import LoadingScreen from "@/components/LoadingScreen.vue"
import ShowEvent from "@/views/ShowEvent.vue"
import EventForm from "@/views/EventForm.vue"
import Event from "@/models/Event"
import OnbStartpageHints from "@/components/onboarding/OnbStartpageHints.vue"
import Experience from "@/views/Experience.vue"
import TrackingRepository from "@/api/TrackingRepository"

@Component({
  components: {
    LoadingScreen,
    HomeBack,
    ShowEvent,
    EventForm,
    OnbStartpageHints,
    Experience
  }
})
export default class Home extends Vue {
  @Prop({ type: Number }) currEvent: number | undefined
  events: Event[] = []
  isLoading: boolean = true
  showEventId: number = -1
  showEventModel: boolean = false
  expMode: boolean = false
  editEventMode: boolean = false
  editEvent: Event = {} as Event
  addEvent: boolean = false
  addSymptom: boolean = false
  timestamp: number = Date.now()
  initialScroll: number = -1
  initEvents: boolean = false
  showStartpageHints: boolean = false
  sizePerEventConstant: number = 0.31 // event with is 31 vh
  latestPosition: number = 0
  resizeKey: number = Date.now()
  highlightedEvent: number | undefined = -1
  scrollListener: boolean = false
  experienceMode: boolean = false

  get hasEvents(): boolean {
    return this.$store.getters.hasEvents
  }
  get moreEventsAvailable(): boolean {
    return this.$store.getters.moreEventsAvailable
  }
  get eventCountTotal(): number {
    return this.$store.getters.eventCountTotal
  }
  get loadingInProgress(): boolean {
    return this.$store.getters.loadingInProgress
  }
  get getEvents(): Event[] {
    return this.$store.getters.getEvents
  }
  get getBlockSize(): number {
    return this.$store.getters.getBlockSize
  }
  get fillup(): number {
    // Calculate number of segments needed, to fillup screen in case number of events is not sufficient
    return Math.max(0, this.$store.getters.getBlockSize - this.eventCountTotal)
  }
  get wrapperClasses(): Object {
    return {
      "hide-overflow-y": this.showEventId < 0,
      "hide-background-image": this.showEventId > 0
    }
  }
  get startpageHintStatus(): boolean {
    return this.$store.getters.getOnboardingSetting("startpage_hints")
  }

  @Watch("$route")
  onRouteChange() {
    this.expMode = true
  }

  @Watch("showEvent")
  onShowEventChange() {
    if (!this.showEventModel) this.adaptToEventID(this.showEventId)
    if (this.showEventModel) {
      // assure scrolling is enabled -> might be disabled after viewing image and closing dialog with esc key
      let dialog = document.getElementsByClassName("yc_dialog_eventview")[0] as HTMLElement
      dialog.style.overflowY = "scroll"
    }
  }

  @Watch("startpageHintStatus")
  onStartpageHintStatusChange() {
    this.showStartpageHints = this.startpageHintStatus
  }

  async created(): Promise<void> {
    window.addEventListener("resize", this.resize)

    if (this.getEvents.length == 0) {
      await this.$store.dispatch("loadInitialEvents")
    }
    this.highlightedEvent = this.currEvent
    if (!this.currEvent) this.isLoading = false
  }
  async updated(): Promise<void> {
    if (!this.isLoading) {
      const wrapper = document.getElementById("home_wrapper")
      // When data was initially loaded
      // must be in updated because of dom rendering

      if (wrapper && this.initialScroll > -1) {
        // CAN THIS BE MOVED TO MOUNTED?? DON'T KNOW WHY WE DID IT HERE
        // SEEMS TO WORK WHEN IN MOUNTED
        // let ep = document.getElementById('eventPath');
        // if(ep) {
        //   ep.children[0].scrollIntoView();
        //   alert('into');
        // }
        // this.initialScroll = Math.ceil(wrapper.scrollLeft);
        // wrapper.focus();
        // if(this.highlightedEvent){
        //   let eventPos = await this.calculateEventPos(this.highlightedEvent);
        //   this.scrollToEventPos(eventPos);
        // }
        wrapper.addEventListener("scroll", this.handleScroll)
      }
    }
  }
  destroyed(): void {
    const wrapper = document.getElementById("home_wrapper")
    if (wrapper) {
      wrapper.removeEventListener("scroll", this.handleScroll)
      this.scrollListener = false
    }
    window.removeEventListener("resize", this.resize)
  }
  async mounted(): Promise<void> {
    this.$store.dispatch("integrateBackendSettings").then(() => {
      this.showStartpageHints = this.$store.getters.getOnboardingSetting("startpage_hints")
    })

    if (this.highlightedEvent) {
      await this.calculateEventPos(this.highlightedEvent)
      this.isLoading = false
    }
    document.getElementsByTagName("html")[0].classList.add("noScrollY")

    const wrapper = document.getElementById("home_wrapper")
    if (wrapper) {
      wrapper.addEventListener("scroll", this.handleScroll)
      this.scrollListener = true

      let ep = document.getElementById("eventPath")
      if (ep) {
        ep.children[0].scrollIntoView()
      }

      this.initialScroll = Math.ceil(wrapper.scrollLeft)

      wrapper.focus()

      if (this.highlightedEvent) {
        let eventPos = await this.calculateEventPos(this.highlightedEvent)
        this.scrollToEventPos(eventPos)
      }
    }
  }
  beforeDestroy(): void {
    document.getElementsByTagName("html")[0].classList.remove("noScrollY")
  }
  resize(): void {
    let loadedeventsWidth =
      this.getEvents.length * (this.sizePerEventConstant * document.body.clientHeight)
    let wrapper = document.getElementById("home_wrapper")
    if (wrapper) {
      if (wrapper.clientWidth > loadedeventsWidth && this.moreEventsAvailable) {
        this.$store.dispatch("loadAdditionalEvents")
      }
    }
    this.resizeKey = Date.now()
  }
  enableEditEvent(event: Event): void {
    this.editEvent = event
    this.editEventMode = true
  }
  async handleScroll(event: any): Promise<void> {
    // Calculate normalized scroll distance
    const left = Math.ceil(event.currentTarget.scrollLeft) // current position
    const width = event.currentTarget.scrollWidth // maximum size
    const scrolled = Math.abs(this.initialScroll - left) // scrolled distance
    const leftToScroll = Math.abs(width - scrolled - window.innerWidth) // distance that can still be scrolled
    const sizePerEvent = window.innerHeight * this.sizePerEventConstant

    if (typeof this.highlightedEvent !== "undefined" && this.highlightedEvent >= 0) {
      const pos = await this.calculateEventPos(this.highlightedEvent)

      const delta = Math.abs(left - pos)
      if (delta >= sizePerEvent) this.highlightedEvent = -1
    }

    // load depending on how much is left to scroll and on sizePerEvent
    if (leftToScroll < sizePerEvent && this.moreEventsAvailable) {
      if (!this.loadingInProgress) {
        // only load when no pending requests
        await this.$store.dispatch("loadAdditionalEvents")
        let groupID = "HOME:"
        await TrackingRepository.createLog(
          groupID +
            "Scrollen im Homescreen " +
            this.$store.getters.eventCount +
            " von " +
            this.$store.getters.eventCountTotal +
            " Events geladen"
        )
      }
    }
  }
  async openShowEvent(event: number): Promise<void> {
    this.showEventId = event
    this.showEventModel = true
    this.latestPosition = await this.calculateEventPos(event)
  }
  async adaptToEventID(event: number): Promise<void> {
    this.showEventId = event
    // event == -1 -> event closed; event == -2 -> event deleted
    if (event < 0) {
      this.showEventModel = false
      this.scrollToEventPos(this.latestPosition)
    } else {
      this.latestPosition = await this.calculateEventPos(event)
      this.timestamp = Date.now()
    }
    if (
      event == -2 &&
      this.getEvents.length < this.getBlockSize &&
      this.getEvents.length < this.eventCountTotal
    ) {
      await this.$store.dispatch("loadAdditionalEvents")
    }
  }
  scrollToEventPos(position: number): void {
    let wrapper = document.getElementById("home_wrapper")
    setTimeout(() => {
      if (wrapper) {
        wrapper.scrollLeft = position
      }
    }, 100)
  }
  async calculateEventPos(eventId: number): Promise<number> {
    let sizePerEvent = window.innerHeight * this.sizePerEventConstant
    let eventIndex = this.$store.getters.getEventIndex(eventId)

    // does not double check if eventId is valid id -> might be necessary but problematic due to timing
    while (eventIndex < 0 && eventId > -1) {
      try {
        await this.$store.dispatch("loadAdditionalEvents")
      } catch (e) {
        eventIndex = 0
      }
      eventIndex = this.$store.getters.getEventIndex(eventId)
    }
    const loadedEvents = this.getEvents.length
    if (this.initialScroll > 0) {
      // check for scroll direction because of different handling of rtl orientation
      return (loadedEvents - eventIndex - 1) * sizePerEvent - (window.innerWidth - sizePerEvent) / 2
    } else {
      return -eventIndex * sizePerEvent + (window.innerWidth - sizePerEvent) / 2
    }
  }
  async reactToNewEvent(eventId: number): Promise<void> {
    this.highlightedEvent = eventId
    let event_pos = await this.calculateEventPos(eventId)
    this.scrollToEventPos(event_pos)
  }
  async switchMode(): Promise<void> {
    let groupID = "HOME:"
    if (this.experienceMode) {
      this.experienceMode = false
      await TrackingRepository.createLog(groupID + "Wechsel zu Feature Startseite")
    } else {
      groupID = "EXPE:"
      this.experienceMode = true
      await TrackingRepository.createLog(groupID + "Wechsel zu Feature Erleben")
    }
  }
  async logAdd(): Promise<void> {
    let groupID = "HOME:"
    await TrackingRepository.createLog(groupID + "Event anlegen aus dem Homescreen")
  }
  async logSearch(): Promise<void> {
    let groupID = "HOME:"
    await TrackingRepository.createLog(groupID + "Öffnen der Eventsuche")
  }
}
