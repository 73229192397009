








































import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import { MOODS, IMood } from "@/models/Mood"
import { AssetHelper } from "@/utils/AssetHelper"
import { Breakpoint } from "vuetify/types/services/breakpoint"

@Component({})
export default class MoodSelection extends Vue {
  @Prop({ type: Number, default: null }) value!: number | null
  moods: IMood[] = MOODS
  selected: number = -1
  isMobile: boolean = true

  created(): void {
    this.isMobile = this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm
    if (this.value && this.value != -1) {
      this.selected = this.value
    } else if (this.isMobile) {
      //Setting initial mood and passing initial mood on to parent component via callback
      this.selected = 4
      this.$emit("inputMobile", this.selected)
    }
  }
  mounted(): void {
    //If preselected mood exists for desktop
    if (this.selected != -1 && !this.isMobile) {
      //@ts-ignore
      this.$refs.mood[this.selected - 1].classList.add("selected")
    }
  }
  getMoodIcon(index: number): NodeRequire {
    return AssetHelper.getMoodIcon(index, null, false)
  }
  focusMood(index: number): void {
    if (!this.isMobile) {
      if (this.selected != -1) {
        //@ts-ignore
        this.$refs.mood[this.selected - 1].classList.remove("selected")
      }
      //@ts-ignore
      this.$refs.mood[index - 1].classList.add("selected")
    }
    this.selected = index
  }

  @Watch("$vuetify.breakpoint", { deep: true })
  onVuetifyBreakpointChange(updated: Breakpoint): void {
    this.isMobile = updated.xs || updated.sm
  }
}
