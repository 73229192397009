












































































































import { Component, Prop, Vue } from "vue-property-decorator"
import RelationsRepository from "@/api/RelationsRepository"
import TrackingRepository from "@/api/TrackingRepository"

@Component({})
export default class ForeignProfileInteraction extends Vue {
  @Prop({ type: Number, required: true }) userId!: number
  @Prop({ type: String, required: true }) userName!: string

  async sendRequest(userId: any) {
    try {
      const relation = await RelationsRepository.sendRelationRequest(userId)
      this.$store.commit("addRelation", relation)

      const groupID = "CHAT:"
      await TrackingRepository.createLog(
        groupID + "Freundschaftsanfrage an User " + userId + " senden über Profilansicht"
      )
    } catch (error) {
      this.$store.dispatch("alert/error", this.$t("error_messages.add_relation"))
    }
  }
  async block(payload: any) {
    this.$store.dispatch("block", payload)
    const groupID = "CHAT:"
    await TrackingRepository.createLog(
      groupID + "Blockiert User " + payload.user + " über Profilansicht"
    )
  }
  async remove(
    relationId: any,
    userId: number,
    isFriend: boolean,
    isRequestedToMe: boolean,
    isBlocked: boolean
  ) {
    try {
      const groupID = "CHAT:"
      if (isFriend)
        await TrackingRepository.createLog(
          groupID + "Freundschaft beenden mit User " + userId + " über Profilansicht"
        )
      if (isBlocked)
        await TrackingRepository.createLog(
          groupID + "Blockierung von User " + userId + " aufheben über Profilansicht"
        )
      if (!isFriend && isRequestedToMe && !isBlocked)
        await TrackingRepository.createLog(
          groupID + "Freundschaftsanfrage von User " + userId + " ablehnen über Profilansicht"
        )
      if (!isFriend && !isRequestedToMe && !isBlocked)
        await TrackingRepository.createLog(
          groupID + "Freundschaftsanfrage an User " + userId + " löschen über Profilansicht"
        )

      await RelationsRepository.deleteRelation(relationId)
      this.$store.commit("removeRelation", { id: relationId })
    } catch (error) {
      this.$store.dispatch("alert/error", this.$t("error_messages.change_relation"))
    }
  }
  async accept(relationsId: any) {
    try {
      const relation = await RelationsRepository.confirmRelationRequest(relationsId)
      this.$store.commit("updateRelation", relation)

      // create chat
      await this.$store.dispatch("chat/create", relation.from_user.id)

      const groupID = "CHAT:"
      await TrackingRepository.createLog(
        groupID +
          "Freundschaftsanfrage von User " +
          relation.from_user.id +
          " annehmen über Profilansicht"
      )
    } catch (error) {
      this.$store.dispatch("alert/error", this.$t("error_messages.change_relation"))
    }
  }
  /*
    chat(userId: number){
        this.$router.push({name: 'chat', params: {partnerId: userId.toString()}})
    }
    */

  get isFriend() {
    return this.$store.getters.relations.some(
      (friend: any) => friend.to_user.id == this.userId || friend.from_user.id == this.userId
    )
  }
  get isRequested() {
    return this.$store.getters.relationRequests.some(
      (friend: any) => friend.to_user.id == this.userId || friend.from_user.id == this.userId
    )
  }
  get isBlocked() {
    return this.$store.getters.blocked.some(
      (friend: any) => friend.to_user.id == this.userId || friend.from_user.id == this.userId
    )
  }
  get isRequestedToMe() {
    return this.$store.getters.receivedRequests.some(
      (request: any) => request.id == this.relationId
    )
  }
  get relationId() {
    if (this.isFriend) {
      return this.$store.getters.relations.find(
        (friend: any) => friend.to_user.id == this.userId || friend.from_user.id == this.userId
      ).id
    } else if (this.isBlocked) {
      return this.$store.getters.blocked.find(
        (friend: any) => friend.to_user.id == this.userId || friend.from_user.id == this.userId
      ).id
    } else {
      return this.$store.getters.relationRequests.find(
        (friend: any) => friend.to_user.id == this.userId || friend.from_user.id == this.userId
      ).id
    }
  }
}
