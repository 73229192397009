






































































import {Vue, Component} from 'vue-property-decorator'

@Component({})
export default class DailyXPProgress extends Vue {
  showEntryHint: boolean = false
  showExpHint: boolean = false

  get entryXpGained(): number {
    return this.$store.getters.getEventXpGained;
  }
  get entryXpMax(): number {
    return this.$store.getters.getEventXpMax;
  }
  get entryXpPercentage(): number {
    return (this.entryXpGained / this.entryXpMax) * 100;
  }
  get experienceGainedXp(): number {
    return this.$store.getters.getExperienceXpGained;
  }
  get experienceMaxXp(): number {
    return this.$store.getters.getExperienceXpMax;
  }
  get experienceXPGainedPercentage(): number {
    return (this.experienceGainedXp / this.experienceMaxXp) * 100;
  }

  async created(): Promise<void> {
    await this.$store.dispatch("loadExperienceActions");
  }
}
