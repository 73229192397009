export default interface TrackedAction {
  key: ETrackedAction;
  gained_xp: number;
  xp_per_action: number;
  max_xp: number;
}

export enum ETrackedAction {
  NEW_ENTRY = "new_entry",
  EXPERIENCE_MODE_SCROLLED = "experience_mode_scrolled",
  EXPERIENCE_MODE_EVENT_OPENED = "experience_mode_event_opened",
}
