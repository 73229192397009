import Repository from "./Repository";
import Diagnosis from "@/models/Diagnosis";

const resource = "/v1/diagnoses/";


class DiagnosisRepository {
  async get(): Promise<Diagnosis[]>{
    const response = await Repository.get(resource);
    return await response.json();
  }
}

export default new DiagnosisRepository();