import { Vue } from 'vue-property-decorator'

export default class VueStepper extends Vue {
    step: number = 0
    stepTimes: number[] = [2000, 2000, 0, 1500]

    stepper() {
        setTimeout(() => this.step++, this.stepTimes[this.step]);
    }
    scroll() {
        window.scrollTo({
            top: document.getElementsByClassName("yc_onboarding_section")[0].scrollHeight,
            behavior: "smooth",
        });
    }
}