import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VBadge,{attrs:{"bottom":"","color":"none","overlap":"","offset-x":"40","offset-y":"40"}},[_c(VBtn,{directives:[{name:"show",rawName:"v-show",value:(_vm.ownprofile),expression:"ownprofile"}],attrs:{"slot":"badge","fab":"","small":""},on:{"click":function($event){return _vm.chooseNewAvatar()}},slot:"badge"},[_c(VIcon,[_vm._v("mdi-pencil")])],1),(_vm.profileAvatar)?_c(VAvatar,{staticClass:"mx-auto d-block",attrs:{"size":"130"}},[_c('img',{attrs:{"src":_vm.profileAvatar}})]):_c(VIcon,{staticClass:"yc_user_avatar_default",attrs:{"size":"130"}},[_vm._v("mdi-account-circle")])],1),_c(VDialog,{attrs:{"content-class":"yc_dialog_avatar","fullscreen":_vm.$vuetify.breakpoint.xsOnly},model:{value:(_vm.chooseAvatar),callback:function ($$v) {_vm.chooseAvatar=$$v},expression:"chooseAvatar"}},[_c(VCard,[_c('Avatar',{key:_vm.key,attrs:{"show-close-option":true,"show-dialog-title":true},on:{"updateAvatarPath":function($event){return _vm.storeNewAvatar($event)},"closeDialog":function($event){_vm.chooseAvatar = false}}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }