import { Module } from "vuex";
import { ApplicationState, RootState } from "../types";

const application: Module<ApplicationState, RootState> = {
  namespaced: true,
  state: {
    online: true,
    appSetupRan: false
  },
  actions: {
    setOnlineStatus({ commit }, status: boolean) {      
      commit("setOnlineStatus", status);
    }
  },
  mutations: {
    setOnlineStatus(state, status: boolean) {
      state.online = status;      
    },
    setAppSetupRan(state, set: boolean) {
      state.appSetupRan = set
    }
  },
  getters: {
      onlineStatus: state => {
          return state.online
      }
  }
};

export default application;