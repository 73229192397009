import { Module } from "vuex"
import { ExperienceState, RootState } from "../types"
import Event from "@/models/Event"
import EventRepository from "@/api/EventRepository"
import i18n from "@/locales/i18n"
import Activity from "@/models/Activity"
import ActionRepository from "@/api/ActionRepository"
import TrackedAction, { ETrackedAction } from "@/models/Action"

function compareEvents(a: Event, b: Event) {
  if (a.datetime < b.datetime) return -1
  else return 1
}

const initialState: ExperienceState = {
  trigger_activities: [
    "stationary",
    "home",
    "birthday",
    "birthday-other",
    "bone-marrow-transplant",
    "stemcell-collection",
    "good-event",
    "bad-event",
    "good-day",
    "bad-day",
    "surgery",
    "intensive-care",
    "intensive-care-end",
    "emergency"
  ],
  events: [],
  trackedActionExpModeEventOpened: {
    key: ETrackedAction.EXPERIENCE_MODE_EVENT_OPENED,
    gained_xp: 0,
    xp_per_action: 0,
    max_xp: 0
  },
  trackedActionExpModeScrolled: {
    key: ETrackedAction.EXPERIENCE_MODE_SCROLLED,
    gained_xp: 0,
    xp_per_action: 0,
    max_xp: 0
  }
}

const experience: Module<ExperienceState, RootState> = {
  state: initialState,
  mutations: {
    addExpEvents(state, payload: Array<Event>) {
      payload.forEach((e) => state.events.push(e))
    },
    addSingleEvent(state, payload: Event) {
      const set = new Set(state.events)
      set.add(payload)
      state.events = [...set]
      state.events.sort(compareEvents)
    },
    updateExpModeScrolled(state, payload: TrackedAction) {
      state.trackedActionExpModeScrolled = payload
    },
    updateExpModeEventOpened(state, payload: TrackedAction) {
      state.trackedActionExpModeEventOpened = payload
    }
  },
  actions: {
    async loadFirstEvents({ commit, dispatch, getters }) {
      try {
        dispatch("changeIsLoading", true)
        const response = await EventRepository.getFirstEvents(getters.getBlockSize, 0)
        commit("addExpEvents", response.results)
        dispatch("updateEventCount", response.count)
      } catch (error) {
        dispatch("alert/error", i18n.t("error_messages.events"))
        /*eslint-disable no-console */
        console.error("error while loading event: " + error)
      } finally {
        dispatch("changeIsLoading", false)
      }
    },
    async loadAdditionalFirstEvents({ state, commit, dispatch, getters }) {
      try {
        dispatch("changeIsLoading", true)
        const response = await EventRepository.getFirstEvents(
          getters.getBlockSize,
          state.events.length
        )
        commit("addExpEvents", response.results)
        dispatch("updateEventCount", response.count)
      } catch (error) {
        dispatch("alert/error", i18n.t("error_messages.events"))
        /*eslint-disable no-console */
        console.error("error while loading event: " + error)
      } finally {
        dispatch("changeIsLoading", false)
      }
    },
    async loadExperienceActions({ state, commit, dispatch, getters }) {
      try {
        const response = await ActionRepository.get()
        const expModeScrolledTrackedAction = response.find(
          (action) => action.key == ETrackedAction.EXPERIENCE_MODE_SCROLLED
        )
        const expModeEventOpenedTrackedAction = response.find(
          (action) => action.key == ETrackedAction.EXPERIENCE_MODE_EVENT_OPENED
        )
        commit("updateExpModeScrolled", expModeScrolledTrackedAction)
        commit("updateExpModeEventOpened", expModeEventOpenedTrackedAction)
      } catch (error) {
        dispatch("alert/error", i18n.t("error_messages.experience_mode_state"))
        /*eslint-disable no-console */
        console.error("error while loading experience mode tracked action: " + error)
      }
    },
    addSingleEvent({ state, commit }, event) {
      if (state.events.length > 0) {
        commit("addSingleEvent", event)
      }
    },
    async releaseXpOnScroll({ state, commit }, args) {
      const percentageScrolled = args.numScrolled / args.numEvents
      // If number of events is 20 or lower, thresholds are 20,40,60,80 and 100 percent scrolled
      // If number of events is higher than 20, thresholds are 10,20,30,40 and 50 percent scrolled
      const thresholds = [...Array(5).keys()].map((x) =>
        args.numEvents <= 20 ? x / 5 + 0.2 : x / 10 + 0.1
      )

      // filter thresholds already reached in a former session
      const gained_xp_scrolled = state.trackedActionExpModeScrolled.gained_xp
      const thresholdsSliced = thresholds.slice(gained_xp_scrolled)

      //getting thresholds newly reached with current session
      const thresholdsNewlyReached = thresholdsSliced.filter(
        (thresh) => thresh < percentageScrolled
      )

      //call api to receive new XP per each new threshold reached
      thresholdsNewlyReached.forEach(async (thresh) => {
        const scrolledTrackedAction = await ActionRepository.triggerAction(
          ETrackedAction.EXPERIENCE_MODE_SCROLLED
        )
        commit("updateExpModeScrolled", scrolledTrackedAction)
      })
    },
    async releaseXpOnOpenedEvent({ state, commit }) {
      if (
        state.trackedActionExpModeEventOpened.gained_xp < state.trackedActionExpModeScrolled.max_xp
      ) {
        const eventOpenedTrackedAction = await ActionRepository.triggerAction(
          ETrackedAction.EXPERIENCE_MODE_EVENT_OPENED
        )
        commit("updateExpModeEventOpened", eventOpenedTrackedAction)
      }
    }
  },
  getters: {
    getExperienceEvents(state) {
      return state.events
    },
    getNrOfExpEvents(state) {
      return state.events.length
    },
    getEventTrigger: (state, getters) => (id: number) => {
      const event = state.events.find((e) => e.id == id)
      if (event !== undefined) {
        const eventActivity = event.activity as Activity
        const hint_type = getters.getExperienceHintType

        // check if activity is trigger and if hint type allows activity triggers
        if (
          state.trigger_activities.includes(eventActivity.description) &&
          (hint_type == "all" || hint_type == "activities")
        )
          return eventActivity.description
        // check if event was hard and bad and if hint type allows trigger by hard events
        else if (
          event.difficulty == 3 &&
          (event.mood == 5 || event.mood == 6) &&
          (hint_type == "all" || hint_type == "negative")
        ) {
          if (state.trigger_activities.includes(eventActivity.description))
            return eventActivity.description
          else return "hard_bad"
        }
        // check if event was hard and bad and if hint type allows trigger by hard events
        else if (
          event.difficulty == 3 &&
          (event.mood == 1 || event.mood == 2) &&
          (hint_type == "all" || hint_type == "negative")
        ) {
          if (state.trigger_activities.includes(eventActivity.description))
            return eventActivity.description
          else return "hard_good"
        }
        // check if event was hard and bad and if hint type allows trigger by positive events
        else if (
          event.difficulty == 1 &&
          event.mood == 1 &&
          (hint_type == "all" || hint_type == "positive")
        ) {
          if (state.trigger_activities.includes(eventActivity.description))
            return eventActivity.description
          else return "easy_good"
        }
      }
      return null
    },
    getEventAt: (state) => (index: number) => {
      return state.events[index]
    },
    getExpEventIndex: (state) => (id: number) => {
      return state.events.findIndex((e) => e.id == id)
    },
    getTrackedActionExpModeScrolled: (state) => {
      return state.trackedActionExpModeScrolled
    },
    getTrackedActionExpModeEventOpened: (state) => {
      return state.trackedActionExpModeEventOpened
    },
    getExperienceXpGained: (state) => {
      return (
        state.trackedActionExpModeScrolled.gained_xp +
        state.trackedActionExpModeEventOpened.gained_xp
      )
    },
    getExperienceXpMax: (state) => {
      return (
        state.trackedActionExpModeScrolled.max_xp + state.trackedActionExpModeEventOpened.max_xp
      )
    }
  }
}
export default experience
