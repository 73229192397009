import Repository from "./Repository";
const resource = "/v1/tracking";

class TrackingRepository {

    async createLog(usage_descripton: String): Promise<Number> {
        const body = {log: usage_descripton}
        const response = await Repository.post(resource, body)
        return response.status
    }
}

export default new TrackingRepository();