import Repository from "@/api/Repository";
import { Chapter } from "@/models/Chapter";
import { InfoTextGroup } from "@/models/InfoText";

const resource = "/v1/chapter";
class ChapterRepository {
  async getInfoTextChapters(infoTextId: number): Promise<Array<Chapter>> {
    const response = await Repository.get(`${resource}/${infoTextId}/`);
    return await response.json();
  }
}

export default new ChapterRepository();
