import Repository from "./Repository"
import TrackedAction from "@/models/Action"
const resource = "/v1/actions"

class ActionRepository {
  async get(): Promise<Array<TrackedAction>> {
    const response = await Repository.get(resource)
    return await response.json()
  }
  async triggerAction(action: string) {
    const params = {
      action: action
    }
    const response = await Repository.post(resource, params)
    return response.json()
  }
}

export default new ActionRepository()
