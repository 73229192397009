import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"yc_tracking_wrapper spacetobottom"},[_c(VTabs,{attrs:{"centered":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c(VTab,{attrs:{"data-cy":"moodtab"}},[_vm._v(_vm._s(_vm.$t("mood")))]),_c(VTab,{attrs:{"data-cy":"symptomtab"}},[_vm._v(_vm._s(_vm.$t("symptoms")))])],1),_c(VTabsItems,{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c(VTabItem,[_c('MoodChart',{attrs:{"timespan":_vm.timespan}}),_c(VRow,{staticClass:"d-flex justify-center align-center mb-3"},[_c(VCol,{staticClass:"d-flex justify-center small-breakpoint",attrs:{"lg":"3","md":"4","sm":"4","align-self":"center"}},[_c('TimeSpanSelection',{attrs:{"ranges":_vm.timeRanges},model:{value:(_vm.timespan),callback:function ($$v) {_vm.timespan=$$v},expression:"timespan"}})],1)],1)],1),_c(VTabItem,[_c('SymptomForm')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }