import Repository from "./Repository";
import Paginated from '@/models/Paginated';
import Symptom from '@/models/Symptom';
import SymptomDurationPoint from "@/models/SymptomDurationPoint";
import SymptomDuration from "@/models/SymptomDuration";
import { NewSymptomDuration, NewSymptomDurationPoint } from "@/store/types";

const resource_symptom = "/v1/symptoms";
const resource_duration = "/v1/durations/";
const resource_duration_symptoms = "/v1/durations/symptoms/";
const resource_duration_symptoms_verify = "/v1/durations/verify/";
const resource_durationpoint = "/v1/durationpoints/";
const resource_durationpoint_until = "/v1/durationpoints/until/";


class SymptomRepository {


    // ########################################################
    // ################### symptom methods ##################
    // ########################################################

    async getSymptoms(): Promise<Symptom[]> {
      const response = await Repository.get(resource_symptom);
      return await response.json();
    }

    async getFinishedSymptoms(): Promise<Symptom[]> {
      const response = await Repository.get(resource_duration_symptoms);
      return await response.json();
    }

    // ########################################################
    // ############### symptomdurantion methods ###############
    // ########################################################

    async addActiveSymptomDurations(symptom_duration : NewSymptomDuration): Promise<SymptomDuration>{
      const response = await Repository.post(`${resource_duration}${symptom_duration.symptom}`, symptom_duration);
      return await response.json()
    }

    async getActiveSymptomDurations(): Promise<SymptomDuration[]>{
      const response = await Repository.get(`${resource_duration}active`);
      return await response.json()
    }

    async deleteSymptomDurations(symptom_duration: number): Promise<Number> {
      const response = await Repository.delete(`${resource_duration}${symptom_duration}`);
      return await response.status
    }

    async getFinishedSymptomDurations(symptom : number, page : number): Promise<Paginated<SymptomDuration>>{
      const params = {
        'page': page
      }
      const response = await Repository.get(`${resource_duration}${symptom}`, params);
      return await response.json()
    }

    async updateSymptomDuration(symptom_duration : SymptomDuration): Promise<SymptomDuration> {
        const payload = {...symptom_duration, symptom: symptom_duration.symptom.id}
        const response = await Repository.put(`${resource_duration}${symptom_duration.id}`, payload);
        return await response.json()
    }


    // used to check if updated range rules out any durationpoints. returns num points which are out of (date)range
    async verifySymptomDurationUpdate(symptom_duration : SymptomDuration): Promise<Response> {
      const response = await Repository.put(`${resource_duration_symptoms_verify}`, symptom_duration);
      return response
    }
 

    // ########################################################
    // ############ symptomdurantionpoints methods ############
    // ########################################################


    async getSymptomDurationPoints(symptom_duration : number, page : number) : Promise<Paginated<SymptomDurationPoint>>{
        const params = {
          'page': page
        }
        const response = await Repository.get(`${resource_durationpoint}${symptom_duration}`, params);
        return await response.json();
      }

      async addSymptomDurationPoint(symptom_duration_point : NewSymptomDurationPoint): Promise<SymptomDurationPoint> {
        const response = await Repository.post(`${resource_durationpoint}${symptom_duration_point.symptom_duration}`, symptom_duration_point);
        return await response.json()
    }

      async updateSymptomDurationPoint(symptom_duration_point : SymptomDurationPoint): Promise<SymptomDuration> {
        const response = await Repository.put(`${resource_durationpoint}${symptom_duration_point.id}`, symptom_duration_point);
        return await response.json()
    }

    async deleteSymptomDurationPoint(symptom_duration_point: number): Promise<Number> {
      const response = await Repository.delete(`${resource_durationpoint}${symptom_duration_point}`);
      return await response.status
    }


    async getSymptomDurationPointsUntil(data : {symptom_id: number, duration_id: number, point_id: number, exclude_point: boolean}) : Promise<Paginated<SymptomDurationPoint>>{
      const response = await Repository.post(`${resource_durationpoint_until}`, data);
      return await response.json();
    }
    
  }
  
export default new SymptomRepository();

