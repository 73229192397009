import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VSelect,{attrs:{"items":_vm.sortedClinics,"item-text":"name","item-value":"id","return-object":"","placeholder":_vm.showPlaceholder ? _vm.placeholderText : null},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [(data.item.address)?_c('span',{staticClass:"elevatedText"},[_vm._v(_vm._s(data.item.address)+" ")]):_vm._e(),(data.item.address)?_c('span',[_vm._v(" - ")]):_vm._e(),_c('span',[_vm._v(_vm._s(data.item.name))])]}},{key:"item",fn:function(ref){
var item = ref.item;
var attrs = ref.attrs;
var on = ref.on;
return [_c(VListItem,_vm._g(_vm._b({attrs:{"data-cy":"onb_clinic"}},'v-list-item',attrs,false),on),[_c(VListItemContent,[_c(VListItemTitle,[(item.address)?_c('span',{staticClass:"elevatedText"},[_vm._v(_vm._s(item.address)+" ")]):_vm._e(),(item.address)?_c('span',[_vm._v(" - ")]):_vm._e(),_c('span',[_vm._v(_vm._s(item.name))])])],1)],1)]}}]),model:{value:(_vm.inputVal),callback:function ($$v) {_vm.inputVal=$$v},expression:"inputVal"}})}
var staticRenderFns = []

export { render, staticRenderFns }