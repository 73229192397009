






























import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import "vue-scroll-picker/dist/style.css";

// @ts-ignore
import { ScrollPicker } from "vue-scroll-picker";

function formatDate(date: Date): string {
  return `${date.getFullYear()}-${("" + (date.getMonth() + 1)).padStart(
    2,
    "0"
  )}-${("" + date.getDate()).padStart(2, "0")}`;
}

function formatHours(date: Date): string {
  const numHours = date.getHours();
  return numHours < 10 ? "0" + numHours : numHours.toString();
}

function formatMinutes(date: Date): string {
  const numMinutes = date.getMinutes();
  return numMinutes < 10 ? "0" + numMinutes : numMinutes.toString();
}

function buildTwoDigitStringArray(num: number): Array<string> {
  return Array.from(new Array(num), (x, i) =>
    i < 10 ? "0" + i.toString() : i.toString()
  );
}

@Component({
  components: {
    ScrollPicker
  }
})
export default class DateInput extends Vue {
  @Prop({type: [String, Date]}) datetime: string | Date | undefined
  @Prop({type: String}) eventtype: string | undefined
  hours: string[] = buildTwoDigitStringArray(24)
  minutes: string[] = buildTwoDigitStringArray(60)
  date: string = ""
  inputHours: string = ""
  inputMinutes: string = ""

  get today(): string {
    const now = new Date(Date.now());
    return formatDate(now);
  }
  get language() {
    return navigator.language;
  }

  @Watch('date')
  onDateChange(): void {
    let tmpDate = this.getInputDateTime();
    this.$emit("datetimeChange", tmpDate);
  }
  @Watch('inputHours')
  onInputHoursChange(): void {
    let tmpDate = this.getInputDateTime();
    this.$emit("datetimeChange", tmpDate);
  }
  @Watch('inputMinutes')
  onInputMinutesChange(): void {
    let tmpDate = this.getInputDateTime();
    this.$emit("datetimeChange", tmpDate);
  }
  mounted(): void {
    let initialDate = new Date();
    if (this.datetime) {
      initialDate = new Date(this.datetime);
    }
    this.date = formatDate(initialDate);
    this.inputHours = formatHours(initialDate);
    this.inputMinutes = formatMinutes(initialDate);
  }
  getInputDateTime(): Date {
    const nowDateTime = new Date();
    const chosenDateTime = new Date(
      this.date + "T" + this.inputHours + ":" + this.inputMinutes + ":00"
    );
    if (chosenDateTime > nowDateTime) {
      nowDateTime.setSeconds(0);
      nowDateTime.setMilliseconds(0);
      return nowDateTime;
    } else return chosenDateTime;
  }
}
