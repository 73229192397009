
























































import { Component, Watch, Vue } from "vue-property-decorator"
import HomeBack from "@/components/HomeBack.vue"
import LoadingScreen from "@/components/LoadingScreen.vue"
import ShowEvent from "@/views/ShowEvent.vue"
import Event from "@/models/Event"
import ExperienceBubble from "@/components/experience/ExperienceBubble.vue"
import ExperienceWelcome from "@/components/experience/ExperienceWelcome.vue"
import ExperienceSettings from "@/components/experience/ExperienceSettings.vue"
import TrackingRepository from "@/api/TrackingRepository"

function getRandomArbitrary(min: number, max: number) {
  return Math.floor(Math.random() * (max - min) + min)
}

@Component({
  components: {
    LoadingScreen,
    ShowEvent,
    HomeBack,
    ExperienceBubble,
    ExperienceWelcome,
    ExperienceSettings
  }
})
export default class Experience extends Vue {
  showEvent: boolean = false
  showEventId: number = -1
  showSettings: boolean = false
  initialScroll: number = -1
  firstLoading: boolean = true
  sizePerEventConstant: number = 0.31 // event with is 31 vh
  scrollPos: number = 0
  currEventPos: number = 0
  leftHintPos: number = 0
  showHint: boolean = false
  showStartHint: boolean = false
  trigger: string | null = null
  currHintDist: number = 0
  maxHintTextIndex: number = 4
  textIndex: number = 1
  resizeKey: number = Date.now()
  visitedEvents: Set<number> = new Set()
  openedEvents: Set<number> = new Set()

  get getExperienceEvents(): Event[] {
    return this.$store.getters.getExperienceEvents
  }
  get loadingInProgress(): boolean {
    return this.$store.getters.loadingInProgress
  }
  get eventCountTotal(): number {
    return this.$store.getters.eventCountTotal
  }
  get getNrOfExpEvents(): number {
    return this.$store.getters.getNrOfExpEvents
  }
  get getExperienceSettings(): IExperienceSettings {
    return this.$store.getters.getExperienceSettings
  }
  get xpGained(): number {
    return this.$store.getters.getExperienceXpGained
  }
  get xpTotal(): number {
    return this.$store.getters.getExperienceXpMax
  }
  get xpGainedPercentage(): number {
    return (this.xpGained / this.xpTotal) * 100
  }
  get fillup(): number {
    // Calculate number of segments needed, to fillup screen so that all events scroll by middle position
    return Math.floor(window.innerWidth / 2 / this.sizePerEvent)
  }
  get wrapperClasses(): Object {
    return {
      "hide-overflow-y": this.showEventId < 0,
      "hide-background-image": this.showEventId > 0
    }
  }
  get sizePerEvent(): number {
    this.resizeKey
    const height = Math.max(
      document.documentElement.offsetHeight,
      window.innerHeight,
      document.documentElement.clientHeight
    )
    return height * this.sizePerEventConstant
  }
  get currEventIndex(): number {
    // 1.8 as best value for positioning hints and detecting all events
    return Math.floor((this.scrollPos + this.sizePerEvent / 1.8) / this.sizePerEvent)
  }
  get currentEventID(): number {
    let event = this.$store.getters.getEventAt(this.currEventIndex) as Event
    if (event) return event.id
    return -1
  }
  get text(): string {
    if (this.trigger) {
      if (
        this.trigger == "hard_bad" ||
        this.trigger == "hard_good" ||
        this.trigger == "easy_good"
      ) {
        return this.$t("experience_mode." + this.trigger + "." + this.textIndex) + ""
      } else return this.$t("experience_mode." + this.trigger) + ""
    } else return ""
  }

  @Watch("currentEventID")
  onCurrentEventIDChange() {
    this.checkForHint()
    if (this.trigger) {
      let r = getRandomArbitrary(1, this.maxHintTextIndex + 1)
      while (r == this.textIndex) {
        r = getRandomArbitrary(1, this.maxHintTextIndex + 1)
      }
      this.textIndex = r
    }
    this.visitedEvents.add(this.currentEventID)
    this.maybeUpdateXpOnScrolled()
  }

  @Watch("showEvent")
  onShowEventChange() {
    if (!this.showEvent) {
      this.showEventId = -1
    }
  }

  async created(): Promise<void> {
    await this.$store.dispatch("loadExperienceActions")
    if (this.getNrOfExpEvents <= 0) {
      await this.$store.dispatch("loadFirstEvents")
      this.firstLoading = false
    } else {
      this.$store.dispatch("changeIsLoading", false)
      setTimeout(() => {
        this.firstLoading = false
      }, 10)
    }
    if (this.getExperienceSettings.last_pos == 0) {
      await this.$store.dispatch("integrateBackendSettings")
    }
    this.showStartHint = this.$store.getters.getExperienceSettings.show_start_hint
  }
  updated(): void {
    if (!this.firstLoading) {
      const wrapper = document.getElementById("exp_wrapper")
      // When data was initially loaded
      // must be in updated because of dom rendering
      if (wrapper && this.initialScroll == -1) {
        wrapper.addEventListener("scroll", this.handleScroll)
        wrapper.focus()
      }
    }
  }
  mounted(): void {
    this.checkForHint()
    document.getElementsByTagName("html")[0].classList.add("noScrollY")
  }
  beforeDestroy(): void {
    document.getElementsByTagName("html")[0].classList.remove("noScrollY")
  }
  async destroyed(): Promise<void> {
    this.$store.dispatch("updateExperienceSettings", {
      last_pos: this.scrollPos
    })
    const wrapper = document.getElementById("exp_wrapper")
    if (wrapper) {
      wrapper.removeEventListener("scroll", this.handleScroll)
    }
    window.removeEventListener("resize", this.resize)
  }
  async openShowEvent(event: number): Promise<void> {
    if (this.currentEventID == event && this.showHint) {
      const groupID = "EXPE:"
      await TrackingRepository.createLog(groupID + "Klick auf von Begleiter empfohlenes Event")
    }
    this.showEventId = event
    this.openedEvents.add(this.showEventId)
    this.showEvent = true
    this.maybeUpdateXpOnEventOpened()
  }
  async handleScroll(event: any): Promise<void> {
    // Calculate normalized scroll distance
    const left = Math.ceil(event.currentTarget.scrollLeft) // current position
    const width = event.currentTarget.scrollWidth // maximum size
    const scrolled = Math.abs(this.initialScroll - left) // scrolled distance
    this.scrollPos = scrolled
    const leftToScroll = Math.abs(width - scrolled - window.innerWidth) // distance that can still be scrolled
    // load depending on how much is left to scroll and on sizePerEvent
    if (leftToScroll < window.innerWidth && this.getNrOfExpEvents < this.eventCountTotal) {
      if (!this.loadingInProgress) {
        // only load when no pending requests
        await this.$store.dispatch("loadAdditionalFirstEvents")
        let groupID = "EXPE:"
        await TrackingRepository.createLog(
          groupID +
            "Scrollen im Erlebenscreen " +
            this.$store.getters.getNrOfExpEvents +
            " von " +
            this.$store.getters.eventCountTotal +
            " Events geladen"
        )
      }
    }
  }
  maybeUpdateXpOnScrolled(): void {
    this.$store.dispatch("releaseXpOnScroll", {
      numScrolled: this.visitedEvents.size,
      numEvents: this.eventCountTotal
    })
  }
  async maybeUpdateXpOnEventOpened(): Promise<void> {
    await this.$store.dispatch("releaseXpOnOpenedEvent")
  }
  calculateEventPos(): number {
    return (
      this.currEventIndex * this.sizePerEvent +
      this.sizePerEvent / 2 +
      this.fillup * this.sizePerEvent
    )
  }
  checkForHint(): void {
    this.showHint = false
    this.currEventPos = this.calculateEventPos()
    this.leftHintPos = this.currEventPos - this.sizePerEvent / 2
    if (this.leftHintPos <= 0) this.leftHintPos = 10
    this.trigger = this.$store.getters.getEventTrigger(this.currentEventID)
    if (this.trigger) {
      if (
        !(
          this.trigger == "hard_good" ||
          this.trigger == "hard_bad" ||
          this.trigger == "easy_good"
        ) ||
        this.currHintDist >= this.getExperienceSettings.min_hint_distance
      ) {
        this.showHint = true
        this.currHintDist = 0
      }
    } else {
      this.currHintDist++
    }
  }
  scrollToLastPosition(): void {
    const wrapper = document.getElementById("exp_wrapper")
    if (wrapper) wrapper.scrollTo({ left: this.getExperienceSettings.last_pos })
  }
  resize(): void {
    let loadedeventsWidth = (this.getExperienceEvents.length + this.fillup) * this.sizePerEvent
    let wrapper = document.getElementById("exp_wrapper")
    if (wrapper) {
      if (
        wrapper.clientWidth > loadedeventsWidth &&
        this.getExperienceEvents.length < this.eventCountTotal
      ) {
        this.$store.dispatch("loadAdditionalFirstEvents")
      }
    }
    this.resizeKey = Date.now()
    this.checkForHint()
  }
  async logSettings(): Promise<void> {
    let groupID = "EXPE:"
    await TrackingRepository.createLog(groupID + "Erleben Einstellungen öffnen")
  }
}

interface IExperienceSettings {
  show_initial_hint: boolean
  show_start_hint: boolean
  last_pos: number
  min_hint_distance: number
  ask_preferences: boolean
  hint_type: "all" | "activities" | "negative" | "positive"
}
