export const numDays = (ts0: number, ts1: number): number => {
  return _msToDays(ts1 - ts0);
};

export const today = () => {
  return Date.now() - _millisecondsOfDayPassed();
};

export const daysAgo = (numDays: number) => {
  return Date.now() - _daysToMs(numDays) - _millisecondsOfDayPassed();
};

const _millisecondsOfDayPassed = () => {
  return Date.now() % (1000 * 60 * 60 * 24);
};

//conversions
const _msToDays = (ms: number) => {
  return ms / (1000 * 60 * 60 * 24);
};

const _daysToMs = (days: number) => {
  return days * 1000 * 60 * 60 * 24;
};
