import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"yc_eventform_div"},[_c('h3',[_vm._v(_vm._s(_vm.$t("difficulty_headline")))]),_c(VContainer,{staticClass:"yc_eventform_cards"},[_c(VRow,{attrs:{"justify":"center"}},_vm._l(([1, 2, 3, 4]),function(difficulty,index){return _c(VCol,{key:'difficulty' + index,staticClass:"col-sm-4 col-xl-2 flex6",attrs:{"data-cy":"difficultylevel","cols":6}},[_c(VCard,{staticClass:"yc_eventform_card",class:[
            { yc_eventform_card_selected: _vm.value === difficulty },
            _vm.eventtype === 'clinic' ? 'clinic_event' : 'home_event' ],attrs:{"outlined":"","raised":""},on:{"click":function($event){_vm.$emit('input', difficulty), _vm.$emit('nextStep')}}},[_c('div',{staticClass:"yc_card_vertical_center"},[_c(VImg,{attrs:{"src":_vm.getDifficultyImage(difficulty, _vm.value === difficulty),"contain":"","height":"70px"}}),_c('div',{staticClass:"mt-1"},[_c('i18n',{attrs:{"path":'difficultyLevel.difficulty_' + difficulty}},[_vm._v("­")])],1)],1)])],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }