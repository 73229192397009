














































import { Component, Prop, Watch } from 'vue-property-decorator'
import VueStepper from './VueStepper'
import Speechbubble from '@/components/onboarding/Speechbubble.vue'
import Companion from '@/components/onboarding/Companion.vue'
import TypingBubble from '@/components/onboarding/onb_chat/TypingBubble.vue'
import "vue-scroll-picker/dist/style.css"

// @ts-ignore
import { ScrollPicker } from 'vue-scroll-picker'

@Component({
    components: {
      Speechbubble,
      Companion,
      TypingBubble,
      ScrollPicker
    }
})
export default class InputBirthday extends VueStepper {
    @Prop({type: String, default: ''}) avatarPath!: string
    stepTimes: number[] = [2000, 2000, 0, 1500] //typing, question birthdate, birthdate input, waiting after birthdate
    birthdate: string = ""
    monthsLenghts: number[] = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
    showError: boolean = false
    inputDay: number = 1
    inputMonth: number = 1
    inputYear: number = 2000
    days: number[] = Array.from(new Array(31), (x, i) => i + 1)
    months: {name: string, value: number}[] = []
    years: number[] = Array.from(new Array(35), (x, i) => i + (new Date().getFullYear() - 34))

    isLeapYear(year: number): boolean
    {
        return ((year % 4 == 0) && (year % 100 != 0)) || (year % 400 == 0);
    }
    validateBirthday(skip: boolean): void {
        
        if(skip) {  
            this.$emit('birthdateChanged', '');
            this.stepper()
            return
        }
        
        let selectedDate = new Date(this.inputYear, this.inputMonth, this.inputDay)
        // TODO cleanup from moment.js removal
        //let selectedDate = moment([this.inputYear, this.inputMonth, this.inputDay])

        // selected date is in future 
        //if(moment().diff(selectedDate, 'seconds') < 0) {
        if((new Date().getTime() - selectedDate.getTime()) < 0) {
            this.showError = true; 
            return
        }

        // TODO check if alter < als ?? 

        var maxDaysOfMonth = this.monthsLenghts[this.inputMonth - 1]

        // check if leap year for february, correct num days of february
        //if(this.inputMonth == 2 && moment([this.inputYear]).isLeapYear()) {
        if(this.inputMonth == 2 && this.isLeapYear(this.inputYear)) {
            maxDaysOfMonth += 1
        }

        // check if not a valid date 
        if(this.inputDay > maxDaysOfMonth) {
            this.showError = true; 
            return 
        }

        this.showError = false;

        this.birthdate = this.inputDay+"."+this.inputMonth+"."+this.inputYear
        this.$emit('birthdateChanged', new Date(this.inputYear, this.inputMonth, this.inputDay).toISOString());
        this.stepper()
    }
    getUserString_Birthdate(): string {
        if(this.birthdate) 
            return this.$t('onboarding.user.my_birthday_is')+' '+this.birthdate+'.';
        else 
            return this.$t('onboarding.user.not_tell_birthdate')+'';
    }

    get labelText(): string{
        return this.showError ? this.$t("onboarding.error.invalid_birthday")+''  : "";
    }

    created(): void {
        this.stepper()
        for(let i = 0; i < 12;) {
            this.months.push({
                name: <string>this.$t(`months[${i}]`),
                value: ++i
            })
        }
    }

    @Watch('step')
    onStepChange() {
        if(this.step < this.stepTimes.length && this.step != 2)
            this.stepper();
        else if(this.step >= this.stepTimes.length)
            this.$emit('nextOnbPart');
    }
}
