import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VRow,{attrs:{"justify":"center"}},[(_vm.isFriend || _vm.isRequested)?_c('div',[_c(VMenu,{attrs:{"offset-y":"","offset-x":"","auto":""},scopedSlots:_vm._u([(_vm.isFriend)?{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({staticClass:"mr-2",attrs:{"fab":"","small":"","color":"green","data-cy":"editfriendbtn"}},on),[_c(VIcon,[_vm._v("mdi-account-check")])],1)]}}:(_vm.isRequested)?{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({staticClass:"mr-2",attrs:{"fab":"","small":"","color":""}},on),[_c(VIcon,{attrs:{"data-cy":"accountquestion"}},[_vm._v("mdi-account-question")])],1)]}}:null],null,true)},[_c(VList,{staticClass:"friendbuttons"},[(_vm.isRequestedToMe)?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VListItem,{staticClass:"px-3"},[_c(VBtn,_vm._g({attrs:{"small":"","fab":"","color":"green"},on:{"click":function($event){return _vm.accept(_vm.relationId)}}},on),[_c(VIcon,[_vm._v("mdi-account-plus")])],1)],1)]}}],null,false,609235125)},[_c('span',[_vm._v(_vm._s(_vm.$t("accept_friend_request")))])]):_vm._e(),(!_vm.isBlocked)?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VListItem,{staticClass:"px-3"},[_c(VBtn,_vm._g({attrs:{"small":"","fab":"","color":"red"},on:{"click":function($event){return _vm.remove(_vm.relationId, _vm.userId, _vm.isFriend, _vm.isRequestedToMe, _vm.isBlocked)}}},on),[_c(VIcon,{attrs:{"data-cy":"accountremovebtn"}},[_vm._v("mdi-account-remove")])],1)],1)]}}],null,false,1086374956)},[(_vm.isFriend)?_c('span',[_vm._v(_vm._s(_vm.$t("remove_friend_hint")))]):(_vm.isRequested)?_c('span',[_vm._v(_vm._s(_vm.$t("remove_friend_request_hint")))]):_vm._e()]):_vm._e(),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VListItem,{staticClass:"px-3"},[_c(VBtn,_vm._g({attrs:{"small":"","fab":"","color":"red darken-1"},on:{"click":function($event){return _vm.block({ user: _vm.userId, relation: _vm.relationId })}}},on),[_c(VIcon,{attrs:{"data-cy":"blockpersonbtn"}},[_vm._v("mdi-cancel")])],1)],1)]}}],null,false,2805615329)},[_c('span',[_vm._v(_vm._s(_vm.$t("block_person_hint")))])])],1)],1)],1):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isFriend && !_vm.isRequested && !_vm.isBlocked),expression:"!isFriend && !isRequested && !isBlocked"}]},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"fab":"","small":"","color":""},on:{"click":function($event){return _vm.sendRequest(_vm.userId)}}},on),[_c(VIcon,{attrs:{"data-cy":"sendrequestbtn"}},[_vm._v("mdi-account-plus")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("add_person_hint")))])]),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({staticClass:"ml-2",attrs:{"fab":"","small":"","color":""},on:{"click":function($event){return _vm.block({ user: _vm.userId })}}},on),[_c(VIcon,{attrs:{"data-cy":"blockuserbtn"}},[_vm._v("mdi-cancel")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("block_person_hint")))])])],1),(_vm.isBlocked)?_c('div',[(_vm.isBlocked)?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({staticClass:"ml-2",attrs:{"fab":"","small":"","color":"red"},on:{"click":function($event){return _vm.remove(_vm.relationId, _vm.userId, _vm.isFriend, _vm.isRequestedToMe, _vm.isBlocked)}}},on),[_c(VIcon,{attrs:{"data-cy":"unblockuserbtn"}},[_vm._v("mdi-account-cancel")])],1)]}}],null,false,3828713261)},[_c('span',[_vm._v(_vm._s(_vm.$t("profileinteractions.hints.unblock")))])]):_vm._e()],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }