import { Module } from "vuex";
import { UserState, RootState } from "../types";
import User from '@/models/User';
import UserRepository from '@/api/UserRepository';
import i18n from '@/locales/i18n'

export const userModule: Module<UserState, RootState> = {
    state: {
        userdata: {} as User
    },
    getters: {
        getUserData: state => (key: keyof User) => {
            return state.userdata[key];
        },
        getUser(state): User{
            return state.userdata;
        },
        userIsSearchable(state){
            return state.userdata.is_searchable;
        }
    },
    mutations: {
        changeUserData(state, payload){
            state.userdata = {...state.userdata, ...payload};
        },
        removeAvatar(state){
            state.userdata.avatar = '';
        }
    },
    actions: {
        async integrateUserDataFromBackend({commit}){
            try{
                const beUser = await UserRepository.getUser(this.getters.userId, true);
                commit('changeUserData', beUser);
            }catch(error){
                this.dispatch('alert/error', i18n.t('error_messages.user'));
                /*eslint-disable no-console */
                console.error("error while fetching user: "+error);
            }
        },
        async updateUser({state, commit, dispatch}, payload){
            try{
                await UserRepository.updateUser(state.userdata.id, payload);
                commit('changeUserData', payload);
                if(Object.keys(payload).indexOf('name') > -1){
                    dispatch('updateUserName', payload);
                }
            }catch(error){
                this.dispatch('alert/error', i18n.t('error_messages.update_user'));
                /*eslint-disable no-console */
                console.error("error while updating user: "+error);
            }
        },
        async changeUserSearchable({state, commit}, searchable){
            const payload = {is_searchable: searchable} as User;
            try{
                await UserRepository.updateUser(state.userdata.id, payload);
                commit('changeUserData', payload);
            }catch(error){
                this.dispatch('alert/error', i18n.t('error_messages.update_user'));
                /*eslint-disable no-console */
                console.error("error while updating user: "+error);
            }
        },
        async addUserAvatar({state, dispatch}, path){
            try{
                const formData = new FormData();
                let blob = null;
                const xhr = new XMLHttpRequest();
                xhr.open("GET", path);
                xhr.responseType="blob";
                xhr.onload = async () => {
                    blob = xhr.response;
                    const file = new File([blob], 'avatar.png');
                    formData.append('file', file);
                    await UserRepository.addAvatar(state.userdata.id, formData);
                    dispatch('integrateUserDataFromBackend');
                }
                xhr.send();
            }
            catch(error){
                this.dispatch('alert/error', i18n.t('error_messages.store_avatar'));
                /*eslint-disable no-console */
                console.error("error while saving user avatar: "+error);
            }
        },
        /*
        async removeUserAvatar({state, commit, dispatch}){
            try{
                await UserRepository.deleteAvatar(state.userdata.id);
                commit('removeAvatar');
            }catch(error){
                this.dispatch('alert/error', i18n.t('error_messages.delete_avatar'));
                /*eslint-disable no-console 
                console.error("error while removing user avatar: "+error);
            }
        },
        */
        async markForDeletion({state, dispatch}) {
            try {
                await UserRepository.markForDeletion(state.userdata.id);
                dispatch('logout');
            } catch(error) {
                this.dispatch('alert/error', i18n.t('error_messages.mark_for_deletion'));
                /*eslint-disable no-console */
                console.error("error while removing user avatar: "+error);
            }
        },
        /*
        async markForDeletion({state, dispatch}) {
            try {
                await UserRepository.markForDeletion(state.userdata.id);
                dispatch('logout');
            } catch(error) {
                this.dispatch('alert/error', i18n.t('error_messages.mark_for_deletion'));
                /*eslint-disable no-console 
                console.error("error while removing user avatar: "+error);
            }
        }*/
    }
};

export default userModule;