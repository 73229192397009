



























































































import { Component, Vue, Watch } from "vue-property-decorator"
import Introduction from "@/components/onboarding/onb_chat/Introduction.vue"
import InputLanguage from "@/components/onboarding/onb_chat/InputLanguage.vue"
import InputGender from "@/components/onboarding/onb_chat/InputGender.vue"
import InputAvatar from "@/components/onboarding/onb_chat/InputAvatar.vue"
import InputName from "@/components/onboarding/onb_chat/InputName.vue"
import InputBirthday from "@/components/onboarding/onb_chat/InputBirthday.vue"
import InputDiagnosis from "@/components/onboarding/onb_chat/InputDiagnosis.vue"
import InputInitialDiagnosis from "@/components/onboarding/onb_chat/InputInitialDiagnosis.vue"
import InputClinic from "@/components/onboarding/onb_chat/InputClinic.vue"
import InputClinicStatus from "@/components/onboarding/onb_chat/InputClinicStatus.vue"
import InputPrivacyOption from "@/components/onboarding/onb_chat/InputPrivacyOption.vue"
import InputEvent from "@/components/onboarding/onb_chat/InputEvent.vue"
import EventForm from "@/views/EventForm.vue"
import UserRepository from "@/api/UserRepository"
import User from "../models/User"
import Diagnosis from "@/models/Diagnosis"
import Clinic from "@/models/Clinic"
import Gender from "@/models/Gender"

@Component({
  components: {
    Introduction,
    InputLanguage,
    InputAvatar,
    InputGender,
    InputName,
    InputBirthday,
    InputDiagnosis,
    InputInitialDiagnosis,
    InputClinic,
    InputClinicStatus,
    InputPrivacyOption,
    InputEvent,
    EventForm
  }
})
export default class Onboarding extends Vue {
  step: number = -1
  userName: string = ""
  sex: Gender | null = null
  avatarPath: string = ""
  birthdate: string = ""
  diagnosis: Diagnosis | null = null
  initialDiagnosis: string = ""
  clinic: Clinic | null = null
  atHospital: boolean = false
  showChat: boolean = true
  addEvent: boolean = false
  showXP: boolean = false
  privacyOption: boolean = true
  language: string = navigator.language.substring(0, 2).toLowerCase()

  mounted(): void {
    this.$i18n.locale = this.language
    setTimeout(() => this.step++, 3000)
  }
  getCompanionString_BirthdayDiagnosis(): string {
    if (this.birthdate) return this.$t("onboarding.companion.question_diagnosis") + ""
    else return this.$t("onboarding.companion.reply_birthdate_diagnosis") + ""
  }
  getCompanionString_InitialDiagnosis(): string {
    if (this.diagnosis) return this.$t("onboarding.companion.question_initial_diagnosis") + ""
    return this.$t("onboarding.companion.reply_understanding_initial") + ""
  }
  finishOnboardingChat(): void {
    this.saveUserData()
    this.$store.dispatch("updateOnboardingSettings", {
      onboarding_chat: false
    })
    this.$router.push({ name: "home" })
  }
  async saveUserData(): Promise<void> {
    let payload = {
      name: this.userName,
      initial_diagnosis: this.initialDiagnosis ? this.initialDiagnosis.split("T")[0] : null,
      diagnosis: this.diagnosis ? this.diagnosis.id : null,
      birthdate: this.birthdate ? this.birthdate.split("T")[0] : null,
      sex: this.sex ? this.sex.id : null,
      treated_at: this.clinic ? this.clinic.id : null,
      at_clinic: this.atHospital,
      is_searchable: this.privacyOption,
      language: this.language
    } as unknown as User
    this.$store.dispatch("addUserAvatar", this.avatarPath)
    try {
      await UserRepository.updateUser(this.$store.getters.userId, payload)
      this.$emit("userUpdated", Date.now())
    } catch (error) {
      this.$store.dispatch("alert/error", this.$t("error_messages.update_user"))
      /*eslint-disable no-console */
      console.error("error while updating user: " + error)
    }
  }

  @Watch("language")
  onLanguageChange(lang: string) {
    this.$i18n.locale = lang
  }
}
