





































import { Vue, Component, Prop } from 'vue-property-decorator'
import EventRepository from "@/api/EventRepository";

@Component({})
export default class LocationInput extends Vue {
  @Prop({type: String}) value: string | undefined
  locations: String[] = []

  created() {
    this.fetchLocations()
  }

  async fetchLocations(): Promise<void> {
    try {
      this.locations = await EventRepository.getLocations();
    } catch (error) {
      this.$store.dispatch(
        "alert/error",
        this.$t("error_messages.locations")
      );
      /*eslint-disable no-console*/
      console.error("Error while fetching locations: " + error);
    }
  }
}
