












import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({})
export default class Speechbubble extends Vue {
    @Prop({type: Boolean, default: false}) showTick!: boolean
    @Prop({type: Boolean, default: false}) left!: boolean
    @Prop({type: Boolean, default: false}) disableTick!: boolean
    @Prop({type: String}) text: string | undefined

    nextStep(): void {
        this.$emit('nextStep')
    }
    mounted(): void {
        this.$emit('rendered')
    }
}
