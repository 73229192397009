





























import { Component, Prop, Watch } from 'vue-property-decorator'
import VueStepper from './VueStepper'
import Speechbubble from '@/components/onboarding/Speechbubble.vue'
import Companion from '@/components/onboarding/Companion.vue'
import TypingBubble from '@/components/onboarding/onb_chat/TypingBubble.vue'
import BooleanButtons from '@/components/onboarding/onb_chat/BooleanButtons.vue'


@Component({
    components: {
      Speechbubble,
      Companion,
      TypingBubble,
      BooleanButtons
    }
})
export default class InputClinicStatus extends VueStepper {
    @Prop({type: String, default: ''}) avatarPath!: string
    stepTimes: number[] = [0, 1500, 2000, 2000] //yes no answer, waiting, typing, waiting after response
    atHospital: string = ''

    @Watch('step')
    onStepChange() {
        if(this.step < this.stepTimes.length)
            this.stepper();
        else if(this.step >= this.stepTimes.length)
            this.$emit('nextOnbPart');
    }
}
