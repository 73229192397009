import { VueConstructor } from 'vue/types/umd';
//import ContentRepository from "@/api/ContentRepository";
import { DirectiveBinding } from 'vue/types/options';
import MediaRepository from "@/api/MediaRepository";

async function setSource(el: HTMLElement, binding: DirectiveBinding) {
    if(binding.oldValue && binding.value === binding.oldValue) {
        return;
    }

    try {
        //const encoded = await ContentRepository.getAuthenticated(imageUrl);

        const encoded = await MediaRepository.getPartnerEncodedContent('profile', binding.value.avatar, binding.value.id);
        (el as HTMLSourceElement).src = encoded.toDataURI();
    } catch (err) {
        (el as HTMLSourceElement).src = binding.value.avatar;
    }
}

export default {

    install(Vue: VueConstructor<Vue>) {

        Vue.directive('auth-src', {
            bind: setSource,
            componentUpdated: setSource
        })
    }
}