import Repository from "./Repository"
import Relation from "@/models/Relation"

const resource = "/v1/relations/"

class RelationsRepository {
  async getRelations(): Promise<Relation[]> {
    const response = await Repository.get(resource)
    return await response.json()
  }

  async deleteRelation(relationsId: Number) {
    await Repository.delete(`${resource}${relationsId}/`)
  }

  async blockUser(userId: Number): Promise<Relation> {
    const response = await Repository.post(resource, {
      to_user: userId,
      status: "blocked"
    })
    return await response.json()
  }

  async sendRelationRequest(userId: Number): Promise<Relation> {
    const response = await Repository.post(resource, { to_user: userId })
    return await response.json()
  }

  async confirmRelationRequest(relationsId: Number): Promise<Relation> {
    const response = await Repository.patch(`${resource}${relationsId}/`, {
      status: "confirmed"
    })
    return await response.json()
  }

  async deleteRelationRequest(relationsId: Number) {
    await Repository.delete(`${resource}${relationsId}/`)
  }
}

export default new RelationsRepository()
