










































































import { Component, Prop, Watch, Vue } from "vue-property-decorator"
import ForeignProfile from "@/components/social/ForeignProfile.vue"
import EventRepository from "@/api/EventRepository"
import ProfileAvatar from "@/components/social/ProfileAvatar.vue"
import EventChain from "@/components/social/profile/EventChain.vue"
import UserRepository from "@/api/UserRepository"
import User from "@/models/User"
import TrackingRepository from "@/api/TrackingRepository"
import ProgressMarkerRepository from "@/api/ProgressMarkerRepository"
import ProgressMarker from "@/models/ProgressMarker"
import { DateTimeFormatOptions } from "vue-i18n"
import ProgressMarkerInfo from "@/components/social/ProgressMarkerInfo.vue"

@Component({
  components: {
    ForeignProfile,
    ProfileAvatar,
    EventChain,
    ProgressMarkerInfo
  }
})
export default class ProfileDetails extends Vue {
  @Prop({ type: Object, required: true }) user!: User
  @Prop({ type: Boolean, default: false }) ownprofile!: boolean
  @Prop({ type: Boolean, default: false }) isOverlay!: boolean
  showEventchain: boolean = false
  xpToDistanceFactor: number = 10
  totalEvents: string = "0"
  marker: ProgressMarker | null = null
  timestamp: number = Date.now()
  unsubscribe: Function = () => {}
  _userShown: User | null = null

  get age(): number {
    const user_birthday = new Date(this.user.birthdate as string)
    const today_date = new Date()
    const today_year = today_date.getFullYear()
    const today_month = today_date.getMonth()
    const today_day = today_date.getDay()
    let age = today_year - user_birthday.getFullYear()
    if (today_month < user_birthday.getMonth() - 1) {
      age--
    }
    if (user_birthday.getMonth() - 1 == today_month && today_day < user_birthday.getDay()) {
      age--
    }
    return age
  }
  get gender(): string {
    return this.user.sex === "F" ? "female" : "male"
  }
  get avatarFromStore(): string {
    return this.$store.getters.getUserData("avatar")
  }
  get isFriend(): boolean {
    return this.$store.getters.relations.some(
      (friend: any) => friend.to_user.id == this.user.id || friend.from_user.id == this.user.id
    )
  }
  get userShown(): User {
    return this._userShown || this.user
  }
  set userShown(user: User) {
    this._userShown = user
  }
  get markerName(): string {
    return this.marker?.name || "-"
  }
  get markerValue(): number {
    return this.marker?.value || 0
  }

  @Watch("user")
  onUserChange(): void {
    this._userShown = null
    this.$nextTick(() => {
      this._userShown = this.user
      this.logOpenProfile()
    })
  }

  async mounted(): Promise<void> {
    await this.loadEvents()

    this.marker = await ProgressMarkerRepository.get()

    this.logOpenProfile()
    this.$store.subscribe((mutation) => {
      if (
        mutation.type === "addRelation" ||
        mutation.type === "removeRelation" ||
        mutation.type === "updateRelation"
      ) {
        this.fetchForeignUser(this.user.id)
      } else if (mutation.type === "changeUserData" || mutation.type === "removeAvatar") {
        this.integrateUserDataFromStore()
      }
    })
  }
  beforeDestroy(): void {
    this.unsubscribe()
  }
  async logOpenProfile(): Promise<void> {
    const groupID = "CHAT:"
    if (this.isFriend)
      await TrackingRepository.createLog(
        groupID + "Profilansicht befreundeter User " + this.user.id
      )
    else await TrackingRepository.createLog(groupID + "Profilansicht fremder User " + this.user.id)
  }
  async changeUserLocation(_inClinic: boolean): Promise<void> {
    const payload = { at_clinic: _inClinic }
    if (this._userShown) this._userShown.at_clinic = _inClinic
    await this.$store.dispatch("updateUser", payload)
  }
  formatDate(date: string): string {
    const dateObject: Date = new Date(date)
    const options: DateTimeFormatOptions = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric"
    }
    return dateObject.toLocaleDateString("de-DE", options)
  }
  async loadEvents(): Promise<void> {
    try {
      const events = await EventRepository.get(1, 0)
      this.totalEvents = events.count + ""
      // this.reachedDistance = events.count * this.xpToDistanceFactor;
    } catch (error) {
      this.totalEvents = this.$t("error_messages.events") + ""
      this.$store.dispatch("alert/error", this.$t("error_messages.events"))
      /*eslint-disable no-console */
      console.error("Error while fetching events: " + error)
    }
  }
  async logEventChain(): Promise<void> {
    let groupID = "HOME:"
    await TrackingRepository.createLog(groupID + "Ereigniskette öffnen")
  }
  async fetchForeignUser(userId: number): Promise<void> {
    try {
      this.userShown = await UserRepository.getUser(userId, true)
    } catch (error) {
      this.$store.dispatch("alert/error", this.$t("error_messages.user"))
      /* eslint-disable no-console */
      console.error("Error while fetching user: " + error)
    }
  }
  integrateUserDataFromStore(): void {
    this.userShown = this.$store.getters.getUser
    if (Object.keys(this.user).length == 0) {
      this.$store.dispatch("alert/error", this.$t("error_messages.user"))
      /* eslint-disable no-console */
      console.error("Error while integrating store data")
    }
  }
}
