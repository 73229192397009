

































































import { Component, Prop, Watch } from 'vue-property-decorator'
import VueStepper from './VueStepper'
import Speechbubble from "@/components/onboarding/Speechbubble.vue";
import Companion from "@/components/onboarding/Companion.vue";
import TypingBubble from "@/components/onboarding/onb_chat/TypingBubble.vue";
import OnboardingSelect from "@/components/onboarding/onb_chat/OnboardingSelect.vue";
import Clinic from "@/models/Clinic";

@Component({
  components: {
    Speechbubble,
    Companion,
    TypingBubble,
    OnboardingSelect,
  }
})
export default class InputClinic extends VueStepper {
  @Prop({type: String, default: ''}) avatarPath!: string
  stepTimes: number[] = [2000, 2000, 0, 1500, 2000, 2000] //typing, question clinic, clinic input, waiting after clinic, typing, question currentlyclinic / reply no clinic
  clinic: Clinic | null = null

  created(): void {
    this.stepper()
  }

  @Watch('step')
  onStepChange() {
    if (this.step < this.stepTimes.length && this.step != 2) this.stepper();
    else if (this.step >= this.stepTimes.length) this.$emit("nextOnbPart");
  }

  getUserString_Clinic(): string {
    if (this.clinic && this.clinic.address != null)
      return (
        this.$t("onboarding.user.my_clinic_is") +
        " " +
        this.clinic.address +
        " - " +
        this.clinic.name +
        "."
      );
    return this.$t("onboarding.user.reply_other_clinic") + "";
  }
  getCompanionString_ReplyClinic(): string {
    if (this.clinic && this.clinic.address != null)
      return (
        this.$t("onboarding.companion.question_currently_at_clinic") + ""
      );
    return this.$t("onboarding.companion.reply_no_clinic") + "";
  }
}
