import {
  EBannerNotificationType,
  LastUsedNotification,
  MountainReachedNotification,
  Notification,
  SymptomNotification,
  UserNotification
} from "@/models/Notification"
import i18n from "@/locales/i18n"
import router from "@/router/index"
import { getDateDiffDays, getJSDate } from "@/utils/utils"
import store from "@/store"

export default class NotificationHelper {
  notification: Notification

  constructor(notification: Notification) {
    this.notification = notification
  }

  getNotificationButtonText(): string | null {
    const translateKey = `notificationsText.${this.notification.key}.buttonText`

    switch (this.notification.type) {
      case EBannerNotificationType.FRIEND_REQUEST_ACCEPTED: {
        const notif = this.notification as UserNotification
        const username = notif.data.name
        return i18n.tc(translateKey, undefined, { username })
      }

      case EBannerNotificationType.FRIEND_IN_CLINIC: {
        const notif = this.notification as UserNotification
        const friendname = notif.data.name
        return i18n.tc(translateKey, undefined, { friendname })
      }

      case EBannerNotificationType.FOREIGN_USER_IN_CLINIC: {
        const notif = this.notification as UserNotification
        const foreignname = notif.data.name
        return i18n.tc(translateKey, undefined, { foreignname })
      }
      default:
        return i18n.te(translateKey) ? i18n.tc(translateKey) : null
    }
  }
  getNotificationText(): string {
    const translateKey = `notificationsText.${this.notification.key}.text`

    switch (this.notification.type) {

      case EBannerNotificationType.REMINDER_TODAY_NO_EVENT: {
        const username = store.getters.getUserData("name");
        return i18n.tc(translateKey, undefined, { username })
      }

      case EBannerNotificationType.REMINDER_YESTERDAY_NO_EVENT: {
        const username = store.getters.getUserData("name");
        return i18n.tc(translateKey, undefined, { username })
      }

      case EBannerNotificationType.REMINDER_ONGOING_SYMPTOM: {
        const notif = this.notification as SymptomNotification
        const lastUsed = getJSDate(notif.data.last_measurement)
        const numDays = getDateDiffDays(lastUsed)
        const symptomName = i18n.tc("symptom." + notif.data.name + ".title")
        return i18n.tc(translateKey, undefined, { numDays, symptomName })
      }

      case EBannerNotificationType.REMINDER_ENTER_TEMP_AND_WEIGHT: {
        const notif = this.notification as SymptomNotification
        const lastUsed = getJSDate(notif.data.last_measurement)
        const numDays = getDateDiffDays(lastUsed)
        const weightOrTemperatureName = i18n.tc("symptom." + notif.data.name + ".title")
        return i18n.tc(translateKey, undefined, {
          numDays,
          weightOrTemperatureName
        })
      }

      case EBannerNotificationType.REMINDER_VISIT_EXPERIENCE_MODE: {
        const notif = this.notification as LastUsedNotification
        const lastUsed = getJSDate(notif.data.last_used)
        const numDays = getDateDiffDays(lastUsed)
        return i18n.tc(translateKey, undefined, { numDays })
      }

      case EBannerNotificationType.NEW_FRIEND_REQUEST: {
        const notif = this.notification as UserNotification
        const username = notif.data.name
        return i18n.tc(translateKey, undefined, { username })
      }

      case EBannerNotificationType.FRIEND_REQUEST_ACCEPTED: {
        const notif = this.notification as UserNotification
        const username = notif.data.name
        return i18n.tc(translateKey, undefined, { username })
      }

      case EBannerNotificationType.FRIEND_IN_CLINIC: {
        const notif = this.notification as UserNotification
        const username = store.getters.getUserData("name");
        const friendname = notif.data.name
        return i18n.tc(translateKey, undefined, { username, friendname })
      }

      case EBannerNotificationType.FOREIGN_USER_IN_CLINIC: {
        const notif = this.notification as UserNotification
        const username = store.getters.getUserData("name");
        const foreignname = notif.data.name
        return i18n.tc(translateKey, undefined, { username, foreignname })
      }

      case EBannerNotificationType.REMINDER_VISIT_EVENT_CHAIN: {
        const notif = this.notification as LastUsedNotification
        const lastUsed = getJSDate(notif.data.last_used)
        const numDays = getDateDiffDays(lastUsed)
        return i18n.tc(translateKey, undefined, { numDays })
      }

      case EBannerNotificationType.REACHED_PROGRESS_MARKER: {
        const notif = this.notification as MountainReachedNotification
        const mountainName = notif.data.name
        const mountainHeight = notif.data.value
        return i18n.tc(translateKey, undefined, {
          mountainHeight,
          mountainName
        })
      }

      case EBannerNotificationType.BIRTHDAY: {
        const username = store.getters.getUserData("name");
        return i18n.tc(translateKey, undefined, { username })
      }

      default:
        return i18n.tc(translateKey)
    }
  }
  triggerAccept(): void {
    switch (this.notification.type) {
      case EBannerNotificationType.REMINDER_ONGOING_SYMPTOM: {
        router.push({ name: "tracking" })
        break
      }
      case EBannerNotificationType.REMINDER_ENTER_TEMP_AND_WEIGHT: {
        router.push({ name: "tracking" })
        break
      }
      case EBannerNotificationType.REMINDER_TODAY_NO_EVENT: {
        store.dispatch("openCreateEventOverlay")
        break
      }
      case EBannerNotificationType.REMINDER_YESTERDAY_NO_EVENT: {
        store.dispatch("openCreateEventOverlay")
        break
      }
      case EBannerNotificationType.MOTIVATION_BAD_EVENTS: {
        undefined
        break
      }
      case EBannerNotificationType.REMINDER_VISIT_EXPERIENCE_MODE: {
        //store.dispatch("openExperienceMode")
        router.push({ name: "home" })
        break
      }
      case EBannerNotificationType.REMINDER_OPEN_XP: {
        //store.dispatch("openExperienceMode")
        router.push({ name: "home" })
        break
      }
      case EBannerNotificationType.NEW_FRIEND_REQUEST: {
        const notif = this.notification as UserNotification
        store.dispatch("openUserProfileOverlay", notif.data.user_id)
        break
      }
      case EBannerNotificationType.FRIEND_REQUEST_ACCEPTED: {
        //kinda works, still needs to open chat of specific user
        router.push({ name: "chatList" })
        break
      }
      case EBannerNotificationType.FOREIGN_USER_IN_CLINIC: {
        const notif = this.notification as UserNotification
        store.dispatch("openUserProfileOverlay", notif.data.user_id)
        break
      }
      case EBannerNotificationType.MOTIVATION_BAD_DAILY_MOOD: {
        break
      }
      case EBannerNotificationType.REMINDER_ADJUST_STATUS: {
        const at_clinic = this.notification.key === "adjust-clinic-status"
        store.dispatch("updateUser", { at_clinic: !at_clinic })
        break
      }
      case EBannerNotificationType.REMINDER_VISIT_EVENT_CHAIN: {
        router.push({ name: "eventchain" })
        break
      }
      case EBannerNotificationType.REACHED_PROGRESS_MARKER: {
        store.dispatch("openCreateEventOverlay")
        break
      }
      case EBannerNotificationType.BIRTHDAY: {
        store.dispatch("openCreateEventOverlay")
        break
      }
      case EBannerNotificationType.REMINDER_SURVEY: {
        break
      }
    }
  }
}
