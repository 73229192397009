


























import { Component, Watch } from 'vue-property-decorator'
import VueStepper from './VueStepper'
import Speechbubble from '@/components/onboarding/Speechbubble.vue'
import Companion from '@/components/onboarding/Companion.vue'
import TypingBubble from '@/components/onboarding/onb_chat/TypingBubble.vue'

@Component({
    components: {
        Speechbubble,
        Companion,
        TypingBubble
    }
})
export default class Introduction extends VueStepper {
    stepTimes: number[] = [500, 2000, 2000, 2000, 2000] // offset, typing, speech, typing, speech

    created(): void{
      this.stepper();
    }

    @Watch('step')
    onStepChange() {
        if(this.step < this.stepTimes.length)
            this.stepper();
        else
            this.$emit('nextOnbPart');
    }
}
