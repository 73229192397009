





import { Component, Vue } from 'vue-property-decorator'
import Speechbubble from '@/components/onboarding/Speechbubble.vue'

@Component({
  components: {
    Speechbubble
  }
})
export default class TypingBubble extends Vue {
  giftPath: string = "@/assets/typingPreloader.gif"

  mounted() {
    this.$emit('rendered');
  }
}
