




















import { Component, Watch, Vue } from "vue-property-decorator"

@Component({})
export default class GuideSafari extends Vue {
  instructionImages: NodeRequire[] = []

  created(): void {
    const { locale } = this.$i18n
    this.instructionImages.push(
      require(`@/assets/instructions/safari.01.${locale}.webp`),
      require(`@/assets/instructions/safari.02.${locale}.webp`),
      require(`@/assets/instructions/safari.03.${locale}.webp`)
    )
  }

  @Watch("$i18n.locale")
  onLocaleChange(locale: string): void {
    this.instructionImages = [
      require(`@/assets/instructions/safari.01.${locale}.webp`),
      require(`@/assets/instructions/safari.02.${locale}.webp`),
      require(`@/assets/instructions/safari.03.${locale}.webp`)
    ]
  }
}
