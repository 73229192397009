import Repository from "./Repository";
const resource = "/v1/settings/";

class SettingsRepository {

  async editSettings(payload: Object): Promise<any> {
    const response = await Repository.patch(resource, payload);
    return await response.json();
  }

  async getSettings(): Promise<any> {
    const response = await Repository.get(resource);
    return await response.json();
  }
}

export default new SettingsRepository();