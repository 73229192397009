






















































import { EEventType } from "@/models/Event";
import { IMood, MOODS } from "@/models/Mood";
import { AssetHelper } from "@/utils/AssetHelper";
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({})
export default class MoodInput extends Vue {
  @Prop({type: Number, default: null}) value!: number | null
  @Prop({type: String, default: () => EEventType.CLINIC}) eventType!: EEventType
  moods: IMood[] = MOODS
  selected: number = -1
  isMobile: boolean = true

  created(): void {
    this.isMobile = this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm;
    if (this.value) {
      this.selected = this.value;
    } else {
      if (this.isMobile) {
        this.selected = 4;
      }
    }
  }
  getMoodIcon(index: number): NodeRequire {
    index == -1 ? (index = 4) : index;
    return AssetHelper.getMoodIcon(
      index,
      this.eventType,
      this.selected === index && !this.isMobile
    );
  }
  focusMood(index: number): void {
    this.selected = index;
  }
}
