import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"width":"400px"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('div',{staticClass:"yc_top_bar"},[_c('div',{staticClass:"dialogTitle"},[_vm._v(_vm._s(_vm.headline))]),_c(VBtn,{attrs:{"fab":"","small":"","id":"eventform-closebutton"},on:{"click":function($event){return _vm.$emit('close')}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VCard,[_c(VIcon,{staticClass:"yc_dialog_warning_icon pt-5"},[_vm._v("mdi-alert-outline")]),_c(VCardTitle,{staticClass:"confirmationDialogText"},[_vm._v(_vm._s(_vm.description))]),_c(VCardActions,{staticClass:"actionButtons"},[_c(VSpacer),_c(VBtn,{staticClass:"yc_btn_primary",attrs:{"outlined":"","text":""},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(_vm._s(_vm.$t("dialogs.cancel")))]),_c(VBtn,{attrs:{"outlined":"","text":"","data-cy":"confirmdeletebtn"},on:{"click":function($event){return _vm.$emit('callback')}}},[_vm._v(_vm._s(_vm.$t("dialogs.delete")))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }