import Event from "@/models/Event"
import Relation from "@/models/Relation"
import OnboardingHints from "@/models/OnboardingHints"
import User from "@/models/User"
import OnboardingSettings from "@/models/OnboardingSettings"
import PrivacySettings from "@/models/PrivacySettings"
import DailyMood from "@/models/DailyMood"
import ExperienceSettings from "@/models/ExperienceSettings"
// import ChatParticipant from "@/models/ChatParticipant";
import Symptom from "@/models/Symptom"
import SymptomDuration from "@/models/SymptomDuration"
import SymptomDurationPoint from "@/models/SymptomDurationPoint"
import { InfoText } from "@/models/InfoText"
import { Tag } from "@/models/Tag"
import { Notification } from "@/models/Notification"
import TrackedAction from "@/models/Action"
import NotificationSettings from "@/models/NotificationSettings"

export interface RootState {
  user: UserState
  account: AccountState
  event: EventState
  relation: RelationState
  alert: AlertState
  chat: ChatState
  experience: ExperienceState
}

export interface ConnectionState {
  isLoading: Boolean
}

export interface UserState {
  userdata: User
}

export interface OnboardingState {
  onb_chat_finished: boolean
  onb_hints: OnboardingHints
}

export interface ExperienceState {
  trigger_activities: Array<String>
  events: Array<Event>
  trackedActionExpModeScrolled: TrackedAction
  trackedActionExpModeEventOpened: TrackedAction
}

export interface SettingsState {
  onboarding: OnboardingSettings
  privacy: PrivacySettings
  notifications: NotificationSettings
  experience: ExperienceSettings
}

export interface AccountState {
  user: UserShort
  status: any
}

export interface EventState {
  events: Array<Event>
  eventCountTotal: Number
  trackedActionNewEntry: TrackedAction
}

export interface RelationState {
  relations: Array<Relation>
}

export interface AlertState {
  type: string | null
  message: string | null
}

export interface ChatState {
  chats: Array<ICR>
  activeRoomId: string | null
  partner: number | null
  messages: Array<ICM>
  //polling: any | null;
  unreadChats: Array<string>
  //unreadPolling: any | null;
}

export interface ApplicationState {
  online: Boolean
  appSetupRan: boolean
}

export interface UserShort {
  id: number
  email: string
  name: string
}

export interface SymptomState {
  symptoms: Array<Symptom>
  activeSymptomDurations: Array<DurationsWrapper>
  finishedSymptomDurations: Array<FinishedDurationsWrapper>
}

// interface to add symptomdurationpoints to according symptomduration
export interface DurationsWrapper {
  symptomDuration: SymptomDuration
  nextPageNumber: Number
  symptomDurationPoints: Array<SymptomDurationPoint>
}

/*
interface to add symptomdurations to according symptoms. 
symptomdurationpoints can again be added to symptomdurations
*/
export interface FinishedDurationsWrapper {
  symptom: Symptom
  count: number
  nextPageNumber: Number
  finsihedSymptomDurations: Array<DurationsWrapper>
}

// to use when creating new symptomduration, for explicit handling in store in repository
export interface NewSymptomDuration {
  symptom: Number
  symptom_start: String
}

// to use when creating new symptomdurationpoint, for explicit handling in store in repository
export interface NewSymptomDurationPoint {
  symptom_duration: number
  datetime: string
  value: number
}

/*
export interface InitalChat {
  receiver: number;
  message: string;
}


export interface IChat {
  id: string;
  partner: IChatParticipant;
  is_read: boolean | undefined;
  latest_message: ICM | undefined;
}


export interface IChatMessage {
  content: string;
  participantId: number;
  timestamp: string;
  uploaded: boolean;
  viewed: boolean;
}

*/

export interface IChatParticipant {
  _id: number
  username: string
  avatar: string | undefined
  status: undefined
}

export interface MoodState {
  yesterday: undefined | DailyMood
  moods: DailyMood[]
}

export interface ICM {
  //_id: string;
  _id: number
  indexId: number
  content: string
  senderId: number
  username: string
  avatar: string | undefined
  date: string
  timestamp: string
  // needed props to set the functionality of messages
  system: boolean
  saved: boolean
  distributed: boolean
  seen: boolean
  deleted: boolean
  disableActions: boolean
  disableReactions: boolean
}
export interface ChatUser {
  _id: number
  avatar: string
  username: string
}

// interface chat room message
export interface ICRM {
  content: string
  senderId: number
  username: string
  timestamp: string
  saved: boolean
  distributed: boolean
  seen: boolean
  new: boolean
}

// interface chat room
export interface ICR {
  roomId: string // roomId should be unique
  roomName: string
  avatar: string | undefined
  unreadCount: number | string
  index: string
  users: IChatParticipant[]
  lastMessage: ICRM | undefined
  is_blocked: boolean
}

export interface TagState {
  tags: Array<Tag>
}

// export interface InfoTextState {
//   infoTexts: {
//     diagnoses: Array<InfoText>;
//     examinations: Array<InfoText>;
//     psychology: Array<InfoText>;
//   };
// }

export interface InfoTextState {
  infoTexts: Array<InfoText>
}

export interface NotificationState {
  notifications_hash: string
  notifications: Array<Notification>
  displayState: ENotificationDisplayState
}

export enum ENotificationDisplayState {
  DISPLAY,
  BLOCK,
  IDLE
}

export interface OverlayState {
  userProfileOverlayState: {
    isDisplayed: boolean
    userProfile: User | null
  }
  createEventOverlayState: {
    isDisplayed: boolean
  }
  experienceModeState: {
    isDisplayed: boolean
  }
}
