import { Module } from "vuex";
import { AlertState, RootState } from "../types";

const alert: Module<AlertState, RootState> = {
  namespaced: true,
  state: {
    type: null,
    message: null
  },
  actions: {
    success({ commit }, message) {
      commit("success", message);
    },
    error({ commit }, message) {
      commit("error", message);
    },
    clear({ commit, state }, message) {
      if(state.type != null || state.message != null){
        commit("clear", message);
      }
    }
  },
  mutations: {
    success(state, message) {
      state.type = "success";
      state.message = message;
    },
    error(state, message) {
      state.type = "error";
      state.message = message;
    },
    clear(state) {
      state.type = null;
      state.message = null;
    }
  }
};

export default alert;