import Content from "@/models/Content";
import Activity from "@/models/Activity";

interface Event {
  id: number;
  owner: number;
  datetime: string;
  location: string;
  activity: Activity | number;
  icon: string;
  mood: number;
  difficulty: number;
  text: string;
  contents: Content[];
}

export enum EEventType {
  CLINIC = "clinic",
  HOME = "home",
}

export interface ChainableEvent {
  id: number;
  icon: string;
  location: string;
}

export default Event;
