









import {Component} from 'vue-property-decorator'
import VueOnbHint from './VueOnbHint'
import Speechbubble from '@/components/onboarding/Speechbubble.vue';

@Component({
    components: {
        Speechbubble
    }
})
export default class OnbTrackingHints extends VueOnbHint {
    hintOrder: string[] = ["tracking_symptom_search", "tracking_pinned_symptoms", "tracking_finished_symptoms"]
    hintClasses: string[] = ["searchBar", "pinnedSymptoms", "symptomTabs"]
    onboardingSetting: object = {tracking_hints: false}
    cssClass = "yc_onb_hint_circle_tracking"

    mounted() {
        this.disableScrolling(true);
        this.setHighlight();
        this.disableAnchors(true);
        this.changeFixedNav(true);
    }
    beforeDestroy(){
        this.disableAnchors(false);
        this.disableScrolling(false);
        this.removeAllHighlights();
        this.changeFixedNav(false);
    }
}
