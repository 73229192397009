







































import { Component, Watch, Prop } from 'vue-property-decorator'
import VueStepper from './VueStepper'
import Speechbubble from "@/components/onboarding/Speechbubble.vue";
import Companion from "@/components/onboarding/Companion.vue";
import TypingBubble from "@/components/onboarding/onb_chat/TypingBubble.vue";

@Component({
  components: {
    Speechbubble,
    Companion,
    TypingBubble,
  }
})
export default class InputEvent extends VueStepper {
  @Prop({type: String, default:  ''}) avatarPath!: string
  @Prop({type: String, default: ''}) companionText!: string
  stepTimes: number[] = [2000, 2000, 2000, 2000] //typing, explanation event, typing, question event

  @Watch('step')
  onStepChange(): void {
    if (this.step < this.stepTimes.length) this.stepper();
    if (this.step == 4) setTimeout(() => this.scroll(), 100);
  }

  created(): void {
    this.stepper();
  }
}
