













































































































































import { Component, Prop, Vue } from "vue-property-decorator"
import { getNowTimestamp, formatDate, getTimestamp } from "@/utils/utils"
import symptom_duration from "@/models/SymptomDuration"
import SymptomRepository from "@/api/SymptomRepository"
import ConfirmationDialog from "@/components/dialogs/ConfirmationDialog.vue"
import InfoDialog from "@/components/dialogs/InfoDialog.vue"
import { dateInputValidator } from "@/utils/symptom_validator"

@Component({
  components: {
    ConfirmationDialog,
    InfoDialog
  }
})
export default class EditDialog extends Vue {
  @Prop({ type: Boolean, default: false }) showDialog!: boolean
  @Prop({ type: String, required: true }) headline!: string
  @Prop({ type: Object, required: true }) symptom_duration!: symptom_duration
  @Prop({ type: Boolean, default: false }) isStopDialog!: boolean
  @Prop({ type: String, required: true }) confirmButtonText!: string
  isValidForm: boolean = true
  isFloatingPoint: boolean = false
  startDatemenu: boolean = false
  endDatemenu: boolean = false
  showConfirmationDialog: boolean = false
  showNoPointsLeftDialog: boolean = false
  confirmDialogDescription: string = ""
  timeDescription: string = ""
  startTime: string = ""
  startDate: string = ""
  endTime: string = ""
  endDate: string = ""
  initEndTimeStamp: string = ""
  errorMessages: string[] = []

  getUpdatedDuration(): symptom_duration {
    const updated_duration: symptom_duration = {
      id: this.symptom_duration.id,
      symptom: this.symptom_duration.symptom,
      symptom_start: getTimestamp(this.startDate, this.startTime),
      symptom_end: null,
      count: 0, // not necessary for verification
      page_size: null
    }

    if (this.isStopDialog) {
      updated_duration.symptom_end = getTimestamp(this.endDate, this.endTime)
    }

    return updated_duration
  }
  /*  only do verification if edited 'symptom start' is greater than old 'symptom start'
      or edited 'symptom end' is smaller than old 'symptom end' */
  doVerfification(): boolean {
    // remove all non digits from 'datetime'
    // -> latest timestamp is the highest number, due to django timestamp format
    // -> f.e. "2021-01-31T17:00:00Z"
    const oldStartTS = parseInt(this.symptom_duration.symptom_start.replace(/\D/g, ""))
    const newStartTS = parseInt(getTimestamp(this.startDate, this.startTime).replace(/\D/g, ""))
    const startUpdate = newStartTS > oldStartTS

    // do verfication if enddate was lowered
    if (this.isStopDialog) {
      const oldEndTS = parseInt(this.initEndTimeStamp.replace(/\D/g, ""))
      const newEndTS = parseInt(getTimestamp(this.endDate, this.endTime).replace(/\D/g, ""))

      return startUpdate || oldEndTS > newEndTS
    }

    return startUpdate
  }
  // further form validation with start / end time and date
  validateInputs(): boolean {
    let errors: string[] = []
    if (this.isStopDialog) {
      errors = dateInputValidator(
        getTimestamp(this.startDate, this.startTime),
        getTimestamp(this.endDate, this.endTime),
        this.initEndTimeStamp,
        (key: string) => this.$t(key)
      )
    } else {
      if (
        parseInt(getTimestamp(this.startDate, this.startTime).replace(/\D/g, "")) >
        parseInt(getNowTimestamp().replace(/\D/g, ""))
      ) {
        errors.push("" + this.$t("dialogs.no_future_time"))
      }
    }

    if (errors.length > 0) {
      this.errorMessages = errors
      return false
    }

    this.errorMessages = []
    return true
  }
  async editSymptom(): Promise<void> {
    if (this.isValidForm && this.validateInputs()) {
      const updated_duration = this.getUpdatedDuration()

      if (this.doVerfification()) {
        // check if any appended points to this duration would be out of range when updating start and/or end_date
        const response = await SymptomRepository.verifySymptomDurationUpdate(updated_duration)

        if (response.status == 200) {
          const tmp = await response.json()

          // deletes all points
          if (tmp["num_points"] == this.symptom_duration.count) {
            this.showNoPointsLeftDialog = true
            return
          }

          // create texts for confirmdialog
          if (tmp["num_points"] > 1)
            this.confirmDialogDescription =
              tmp["num_points"] + " " + this.$t("dialogs.deletes_points_description").toString()
          else
            this.confirmDialogDescription =
              tmp["num_points"] + " " + this.$t("dialogs.deletes_point_description").toString()

          this.showConfirmationDialog = true
        } else if (response.status == 204) {
          // no points which are out of range found
          this.$emit("callback", updated_duration)
        } else {
          // TODO error symptomduraiton not found
        }
      } else {
        this.$emit("callback", updated_duration)
      }
    }
  }
  updateWithDeletingPoints(): void {
    // TODO reload attached points / refresh
    this.showConfirmationDialog = false
    this.$emit("callback", this.getUpdatedDuration())
  }
  created(): void {
    this.timeDescription = this.$t("dialogs.time") + ": "
    this.startDate = formatDate(this.symptom_duration.symptom_start, false)
    this.startTime = formatDate(this.symptom_duration.symptom_start, true)

    if (this.isStopDialog) {
      this.initEndTimeStamp = getNowTimestamp()
      this.endDate = formatDate(this.initEndTimeStamp, false)
      this.endTime = formatDate(this.initEndTimeStamp, true)
    }
  }

  // TODO: for getter and setter define Interface
  get showEditDialog(): boolean {
    return this.showDialog
  }

  get today(): string {
    const date = new Date(Date.now())
    return `${date.getFullYear()}-${("" + (date.getMonth() + 1)).padStart(2, "0")}-${(
      "" + date.getDate()
    ).padStart(2, "0")}`
  }
}
