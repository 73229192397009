import { IAuthTokens } from "@/utils/jwt_interceptor";

// type of response from login endpoint
export interface IAuthResponse {
  access: string;
  refresh: string;
}

// transform response into IAuthTokens
// this assumes your auth endpoint returns `{"access": ..., "refresh": ...}`
export const authResponseToAuthTokens = (res: IAuthResponse): IAuthTokens => ({
  accessToken: res.access,
  refreshToken: res.refresh
});
