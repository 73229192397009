




















































































































































import { Vue, Component, Prop } from "vue-property-decorator"
import DurationPoint from "@/components/symptom/DurationPoint.vue"
import SymptomDuration from "@/models/SymptomDuration"
import { NewSymptomDurationPoint } from "@/store/types"
import { formatDate, getNowTimestamp, getTimestamp } from "@/utils/utils"
import ConfirmationDialog from "@/components/dialogs/ConfirmationDialog.vue"
import AddPointDialog from "@/components/dialogs/AddPointDialog.vue"
import EditDialog from "@/components/dialogs/EditDialog.vue"
import TrackingRepository from "@/api/TrackingRepository"
import SymptomDurationPoint from "@/models/SymptomDurationPoint"
import SymptomNotification from "@/components/symptom/SymptomNotification.vue"
/*
 @click.native.stop muss an allen HTML Elementen im *-panel-header verwendet werden,
 damit sich beim Click auf diese Elemente nicht das Dropdown öffnet.

This component stores the current pageNumber, to (dis)able the 'Mehr laden' button. If the pageNumber is < 0, then
there are no more pages to load
*/

@Component({
  components: {
    DurationPoint,
    ConfirmationDialog,
    AddPointDialog,
    EditDialog,
    SymptomNotification
  }
})
export default class SymptomTracker extends Vue {
  @Prop({ type: Object, required: true }) symptom_duration!: SymptomDuration
  @Prop({ type: Array, default: () => [] }) points!: SymptomDurationPoint[]
  @Prop({ type: Boolean, default: false }) isDisabled!: boolean
  @Prop({ type: Number, required: true }) nextPageNumber!: number
  showDeleteDialog: boolean = false
  showAddPointDialog: boolean = false
  showEditDialog: boolean = false
  lastPointValue: null = null
  start: string = ""
  end: string = ""
  showDatePicker: boolean = false
  showStopDialog: boolean = false
  dateRange: string[] = []
  minDate: string = ""
  maxDate: string = ""

  async updateTracker(updated_duration: SymptomDuration): Promise<void> {
    this.showEditDialog = false
    updated_duration.count = this.orderedPoints.length

    // check if updated start- to enddate delete any appended durationppoints
    this.start = this.formatTimestamp(updated_duration.symptom_start, true)
    this.dateRange[0] = this.formatTimestamp(updated_duration.symptom_start, false)

    await this.$store.dispatch("updateActiveSymptomDuration", updated_duration)
    const groupID = "TRAC:"
    await TrackingRepository.createLog(
      groupID +
        "Symptom " +
        this.symptom_duration.symptom.name +
        " editiert. Startzeit " +
        updated_duration.symptom_start
    )
  }
  async stopTracker(updated_duration: SymptomDuration): Promise<void> {
    updated_duration.count = this.orderedPoints.length
    await this.$store.dispatch("updateActiveSymptomDuration", updated_duration)

    const groupID = "TRAC:"
    await TrackingRepository.createLog(
      groupID +
        "Symptom " +
        this.symptom_duration.symptom.name +
        " gestoppt, Startzeit " +
        updated_duration.symptom_start +
        ", Endzeit " +
        updated_duration.symptom_end +
        ", " +
        updated_duration.count +
        " Messpunkte"
    )
  }
  // wrapper because vue does not recognize the 'formatDate' import in html
  formatTimestamp(timestamp: string, isTime: boolean): string {
    return formatDate(timestamp, isTime)
  }
  async startSameTracker(): Promise<void> {
    this.$emit("startSameTracker", this.symptom_duration.symptom)
  }
  async deleteTracker(): Promise<void> {
    this.showDeleteDialog = false

    await this.$store.dispatch("deleteSymptomDuration", {
      duration_id: this.symptom_duration.id,
      is_active_duration: !this.isDisabled,
      symptom_id: this.symptom_duration.symptom.id
    })

    const groupID = "TRAC:"
    let prefix = "Aktives"
    if (this.isDisabled) prefix = "Beendetes"
    await TrackingRepository.createLog(
      groupID + prefix + " Symptom " + this.symptom_duration.symptom.name + " gelöscht"
    )
  }
  async loadMoreDurationPoints(): Promise<void> {
    if (this.nextPageNumber > 0) {
      await this.$store.dispatch("loadSymptomDurationPoints", {
        symptom_duration: this.symptom_duration.id,
        nextPageNumber: this.nextPageNumber,
        is_active: !this.isDisabled
      })
    }
  }
  async addSymptomPoint(data: { date: string; time: string; value: Number }): Promise<void> {
    this.showAddPointDialog = false
    const v = String(data.value).replace(",", ".")
    const point: NewSymptomDurationPoint = {
      symptom_duration: this.symptom_duration.id,
      datetime: getTimestamp(data.date, data.time),
      value: Number(v)
    }

    await this.$store.dispatch("addSymptomDurationPoint", {
      new_point: point,
      symptom_id: this.symptom_duration.symptom.id,
      nextPageNumber: this.nextPageNumber
    })

    const groupID = "TRAC:"
    await TrackingRepository.createLog(
      groupID +
        "Messpunkt zu " +
        this.symptom_duration.symptom.name +
        " hinzufügen mit Wert " +
        point.value +
        " am " +
        point.datetime
    )

    // update tracker start time, if created point start time is smaller
    if (
      parseInt(getTimestamp(data.date, data.time).replace(/\D/g, "")) <
      parseInt(this.symptom_duration.symptom_start.replace(/\D/g, ""))
    ) {
      const updatedSymptomDuration: SymptomDuration = {
        id: this.symptom_duration.id,
        symptom: this.symptom_duration.symptom,
        symptom_start: getTimestamp(data.date, data.time),
        symptom_end: null,
        count: 0,
        page_size: null
      }
      this.updateTracker(updatedSymptomDuration)
    }
  }
  getValueAfter(index: number): number {
    if (index + 1 == this.orderedPoints.length) return this.orderedPoints[index].value
    return this.orderedPoints[index + 1].value
  }
  parseDate(date: String): String {
    if (!date) return ""
    const [year, month, day] = date.split("-")
    return `${day}.${month}.${year}`
  }
  disableLoadMore(): boolean {
    if (this.nextPageNumber > 0) return false
    return true
  }
  async trackerAction(): Promise<void> {
    if (this.isDisabled) {
      this.$emit("startSameTracker", this.symptom_duration.symptom)
      const groupID = "TRAC:"
      await TrackingRepository.createLog(
        groupID + "Beendetes Symptom " + this.symptom_duration.symptom.name + " neu gestartet"
      )
    } else {
      this.showStopDialog = true
    }
  }
  calcDays(): number {
    const milliseconds = Math.abs(
      new Date(this.dateRange[0]).getTime() - new Date(this.dateRange[1]).getTime()
    )
    return milliseconds / (60 * 60 * 24 * 1000)
  }
  getNumDays(): string {
    if (this.dateRange.length > 1) {
      const days = this.calcDays() + 1
      return <string>this.$t("duration.days", { num: days })
    }
    return <string>this.$t("duration.day")
  }
  getElapsedTime(): string {
    const startHourMin = this.start.split(":")
    const endHourMin = this.end.split(":")

    if (
      isNaN(parseInt(startHourMin[0])) ||
      isNaN(parseInt(startHourMin[1])) ||
      isNaN(parseInt(endHourMin[0])) ||
      isNaN(parseInt(endHourMin[1]))
    )
      return <string>this.$t("duration.hours", { num: "?" })
    // on multiple days
    if (this.dateRange.length > 1 && this.dateRange[0] !== this.dateRange[1]) {
      const days = this.calcDays()

      return <string>this.$t("duration.days", { num: days })
      /*
      let elapsedHours = 24 - parseInt(startHourMin[0]) + parseInt(endHourMin[0]) + 24 * (days - 1);
      let elapsedMinutes = 60 - parseInt(startHourMin[1]) + parseInt(endHourMin[1]);

      if (elapsedMinutes > 60) {
        elapsedMinutes -= 60;
        elapsedHours += 1;
      }

      return elapsedHours + ":" + elapsedMinutes + " Stunden";
      */
    }

    // on one day
    let elapsedHours = parseInt(endHourMin[0]) - parseInt(startHourMin[0])
    let elapsedMinutes = parseInt(endHourMin[1]) - parseInt(startHourMin[1])

    if (elapsedMinutes < 0) {
      elapsedMinutes = 60 - Math.abs(elapsedMinutes)
      elapsedHours -= 1
    }

    //return elapsedHours + ":" + elapsedMinutes.toString().padStart(2, "0") + " Stunden";
    if (elapsedHours > 0) return <string>this.$t("duration.hours", { num: elapsedHours })
    return <string>this.$t("duration.minutes", { num: elapsedMinutes })
  }
  parseDateObject(date: Date, isTimestamp: Boolean): string {
    if (isTimestamp) {
      return (
        "" +
        date.getHours().toString().padStart(2, "0") +
        ":" +
        date.getMinutes().toString().padStart(2, "0")
      )
    }
    return (
      "" +
      date.getFullYear() +
      "-" +
      (date.getMonth() + 1).toString().padStart(2, "0") +
      "-" +
      date.getDate().toString().padStart(2, "0")
    )
  }

  created(): void {
    const startDate = this.formatTimestamp(this.symptom_duration.symptom_start, false)
    let endDate = this.formatTimestamp(this.symptom_duration.symptom_end as string, false)

    this.start = this.formatTimestamp(this.symptom_duration.symptom_start, true)
    this.dateRange.push(startDate)

    if (endDate === null || endDate === undefined || endDate === "") {
      //this.end = this.parseDateObject(new Date(), true);
      this.end = this.formatTimestamp(getNowTimestamp(), true)
      endDate = this.formatTimestamp(getNowTimestamp(), false)

      // set minimum and maximum date for calendar (+/- 6 month from now)
      const nowDate = new Date()
      nowDate.setMonth(nowDate.getMonth() - 6)
      this.minDate = this.parseDateObject(nowDate, false)
      nowDate.setMonth(nowDate.getMonth() + 12)
      this.maxDate = this.parseDateObject(nowDate, false)
    } else {
      this.end = this.formatTimestamp(this.symptom_duration.symptom_end as string, true)
    }

    this.dateRange.push(endDate)
  }

  get getLastSymptomPoint(): SymptomDurationPoint | null {
    if (this.orderedPoints != null && this.orderedPoints.length > 0) return this.orderedPoints[0]
    return null
  }

  get getHeadline(): string {
    if (this.orderedPoints.length > 0) return <string>this.$t("tracker.headline.last_entry", {
        points: this.orderedPoints[0].value,
        unit: this.symptom_duration.symptom.unit?.name || "",
        date: this.parseDate(formatDate(this.orderedPoints[0].datetime, false)),
        time: formatDate(this.orderedPoints[0].datetime, true)
      })
    return <string>this.$t("tracker.headline.no_entries")
  }
  get isLastPoint() {
    return this.orderedPoints.length === 1
  }
  get getLastPointValue() {
    if (this.orderedPoints != null && this.orderedPoints.length > 0)
      return this.orderedPoints[0].value
    return null
  }
  get orderedPoints(): SymptomDurationPoint[] {
    return this.points.sort(
      (a, b) => new Date(b.datetime).getTime() - new Date(a.datetime).getTime()
    )
  }
}
