import { Module } from "vuex";
import { RootState, TagState } from "../types";
import i18n from "@/locales/i18n";
import TagRepository from "@/api/TagRepository";
import { Tag } from "@/models/Tag";

const SET_TAGS_MUTATION = "SET_TAGS";

export const tagModule: Module<TagState, RootState> = {
  state: {
    tags: [] as Array<Tag>,
  },
  getters: {
    tags: (state) => (): Array<Tag> => {
      return state.tags;
    },
  },
  mutations: {
    [SET_TAGS_MUTATION](state, tags: Array<Tag>) {
      state.tags = tags;
    },
  },
  actions: {
    async loadTags({ commit, dispatch }) {
      try {
        const tags = await TagRepository.get();
        commit(SET_TAGS_MUTATION, tags);
      } catch (error) {
        this.dispatch("alert/error", i18n.t("error_messages.moods"));
        /*eslint-disable no-console */
        console.error("error while fetching moods: " + error);
      }
    },
  },
};

export default tagModule;
