import Repository from "@/api/Repository";
import { Tag } from "@/models/Tag";

const resource = "/v1/tags/";

interface TagDto {
  tags: Array<Tag>
}

class TagRepository {
  async get(): Promise<TagDto> {
    const response = await Repository.get(resource);
    return await response.json();
  }
}

export default new TagRepository();
