









import {Component} from 'vue-property-decorator'
import VueOnbHint from './VueOnbHint'
import Speechbubble from '@/components/onboarding/Speechbubble.vue';

@Component({
    components: {
        Speechbubble
    }
})
export default class OnbProfileHints extends VueOnbHint {
    hintOrder: string[] = ["profile_overview", "profile_status", "profile_settings"]
    hintClasses: string[] = ["", "profileLocationButtons", "profileSettingsButton"]
    onboardingSetting: object = {profile_hints: false}
    cssClass: string = "yc_onb_hint_circle_profile"

    mounted() {
        this.disableScrolling(true);
        this.setHighlight();
        this.disableAnchors(true);
        this.changeFixedNav(true);
    }
    beforeDestroy(){
        this.disableAnchors(false);
        this.disableScrolling(false);
        this.removeAllHighlights();
        this.changeFixedNav(false);
    }
}
