import { VTextField } from "vuetify/lib"

var formatter = new Intl.NumberFormat("de-DE", {
  style: "decimal",
  // minimumIntegerDigits: 2,
  maximumFractionDigits: 2
})

export default {
  extends: VTextField,
  data: () => ({
    inputValue: null
  }),
  props: {
    blurredFormat: {
      type: Function,
      default: (v) => {
        if (v) {
          // replace "," with "." for formatting to work
          v = String(v).replace(",", ".")
          return formatter.format(parseFloat(v))
        }
      }
    }
  },
  methods: {
    showValue() {
      if (!this.isFocused) {
        this.inputValue = this.lazyValue
        this.lazyValue = this.blurredFormat(this.lazyValue)
      } else {
        this.lazyValue = this.inputValue
      }
    }
  },
  watch: {
    isFocused() {
      this.showValue()
    }
  },
  mounted() {
    this.showValue()
  }
}
