import {
  ChartConfiguration,
  ChartTypeRegistry,
  ScriptableLineSegmentContext,
  TooltipItem,
} from "chart.js";

import "chartjs-adapter-date-fns";
import { de } from "date-fns/locale";
import { daysAgo, numDays, today } from "@/utils/dateUtils";

interface ITick{
  value: number;
  label: string;
}
const skipped = (ctx: ScriptableLineSegmentContext, value: any) => {
  return numDays(ctx.p0.parsed.x, ctx.p1.parsed.x) > 3 ? value : undefined;
};

export default class DailyMoodChart {
  labels: Array<Date>;
  data: Array<number>;
  timeSpanDays: number;

  tickLabels: Array<ITick> = [
    { value: 1, label: "sehr schlecht" },
    { value: 2, label: "schlecht" },
    { value: 3, label: "eher schlecht" },
    { value: 4, label: "eher gut" },
    { value: 5, label: "gut" },
    { value: 6, label: "sehr gut" },
  ];

  constructor(labels: Array<Date>, data: Array<number>, timeSpanDays: number) {
    this.labels = labels;
    this.data = data;
    this.timeSpanDays = timeSpanDays;
  }

  generateChart(): ChartConfiguration<
    keyof ChartTypeRegistry,
    Array<number>,
    Date
  > {
    const getTick = (value: any) => {
      return this.tickLabels.find((tick) => tick.value === value)?.label;
    };

    return {
      type: "line",
      data: {
        labels: this.labels,
        datasets: [
          {
            label: "Stimmungsverlauf",
            data: this.data,
            backgroundColor: "#1B96A2",
            borderColor: "#1B96A2",
            borderWidth: 3,
            cubicInterpolationMode: "monotone",
            tension: 0.4,
            pointRadius: 2,
            pointHoverRadius: 10,
            segment: {
              borderColor: (ctx) => skipped(ctx, "rgb(0,0,0,0.2)"),
              borderDash: (ctx) => skipped(ctx, [6, 6]),
            },
          },
        ],
      },
      options: {
        layout: {
          padding: 40,
        },
        plugins: {
          tooltip: {
            callbacks: {
              label: (tooltipItem: TooltipItem<any>) => {
                let label = tooltipItem.parsed.y || "";
                return " " + getTick(label);
              },
            },
          },
          legend: {
            display: false,
          },
        },
        scales: {
          x: {
            type: "time",
            min: daysAgo(this.timeSpanDays),
            max: today(),

            time: {
              minUnit: "day",
              stepSize: 1,
              displayFormats: {
                day: "dd MMM",
                month: "MMM yy",
              },
            },
            adapters: {
              date: {
                locale: de,
              },
            },
            ticks: {
              maxRotation: 45,
              minRotation: 45,
              padding: 10,
            },
          },
          y: {
            beginAtZero: true,
            min: 1,
            max: 6,
            ticks: {
              padding: 10,
              stepSize: 1,
              callback: (val) => {
                return getTick(val);
              },
            },
          },
        },
      },
    };
  }
}
