/* eslint-disable no-console */

import { register } from 'register-service-worker';
import i18n from '@/locales/i18n';
import store from '@/store/index'

async function load_i18n(registration: ServiceWorkerRegistration) {
  const sw = registration.active || registration.installing || registration.waiting;
  if (!sw) {
    throw "missing service worker!";
  }
  console.log('sending i18n data to service worker')
  // Dirty hack to load translation data to active service worker
  sw.postMessage({'i18n': i18n.messages});
}

function setOnlineStatus(event:any) {
  if (event.data && event.data.type) {
    if (event.data.type === 'OFFLINE_STATUS') {
      store.dispatch("application/setOnlineStatus", false);
    } else if (event.data.type === 'ONLINE_STATUS'){
      store.dispatch("application/setOnlineStatus", true);
    }
  }
}

if (process.env.NODE_ENV === 'production') {
    register(`${process.env.BASE_URL}service-worker.js`, {
      ready (registration) {
        console.log(
          'App is being served from cache by a service worker.\n' +
          'For more details, visit https://goo.gl/AFskqB'
        )
        load_i18n(registration);
      },
      registered () {
        console.log('Service worker has been registered.');
      },
      cached () {
        console.log('Content has been cached for offline use.')
      },
      updatefound () {
        console.log('New content is downloading.')
      },
      updated () {
        console.log('New content is available; please refresh.')
      },
      offline () {
        console.log('No internet connection found. App is running in offline mode.')
      },
      error (error) {
        console.error('Error during service worker registration:', error)
      },
      registrationOptions: {
        updateViaCache: 'imports' // https://developers.google.com/web/updates/2019/09/fresher-sw
      }
    })

    // navigator.serviceWorker.onmessage = (event) => {
    //   setOnlineStatus(event)
    // };
}