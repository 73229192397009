import { Notification, NotificationDTO } from "@/models/Notification";
import Repository from "@/api/Repository";
const resource = "/v1/notifications";

class NotificationRepository {
  async getNotifications(notifications_hash: string): Promise<NotificationDTO | null> {
    const params = {
      'notification_hash': notifications_hash,
    };
    // const response = await Repository.get(`${resource}?notifications_hash=${notifications_hash}`);
    const response = await Repository.get(resource, params);
    if(response.status == 200)
      return await response.json();
    return null
  }

  /*testing
  async getMockedNotifications(): Promise<Array<NotificationDTO>>{
    const response = await MockedRepository.get(resource);
    return response.json();
  }
  */

  async deleteNotification(notification: Notification): Promise<boolean> {
    const params = {
      'pk': notification.id,
    };
    const response = await Repository.delete(resource, params);
    return response.status === 204;
  }
}

export default new NotificationRepository();
