














































import { Component, Prop, Watch, Vue } from "vue-property-decorator"
import MoodSelection from "@/components/utils/MoodSelection.vue"
import DailyMood from "@/models/DailyMood"
import TrackingRepository from "@/api/TrackingRepository"
import LottieAnimation from "lottie-web-vue"

@Component({
  components: {
    MoodSelection,
    LottieAnimation
  }
})
export default class MoodInputDialog extends Vue {
  @Prop({ type: Number }) value: number | undefined
  @Prop({ type: String }) dialogTitle: string | undefined
  @Prop({ type: String }) dialogSubtitle: string | undefined
  show: boolean = false
  selectedMood: number = 0
  showAnimation: boolean = false

  async mounted() {
    //load all moods
    await this.$store.dispatch("loadMoods")
    //check current route to see if moodInputDialog is supposed to be shown
    const isYesterdayMoodEntered = !!this.$store.getters.yesterdaysMood
    const routeName = this.$route.name
    this.show = routeName ? this.maybeShowDialog(isYesterdayMoodEntered, routeName) : false
  }
  destroyed() {
    this.showAnimation = false
  }

  async storeMoodSelected(selection: number) {
    this.selectedMood = selection
  }
  async moodSelected() {
    if (this.selectedMood != 0) {
      await this.$store.dispatch("setDailyMood", this.selectedMood)
      this.showAnimation = true
    }
  }
  destoryAnimation() {
    this.show = false
  }
  async skipMoodSelection() {
    // -1 is the code for making an empty selection
    await this.$store.dispatch("setDailyMood", -1)
    this.show = false
  }
  async logDailyMood() {
    let groupID = "HOME:"
    await TrackingRepository.createLog(
      groupID + "Tagesstimmung für gestern eintragen im Homescreen"
    )
  }
  maybeShowDialog(isYesterdayMoodEntered: boolean, routeName: string) {
    //moodInputDialog is shown on Home if Mood is not yet entered
    //moodInputDialog is shown on Info, Tracking, Chat and Profile if Mood is not yet entered
    //and current time is after 6pm
    const hourOfDayThreshold = 18
    const isRouteRelevant = ["home", "chatList", "information", "tracking", "profile"].some(
      (existingRoute) => routeName == existingRoute
    )
    const isRouteHome = routeName == "home"
    const isAfterDailyThreshold = new Date().getHours() >= hourOfDayThreshold
    if (isYesterdayMoodEntered || !isRouteRelevant) {
      return false
    } else {
      if (isRouteHome) {
        return true
      } else {
        return isAfterDailyThreshold ? true : false
      }
    }
  }

  @Watch("$route")
  onRouteChange(to: any) {
    const isYesterdayMoodEntered = !!this.$store.state.mood.yesterday
    this.show = this.maybeShowDialog(isYesterdayMoodEntered, to.name)
  }

  get moodToday(): DailyMood | undefined {
    const yesterday = this.$store.state.mood.yesterday
    return yesterday ? yesterday.mood : undefined
  }
  get moodInputTitle(): string {
    return <string>this.$t("daily_mood.daily_ title")
  }
  get moodInputSubtitle(): string {
    const date = new Date()
    date.setDate(date.getDate() - 1)
    return this.$t("daily_mood.question", {
      name: this.$store.getters.getUser.name,
      weekday: this.$t("weekdays." + date.getDay()),
      date: date.getDate() + "." + (date.getMonth() + 1)
    }).toString()
  }
}
