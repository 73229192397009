





















































































































































































































































































import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class AchievementIcon extends Vue {
    @Prop({type: Number, required: true}) level!: number
    @Prop({type: String, required: true}) icon!: string
    @Prop({type: Number}) goalNumber: number | undefined
    @Prop({type: String}) goalName: string | undefined

    get color(): string {
        switch (this.level) {
            case 4:
                return '#E08745';
            case 5:
                return '#A4A2AC';
            case 6:
                return '#FFC05F';
            default:
                return '#1b7d8a';
          }
    }
}
