// NOTE: Code is from https://www.npmjs.com/package/axios-jwt
/* eslint-disable no-console */
import * as jwt from "jsonwebtoken"

// a little time before expiration to try refresh (seconds)
const EXPIRE_FUDGE = 10

type Token = string
export interface IAuthTokens {
  accessToken: Token
  refreshToken: Token
}

// EXPORTS
export const isLoggedIn = (): boolean => {
  return <boolean>(!!getRefreshToken() && !isRefreshTokenExpired())
}

export const setAuthTokens = (tokens: IAuthTokens) =>
  localStorage.setItem(getTokenStorageKey(), JSON.stringify(tokens))

export const clearAuthTokens = () => localStorage.removeItem(getTokenStorageKey())

// PRIVATE
const getTokenStorageKey = (): string => `auth-tokens-${process.env.NODE_ENV}`
const getAuthTokens = (): IAuthTokens | undefined => {
  const tokensRaw = localStorage.getItem(getTokenStorageKey())
  if (!tokensRaw) return

  try {
    // parse stored tokens JSON
    return JSON.parse(tokensRaw)
  } catch (err) {
    console.error("Failed to parse auth tokens: ", tokensRaw, err)
  }
  return
}

export const isRefreshTokenExpired = (): boolean => {
  const tokensRaw = localStorage.getItem(getTokenStorageKey())

  if (!tokensRaw) return true

  try {
    // parse stored tokens JSON
    const tokens = JSON.parse(tokensRaw)
    const expired = isTokenExpired(tokens["refreshToken"])

    if (expired) return true
    else return false
  } catch (err) {
    /*eslint-disable no-console */
    console.error("Failed to parse auth tokens: ", tokensRaw, err)
  }
  return true
}

export const getRefreshToken = (): Token | undefined => {
  const tokens = getAuthTokens()
  return tokens ? tokens.refreshToken : undefined
}
export const getAccessToken = (): Token | undefined => {
  const tokens = getAuthTokens()
  return tokens ? tokens.accessToken : undefined
}
const isTokenExpired = (token: Token): boolean => {
  if (!token) return true
  const expin = getExpiresInFromJWT(token) - EXPIRE_FUDGE
  return !expin || expin < 0
}

// gets unix TS
const getTokenExpiresTimeStamp = (token: Token): number | undefined => {
  const decoded = jwt.decode(token)
  if (!decoded) return
  return (decoded as { [key: string]: number }).exp
}

const getExpiresInFromJWT = (token: Token): number => {
  const exp = getTokenExpiresTimeStamp(token)
  if (exp) return exp - Date.now() / 1000

  return -1
}

const refreshToken = async (requestRefresh: TokenRefreshRequest): Promise<Token> => {
  const refreshToken = getRefreshToken()
  if (!refreshToken) return Promise.reject("No refresh token available")

  try {
    // do refresh with default axios client (we don't want our interceptor applied for refresh)
    const res = await requestRefresh(refreshToken)
    // save tokens
    setAuthTokens(res)
    return res.accessToken
  } catch (err: any) {
    // failed to refresh... check error type
    if (err && err.response && (err.response.status === 401 || err.response.status === 422)) {
      // got invalid token response for sure, remove saved tokens because they're invalid
      localStorage.removeItem(getTokenStorageKey())
      return Promise.reject(`Got 401 on token refresh; Resetting auth token: ${err}`)
    } else {
      // some other error, probably network error
      return Promise.reject(`Failed to refresh auth token: ${err}`)
    }
  }
}

export type TokenRefreshRequest = (refreshToken: string) => Promise<IAuthTokens>
export interface IAuthTokenInterceptorConfig {
  header?: string
  headerPrefix?: string
  requestRefresh: TokenRefreshRequest
}

export const authTokenInterceptor = async (
  request: any,
  {
    header = "Authorization",
    headerPrefix = "Bearer ",
    requestRefresh
  }: IAuthTokenInterceptorConfig
) => {
  // we need refresh token to do any authenticated requests
  if (!getRefreshToken()) return request

  // do refresh if needed
  const accessToken = await getAuthToken({ header, headerPrefix, requestRefresh })

  // add token to headers
  if (accessToken) request.headers[header] = accessToken
  return request
}

export const getAuthToken = async ({
  headerPrefix = "Bearer ",
  requestRefresh
}: IAuthTokenInterceptorConfig) => {
  // we need refresh token to do any authenticated requests
  if (!getRefreshToken()) return Promise.reject("no token")

  // do refresh if needed
  let accessToken
  try {
    accessToken = await refreshTokenIfNeeded(requestRefresh)
  } catch (err) {
    return Promise.reject(err)
  }

  // add token to headers
  return `${headerPrefix}${accessToken}`
}

export const refreshTokenIfNeeded = async (
  requestRefresh: TokenRefreshRequest
): Promise<Token | undefined> => {
  // use access token (if we have it)
  let accessToken = getAccessToken()
  // check if access token is expired
  if (!accessToken || isTokenExpired(accessToken)) {
    // do refresh
    accessToken = await refreshToken(requestRefresh)
  }

  return accessToken
}
