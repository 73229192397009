import { BrowserDetectMeta } from "vue-browser-detect-plugin";

type MailtoType = "simple" | "bug_mail";

// %0D%0A can be used instead of normal line break to trigger a line break in the mailTo protocol
const lineBreaks = (count: number) => {
  return "%0D%0A".repeat(count);
};

const PROTOCOL = "mailto:";
const SUPPORT_MAIL_ADRESS = "support@youcan-app.de";
const SUBJECTS = {
  simple: "Ich benötige eure Unterstützung",
  bug_mail: "Bitte behebt meinen Fehler",
};
const QUERYPARAMS = {
  SUBJECT: "subject=",
  BODY: "body=",
};

//simple mail
const ENTRY_TEXT_SIMPLE = "Hallo, liebes YouCan!Team. Ich benötige eure Unterstützung bei folgendem Problem: " + lineBreaks(5);

//advanced mail
const ENTRY_TEXT =
  "Hallo liebes YouCan!-Team." +
  lineBreaks(2) +
  "Ich habe einen Fehler gefunden und würde euch gerne darüber in Kenntnis setzen." +
  lineBreaks(3);
const END_TEXT = "Viele Grüße" + lineBreaks(1);
const DIVIDER = lineBreaks(1) + "____________" + lineBreaks(1);

const MAILCONTENTS = {
  WHICH_FEATURE: "Welches Feature hast du genutzt? " + lineBreaks(5),
  STEPS_TO_REPRODUCE: "Was genau hast du getan?" + lineBreaks(5),
  REPRODUCIBLE_BUG:
    "Tritt der Fehler wiederholt auf oder ist er bislang nur einmal aufgetreten?" +
    lineBreaks(5),
  UPLOAD_IMAGE:
    "Bitte lade, falls möglich, einen Screenshot des Fehlers hoch." +
    lineBreaks(5),
  BROWSERVERSION: "Browser-Version: ",
};

export default class MailTo {
  browserMetaData: BrowserDetectMeta;
  userName: string;
  mailToType: MailtoType;
  constructor(
    browserMetaData: BrowserDetectMeta,
    userName: string,
    mailToType: MailtoType
  ) {
    this.browserMetaData = browserMetaData;
    this.userName = userName;
    this.mailToType = mailToType;
  }

  private buildMailSubject(mailToType: MailtoType) {
    return SUBJECTS[mailToType]
  }

  private buildMailBody(mailToType: MailtoType) {
    switch (mailToType) {
      case "simple":
        return ENTRY_TEXT_SIMPLE;
      case "bug_mail":
        return (
          ENTRY_TEXT +
          MAILCONTENTS.WHICH_FEATURE +
          MAILCONTENTS.STEPS_TO_REPRODUCE +
          MAILCONTENTS.REPRODUCIBLE_BUG +
          MAILCONTENTS.UPLOAD_IMAGE +
          END_TEXT +
          this.userName +
          DIVIDER +
          MAILCONTENTS.BROWSERVERSION +
          this.browserMetaData.ua
        );
    }
  }

  public toString(): string {
    return (
      PROTOCOL +
      SUPPORT_MAIL_ADRESS +
      "?" +
      QUERYPARAMS.SUBJECT +
      this.buildMailSubject(this.mailToType) +
      "&" +
      QUERYPARAMS.BODY +
      this.buildMailBody(this.mailToType)
    );
  }
}
