/**
 * Parses an parameter object to an URLSearchParams Object.
 *
 * null and undefined values will be filtered
 */
export function parseParams(parameters: any): URLSearchParams {
  return Object.keys(parameters)
    .filter((key) => parameters[key])
    .reduce((params, key) => {
      const value = parameters[key]
      if (Array.isArray(value)) {
        value.forEach((v) => params.append(key, v))
      } else {
        params.append(key, value)
      }
      return params
    }, new URLSearchParams())
}

export function trimArrayExceeding(array: any[], limit: number): any[] {
  const trimmed = array.slice(0, limit)
  if (array.length > limit) {
    trimmed.push("...")
  }
  return trimmed
}

// returns the time of django timestamp, if it's on the same day or a date otherwise
export function getLastMessageTimestamp(timestamp: string): string {
  const today = new Date()
  const date_time = timestamp.split("T")
  const date = date_time[0].split("-")

  // compare if same day, month, year
  if (
    parseInt(date[0]) === today.getFullYear() &&
    parseInt(date[1]) === today.getMonth() + 1 &&
    parseInt(date[2]) === today.getDay() + 1
  ) {
    const timeparts = date_time[1].split(":")
    return "" + timeparts[0] + ":" + timeparts[1]
  }

  return "" + date[2] + "." + date[1] + "." + date[0]
}

// return the time of django timesstamp if 'isTime' is true or the date otherwise
export function formatDate(date: string, isTime: boolean): string {
  if (date === null || date === undefined || date === "") return ""

  const dateParts = date.replace("Z", "").split("T")
  if (isTime) {
    // keep only hours:minutes
    const timeParts = dateParts[1].split(":")
    return timeParts[0] + ":" + timeParts[1]
  }
  return dateParts[0]
}

export function getTimestamp(date: string, time: string): string {
  if (
    date === undefined ||
    date === null ||
    date === "" ||
    time === undefined ||
    time === null ||
    time === ""
  )
    return ""
  return date + "T" + time + ":" + "00Z"
}

export function getJSDate(datetime: string): Date {
  // format: '2021-11-12T14:00:00.0Z'

  if (datetime === "" || datetime === null || datetime === undefined) {
    return new Date()
  }

  const _datetime = datetime.split("T")
  const _date = _datetime[0].split("-")
  const _time = _datetime[1].replace("Z", "").split(":")

  return new Date(
    parseInt(_date[0]),
    parseInt(_date[2]),
    parseInt(_date[2]),
    parseInt(_time[0]),
    parseInt(_time[1]),
    parseInt(_time[2]),
    0
  )
}

// return datetime string in format 'YYYY-MM-DDTHH:MM:SSZ' - django format
export function getNowTimestamp(): string {
  const now = new Date()

  return (
    "" +
    now.getFullYear() +
    "-" +
    (now.getMonth() + 1).toString().padStart(2, "0") +
    "-" +
    now.getDate().toString().padStart(2, "0") +
    "T" +
    now.getHours().toString().padStart(2, "0") +
    ":" +
    now.getMinutes().toString().padStart(2, "0") +
    ":" +
    now.getSeconds().toString().padStart(2, "0") +
    "Z"
  )
}

export function getDateDiffDays(givenDate: Date): number {
  const now = new Date()
  return Math.floor((now.getTime() - givenDate.getTime()) / (1000 * 60 * 60 * 24))
}
