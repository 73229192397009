import { Module } from "vuex"
import { InfoTextState, RootState, TagState } from "../types"
import i18n from "@/locales/i18n"
import { InfoText, InfoTextGroup } from "@/models/InfoText"
import InfoTextRepository from "@/api/InfoTextRepository"
import ChapterRepository from "@/api/ChapterRepository"

const SET_INFO_TEXTS_MUTATION = "SET_INFO_TEXTS"
const SET_INFO_TEXT_CHAPTERS_MUTATION = "SET_INFO_TEXT_CHAPTERS"

export const infoTextModule: Module<InfoTextState, RootState> = {
  state: {
    infoTexts: new Array()
  },
  getters: {
    infoTexts: (state) => (): Array<InfoText> => {
      return state.infoTexts
    }
  },
  mutations: {
    [SET_INFO_TEXTS_MUTATION](state, infoTexts: Array<InfoText>) {
      state.infoTexts = infoTexts
    },
    [SET_INFO_TEXT_CHAPTERS_MUTATION](state, { infoTextId, chapters }) {
      const infoText = state.infoTexts.find((infoText) => (infoText.id = infoTextId))
      infoText ? (infoText.chapters = chapters) : undefined
    }
  },
  actions: {
    async loadInfoTexts({ commit }) {
      try {
        const infoTexts: Array<InfoText> = new Array()
        const infoTextGroups = await InfoTextRepository.get()
        infoTextGroups.forEach((infoTextGroup) => infoTexts.push(...infoTextGroup.info_texts))
        infoTexts.map((infoText: InfoText) => {
          InfoTextRepository.setInfoTextImage(infoText)
        })
        commit(SET_INFO_TEXTS_MUTATION, infoTexts)
      } catch (error) {
        this.dispatch("alert/error", i18n.t("error_messages.moods"))
        /*eslint-disable no-console */
        console.error("error while fetching moods: " + error)
      }
    },
    async loadInfoTextChapters({ commit, dispatch }, infoTextId: number) {
      try {
        const chapters = await ChapterRepository.getInfoTextChapters(infoTextId)
        commit(SET_INFO_TEXT_CHAPTERS_MUTATION, { infoTextId, chapters })
      } catch (error) {
        this.dispatch("alert/error", i18n.t("error_messages.moods"))
        /*eslint-disable no-console */
        console.error("error while fetching moods: " + error)
      }
    }
  }
}

export default infoTextModule
