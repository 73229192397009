











import { Component, Prop, Vue, Watch } from "vue-property-decorator"

interface IDataDayRange {
  value: number
  text: string
}

@Component({})
export default class TimeSpanSelection extends Vue {
  @Prop({ type: Array, required: true }) ranges!: IDataDayRange[]
  @Prop({ type: Number, required: true }) value!: number
  selected: number = -1

  created() {
    this.selected = this.value
  }

  @Watch("selected")
  onSelectedChange(newVal: number) {
    this.$emit("input", newVal)
  }
}
