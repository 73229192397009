import { Module } from "vuex"
import SymptomRepository from "@/api/SymptomRepository"
import {
  UserShort,
  SymptomState,
  DurationsWrapper,
  FinishedDurationsWrapper,
  RootState,
  NewSymptomDuration,
  NewSymptomDurationPoint
} from "../types"
import Symptom from "@/models/Symptom"
import SymptomDuration from "@/models/SymptomDuration"
import SymptomDurationPoint from "@/models/SymptomDurationPoint"
import SymptomUnit from "@/models/SymptomUnit"
import i18n from "@/locales/i18n"

const POINT_PAGE_SIZE = 5

const sessionUser = localStorage.getItem("user")
const user: UserShort = sessionUser && JSON.parse(sessionUser)

const initialState = {
  symptoms: [] as Symptom[],
  activeSymptomDurations: [] as DurationsWrapper[],
  finishedSymptomDurations: [] as FinishedDurationsWrapper[]
}

export const symptomModule: Module<SymptomState, RootState> = {
  state: initialState,
  mutations: {
    // ########################################################
    // ################### symptom methods ##################
    // ########################################################

    setSymptoms(state, symptoms: Array<Symptom>) {
      Object.assign(state.symptoms, symptoms)
    },

    setFinishedSymptoms(state, finishedSymptoms: Array<FinishedDurationsWrapper>) {
      Object.assign(state.finishedSymptomDurations, finishedSymptoms)
    },

    // ########################################################
    // ############### symptomdurantion methods ###############
    // ########################################################

    setActiveSymptomDurations(state, durations: Array<DurationsWrapper>) {
      state.activeSymptomDurations = durations
      //Object.assign(state.activeSymptomDurations, durations);
    },

    addActiveSymptomDuration(state, durations: DurationsWrapper) {
      // newest is always in the beginning of array
      state.activeSymptomDurations.push(durations)
    },

    // only field that can change is 'symptom_start'
    updateActiveSymptomDuration(state, duration: SymptomDuration) {
      state.activeSymptomDurations.forEach(function (element, i) {
        if (element.symptomDuration.id === duration.id) {
          element.symptomDuration.symptom_start = duration.symptom_start
          //return true
        }
      })
    },

    /* 
    check if finsihedSymptomWrapper with same symptom of 'duration' exist -> append 
    if not create wrapper then append 

    */
    addFinishedSymptomDuration(state, duration: SymptomDuration) {
      /* points are not yet loaded, so pageNumber is 1. If an active symtom with no points is stopped,
         pageNumber should be -1
      */
      //let nextPageNumber = 1;

      const durationsWrapper = mapSymptomDurationWrapper(duration)
      let newGroup = true
      state.finishedSymptomDurations.forEach((element) => {
        if (element.symptom.id === durationsWrapper.symptomDuration.symptom.id) {
          element.count = element.count + 1
          element.finsihedSymptomDurations.unshift(durationsWrapper)
          newGroup = false
          //return true
        }
      })

      if (newGroup) {
        // if it is a new group, pageNumber is -1

        const symptomWrapper = {
          symptom: {
            id: duration.symptom.id,
            name: duration.symptom.name,
            unit: <SymptomUnit>{
              ...state.symptoms.find((x) => x.id === duration.symptom.id)!.unit
            }
          },
          count: duration.count
        }

        const finishedWrapper = mapFinishedSymptomWrapper(symptomWrapper, 1)
        if (!durationsWrapper.symptomDuration.symptom.unit) {
          durationsWrapper.symptomDuration.symptom.unit = symptomWrapper.symptom.unit
        }
        finishedWrapper.finsihedSymptomDurations.push(durationsWrapper)
        state.finishedSymptomDurations.push(finishedWrapper)
      }
      state.finishedSymptomDurations = [...state.finishedSymptomDurations]
    },

    removeActiveSymptomDuration(state, duration_id: number) {
      let index = -1
      state.activeSymptomDurations.forEach(function (element, i) {
        if (element.symptomDuration.id === duration_id) {
          index = i
          //return true
        }
      })

      if (index > -1) {
        state.activeSymptomDurations.splice(index, 1)
      } else {
        // TODO throw error?
      }
    },

    removeSymptomDuration(state, data: { duration_id: number; symptom_id: number }) {
      let index = -1
      state.finishedSymptomDurations.forEach(function (element) {
        if (element.symptom.id === data.symptom_id) {
          element.finsihedSymptomDurations.forEach(function (fsd, i) {
            if (fsd.symptomDuration.id === data.duration_id) {
              index = i
              //return true
            }
          })

          if (index > -1) {
            element.finsihedSymptomDurations.splice(index, 1)
            element.count = element.count - 1
            index = -1
          }
        }
      })
      state.finishedSymptomDurations = [...state.finishedSymptomDurations]
    },

    addFinsihedSymptomDurations(
      state,
      data: {
        durations: Array<DurationsWrapper>
        symptom_id: Number
        pageNumber: Number
      }
    ) {
      state.finishedSymptomDurations.forEach((element) => {
        if (element.symptom.id === data.symptom_id) {
          // update nextPageNumber and add new symptomDurations

          element.nextPageNumber = data.pageNumber
          data.durations.forEach((d) => {
            element.finsihedSymptomDurations.push(d)
          })
        }
      })
      state.finishedSymptomDurations = [...state.finishedSymptomDurations]

      // TODO was passiert wenn symptom 'nachgetragen' wird -> reihenfolge
      // der list muss neu geordnet werden, weil neues Element zwischen anderen liegt.
    },

    // ########################################################
    // ############ symptomdurantionpoints methods ############
    // ########################################################

    insertActiveSymptomDurationPoints(
      state,
      data: { point: SymptomDurationPoint; position: number }
    ) {
      state.activeSymptomDurations.forEach((element) => {
        if (element.symptomDuration.id === data.point.symptom_duration) {
          element.symptomDuration.count += 1
          //element.nextPageNumber = data.pageNumber;
          if (data.position >= 0) element.symptomDurationPoints.splice(data.position, 0, data.point)
          else element.symptomDurationPoints.push(data.point)
        }
      })
    },

    addActiveSymptomDurationPoints(
      state,
      data: { points: Array<SymptomDurationPoint>; pageNumber: Number }
    ) {
      const symptom_duration = data.points[0].symptom_duration
      state.activeSymptomDurations.forEach((element) => {
        if (element.symptomDuration.id === symptom_duration) {
          //element.symptomDuration.count += 1
          element.nextPageNumber = data.pageNumber
          data.points.forEach((point) => element.symptomDurationPoints.push(point))
          //return true
        }
      })
    },

    // overwrites all points to corresponding symptom_duration
    setActiveSymptomDurationPoints(
      state,
      data: {
        points: Array<SymptomDurationPoint>
        pointCount: number
        pageNumber: Number
      }
    ) {
      const symptom_duration = data.points[0].symptom_duration
      state.activeSymptomDurations.forEach((element) => {
        if (element.symptomDuration.id === symptom_duration) {
          element.nextPageNumber = data.pageNumber
          element.symptomDuration.count = data.pointCount
          element.symptomDurationPoints = []
          data.points.forEach((point) => element.symptomDurationPoints.push(point))
        }
      })
    },

    addActiveSymptomDurationPoint(state, point: SymptomDurationPoint) {
      state.activeSymptomDurations.forEach((element) => {
        if (element.symptomDuration.id === point.symptom_duration) {
          // newest is always in the beginning of array
          element.symptomDuration.count += 1
          element.symptomDurationPoints.unshift(point)
          //return true
        }
      })
    },

    addFinishedSymptomDurationPoints(
      state,
      data: { points: Array<SymptomDurationPoint>; pageNumber: Number }
    ) {
      const symptom_duration = data.points[0].symptom_duration
      state.finishedSymptomDurations.forEach((wrapper) => {
        wrapper.finsihedSymptomDurations.forEach((sd) => {
          if (sd.symptomDuration.id === symptom_duration) {
            sd.nextPageNumber = data.pageNumber
            //sd.symptomDuration.count += 1
            data.points.forEach((point) => sd.symptomDurationPoints.push(point))
            //return true
          }
        })
      })
      state.finishedSymptomDurations = [...state.finishedSymptomDurations]
    },

    // overwrites all points to corresponding symptom_duration
    setFinishedSymptomDurationPoints(
      state,
      data: {
        points: Array<SymptomDurationPoint>
        pointCount: number
        pageNumber: Number
      }
    ) {
      const symptom_duration = data.points[0].symptom_duration
      state.finishedSymptomDurations.forEach((wrapper) => {
        wrapper.finsihedSymptomDurations.forEach((sd) => {
          if (sd.symptomDuration.id === symptom_duration) {
            sd.nextPageNumber = data.pageNumber
            sd.symptomDuration.count = data.pointCount
            sd.symptomDurationPoints = []
            data.points.forEach((point) => sd.symptomDurationPoints.push(point))
          }
        })
      })
      state.finishedSymptomDurations = [...state.finishedSymptomDurations]
    },

    addFinishedSymptomDurationPoint(state, point: SymptomDurationPoint) {
      state.finishedSymptomDurations.forEach((wrapper) => {
        wrapper.finsihedSymptomDurations.forEach((sd) => {
          if (sd.symptomDuration.id === point.symptom_duration) {
            // newest is always in the beginning of array
            sd.symptomDuration.count += 1
            sd.symptomDurationPoints.unshift(point)
            //return true
          }
        })
      })
      state.finishedSymptomDurations = [...state.finishedSymptomDurations]
    },

    removeActiveSymptomDurationPoint(state, point: { symptom_duration: number; id: number }) {
      let sd_index = -1
      let sdp_index = -1

      state.activeSymptomDurations.forEach(function (sd, i) {
        if (sd.symptomDuration.id === point.symptom_duration) {
          sd.symptomDurationPoints.forEach(function (obj, j) {
            if (obj.id === point.id) {
              sd_index = i
              sdp_index = j
              //return true
            }
          })
        }
      })

      if (sdp_index > -1) {
        state.activeSymptomDurations[sd_index].symptomDuration.count -= 1
        state.activeSymptomDurations[sd_index].symptomDurationPoints.splice(sdp_index, 1)
      } else {
        // TODO throw error?
      }
    },

    removeSymptomDurationPoint(state, point: { symptom_duration: number; id: number }) {
      let fsd_index = -1
      let sd_index = -1
      let sdp_index = -1
      state.finishedSymptomDurations.forEach(function (wrapper, i) {
        wrapper.finsihedSymptomDurations.forEach(function (sd, j) {
          if (sd.symptomDuration.id === point.symptom_duration) {
            sd.symptomDurationPoints.forEach(function (obj, k) {
              if (obj.id === point.id) {
                fsd_index = i
                sd_index = j
                sdp_index = k
                //return true
              }
            })
          }
        })
      })

      if (sdp_index > -1) {
        state.finishedSymptomDurations[fsd_index].finsihedSymptomDurations[
          sd_index
        ].symptomDuration.count -= 1
        state.finishedSymptomDurations[fsd_index].finsihedSymptomDurations[
          sd_index
        ].symptomDurationPoints.splice(sdp_index, 1)
        state.finishedSymptomDurations = [...state.finishedSymptomDurations]
        // TODO state.finishedSymptomDurations[fsd_index].count -= 1
      } else {
        // TODO throw error?
      }
    },

    updateActiveSymptomDurationPoint(
      state,
      data: { symptom_duration: number; point: SymptomDurationPoint }
    ) {
      state.activeSymptomDurations.forEach(function (sd, i) {
        if (sd.symptomDuration.id === data.symptom_duration) {
          sd.symptomDurationPoints.forEach(function (obj, j) {
            if (obj.id === data.point.id) {
              obj.datetime = data.point.datetime
              obj.value = data.point.value
              //return true
            }
          })
        }
      })
    },

    updateSymptomDurationPoint(
      state,
      data: { symptom_duration: number; point: SymptomDurationPoint }
    ) {
      state.finishedSymptomDurations.forEach(function (wrapper, i) {
        wrapper.finsihedSymptomDurations.forEach(function (sd, j) {
          if (sd.symptomDuration.id === data.symptom_duration) {
            sd.symptomDurationPoints.forEach(function (obj, k) {
              if (obj.id === data.point.id) {
                obj.datetime = data.point.datetime
                obj.value = data.point.value
                //return true
              }
            })
          }
        })
      })
      state.finishedSymptomDurations = [...state.finishedSymptomDurations]
    }
  },
  actions: {
    async loadSymptoms({ commit, dispatch }) {
      try {
        const response = await SymptomRepository.getSymptoms()
        const mappedSymptoms = response.map((symptom: Symptom) => mapSymptom(symptom))
        mappedSymptoms.sort(function (a: Symptom, b: Symptom): number {
          if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
          if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
          return 0
        })
        commit("setSymptoms", mappedSymptoms)
      } catch (error) {
        // TODO
        //this.dispatch('alert/error', i18n.t('error_messages.moods'));

        /*eslint-disable no-console */
        console.error("error while fetching symptoms: " + error)
      }
    },
    async loadFinishedSymptoms({ commit, dispatch }) {
      /*
        this method is used to load the groups (symptoms) of all finished symptomdurations. 
        If the user clicks on one symptom in the group the (paginated) symptomdurations of this symptom are loaded. 
        */
      try {
        const response = await SymptomRepository.getFinishedSymptoms()

        // pageNumber is in this case always one, because there is no empty 'symptom group'
        const mappedSymptoms = response.map((symptom: any) => mapFinishedSymptomWrapper(symptom, 1))
        commit("setFinishedSymptoms", mappedSymptoms)
      } catch (error) {
        // TODO
        //this.dispatch('alert/error', i18n.t('error_messages.moods'));

        /*eslint-disable no-console */
        console.error("error while fetching symptoms: " + error)
      }
    },
    async loadActiveSymptomDurations({ commit, dispatch }) {
      try {
        const response = await SymptomRepository.getActiveSymptomDurations()

        const mappedSymptomDurations: Array<DurationsWrapper> = []

        response.forEach((sd: SymptomDuration) => {
          //let nextPageNumber = -1;
          // use Non-null assertion operator ! to express that this field will not be null
          //if (sd.page_size! < sd.count!) nextPageNumber = 2;
          mappedSymptomDurations.push(mapSymptomDurationWrapper(sd))
        })
        mappedSymptomDurations.map((as: DurationsWrapper) =>
          dispatch("loadSymptomDurationPoints", {
            symptom_duration: as.symptomDuration.id,
            is_active: true
          })
        )
        commit("setActiveSymptomDurations", mappedSymptomDurations)
      } catch (error) {
        // TODO
        //this.dispatch('alert/error', i18n.t('error_messages.moods'));

        /*eslint-disable no-console */
        console.error("error while fetching active symptomdurations: " + error)
      }
    },
    async loadFinishedSymptomDurations(
      { commit, dispatch },
      data: { symptom: number; pageNumber: number }
    ) {
      try {
        const response = await SymptomRepository.getFinishedSymptomDurations(
          data.symptom,
          data.pageNumber
        )

        // TODO update pagenumber for parent
        const pageNubmer = extractPageNumber(response.next)
        const mappedSymptomDurations = response.results.map((sd: SymptomDuration) =>
          mapSymptomDurationWrapper(sd)
        )
        commit("addFinsihedSymptomDurations", {
          durations: mappedSymptomDurations,
          symptom_id: data.symptom,
          pageNumber: pageNubmer
        })
      } catch (error) {
        // TODO
        //this.dispatch('alert/error', i18n.t('error_messages.moods'));

        /*eslint-disable no-console */
        console.error("error while fetching symptomdurations: " + error)
      }
    },
    // load first 'page' (first 5) symptomduration points for all tracked symptoms
    // -> set state.symptomDurationPoints = response
    async loadSymptomDurationPoints(
      { commit, dispatch },
      data = {
        symptom_duration: Number,
        nextPageNumber: Number,
        is_active: Boolean
      }
    ) {
      try {
        const response = await SymptomRepository.getSymptomDurationPoints(
          data.symptom_duration,
          data.nextPageNumber
        )
        //const mssg = JSON.stringify(response, null, 2);
        const pageNumber = extractPageNumber(response.next)
        if (response.results.length > 0) {
          const mappedPoints = response.results.map((sdp: SymptomDurationPoint) =>
            mapSymptomDurationPoint(sdp)
          )

          if (data.is_active) {
            commit("addActiveSymptomDurationPoints", {
              points: mappedPoints,
              pageNumber: pageNumber
            })
          } else {
            commit("addFinishedSymptomDurationPoints", {
              points: mappedPoints,
              pageNumber: pageNumber
            })
          }
        }
      } catch (error) {
        /*eslint-disable no-console */
        console.error("error while fetching symptomdurationpoints: " + error)
      }
    },

    async updateActiveSymptomDuration(
      { state, commit, dispatch },
      symptom_duration: SymptomDuration
    ) {
      try {
        const response = await SymptomRepository.updateSymptomDuration(symptom_duration)

        // TODO check in EDItDialog if points were delete. give param to this function (pointsDeleted)
        // reload point until last one?

        // symptomduration was updated or stopped
        if (symptom_duration.symptom_end == null) {
          commit("updateActiveSymptomDuration", response)
        } else {
          const symptonDurationPoints = state.activeSymptomDurations.find(
            (x) => x.symptomDuration.id === response.id
          )
          commit("removeActiveSymptomDuration", response.id)

          let foundExistingGroup = false
          // if no elements in symptom group are loaded, load them first, before adding stopped duration
          state.finishedSymptomDurations.forEach(function (fsd) {
            if (
              fsd.symptom.id === symptom_duration.symptom.id &&
              fsd.finsihedSymptomDurations.length === 0
            ) {
              dispatch("loadFinishedSymptomDurations", {
                symptom: symptom_duration.symptom,
                pageNumber: 1
              })
              foundExistingGroup = true
            }
          })

          if (!foundExistingGroup) {
            commit("addFinishedSymptomDuration", response)
            commit("addFinishedSymptomDurationPoints", {
              points: symptonDurationPoints!.symptomDurationPoints,
              pageNumber: symptonDurationPoints!.nextPageNumber
            })
          }
        }
      } catch (error) {
        /*eslint-disable no-console */
        console.error("error while updating active symptomduration: " + error)
      }
    },

    async addActiveSymptomDuration({ commit, dispatch }, symptom_duration: NewSymptomDuration) {
      try {
        // return id of created symptomDuration
        const response = await SymptomRepository.addActiveSymptomDurations(symptom_duration)
        const mappedWrapper = mapSymptomDurationWrapper(response)
        commit("addActiveSymptomDuration", mappedWrapper)

        return new Promise((resolve, reject) => {
          try {
            resolve(response)
          } catch (error) {
            /*eslint-disable no-console */
            console.error("error while return promise for newly created symptom " + error)
            reject(error)
          }
        })
      } catch (error) {
        /*eslint-disable no-console */
        console.error("error while creating new symptomduration: " + error)
      }
    },

    async deleteSymptomDuration(
      { commit, dispatch },
      data: {
        duration_id: number
        is_active_duration: boolean
        symptom_id: number
      }
    ) {
      try {
        //("data of delete: "+JSON.stringify(data, null, 2))
        const responseCode = await SymptomRepository.deleteSymptomDurations(data.duration_id)
        if (responseCode === 204) {
          if (data.is_active_duration) commit("removeActiveSymptomDuration", data.duration_id)
          else
            commit("removeSymptomDuration", {
              duration_id: data.duration_id,
              symptom_id: data.symptom_id
            })
        }
      } catch (error) {
        /*eslint-disable no-console */
        console.error("error while deleting active symptomduration: " + error)
      }
    },

    //
    async addSymptomDurationPoint(
      { commit, getters },
      data: {
        new_point: NewSymptomDurationPoint
        symptom_id: number
        nextPageNumber: number
      }
    ) {
      try {
        const response = await SymptomRepository.addSymptomDurationPoint(data.new_point)
        const point = mapSymptomDurationPoint(response)

        // check if new point is the 'latest' in the list (add in front of list)
        // otherwise reload list until the point
        //const firstPoint = getters.getFirstPoint(point.symptom_duration)

        const position = getters.filterTimpestampDurationPoints(point)

        // if there is a next page -> reload the list until the new point
        if (data.nextPageNumber > 0) {
          const responsePointsUntil = await SymptomRepository.getSymptomDurationPointsUntil({
            symptom_id: data.symptom_id,
            duration_id: point.symptom_duration,
            point_id: point.id,
            exclude_point: false
          })
          const pageNumber = extractPageNumber(responsePointsUntil.next)
          const mappedPoints = responsePointsUntil.results.map((sdp: SymptomDurationPoint) =>
            mapSymptomDurationPoint(sdp)
          )
          const payload = {
            points: mappedPoints,
            pointCount: responsePointsUntil.count,
            pageNumber: pageNumber
          }

          // points can only be added to active durations
          commit("setActiveSymptomDurationPoints", payload)
        }
        // if there is no next page, then new point is on last position
        else {
          commit("insertActiveSymptomDurationPoints", {
            point: point,
            position: position
          })
        }
        //commit("addActiveSymptomDurationPoint", point);
      } catch (error) {
        /*eslint-disable no-console */
        console.error("error while creating new symptomduration: " + error)
      }
    },

    async deleteSymptomDurationPoint(
      { commit, dispatch },
      data: {
        point: SymptomDurationPoint
        symptom_id: number
        isActive: boolean
        nextPageNumber: number
      }
    ) {
      try {
        // only reload pages until deleted point, if there is a next page
        // otherwise just delete the point from the list
        let payload = {}
        if (data.nextPageNumber > 0) {
          const response = await SymptomRepository.getSymptomDurationPointsUntil({
            symptom_id: data.symptom_id,
            duration_id: data.point.symptom_duration,
            point_id: data.point.id,
            exclude_point: true
          })
          const pageNumber = extractPageNumber(response.next)
          const mappedPoints = response.results.map((sdp: SymptomDurationPoint) =>
            mapSymptomDurationPoint(sdp)
          )
          payload = {
            points: mappedPoints,
            pointCount: response.count,
            pageNumber: pageNumber
          }
        }

        const responseCode = await SymptomRepository.deleteSymptomDurationPoint(data.point.id)
        if (responseCode === 204) {
          // active or finished duration
          if (data.isActive) {
            if (data.nextPageNumber > 0) commit("setActiveSymptomDurationPoints", payload)
            else
              commit("removeActiveSymptomDurationPoint", {
                symptom_duration: data.point.symptom_duration,
                id: data.point.id
              })
          } else {
            if (data.nextPageNumber > 0) commit("setSymptomDurationPoints", payload)
            else
              commit("removeSymptomDurationPoint", {
                symptom_duration: data.point.symptom_duration,
                id: data.point.id
              })
          }
        } else {
          // TODO error message
        }
      } catch (error) {
        /*eslint-disable no-console */
        console.error("error while deleting symptomdurationpoint: " + error)
      }
    },

    async updateSymptomDurationPoint({ commit, dispatch }, data: any) {
      try {
        const response = await SymptomRepository.updateSymptomDurationPoint(data.point)

        if (data.isActive) {
          commit("updateActiveSymptomDurationPoint", {
            symptom_duration: data.point.symptom_duration,
            point: mapSymptomDurationPoint(response)
          })
        } else {
          commit("updateSymptomDurationPoint", {
            symptom_duration: data.point.symptom_duration,
            point: mapSymptomDurationPoint(response)
          })
        }
      } catch (error) {
        /*eslint-disable no-console */
        console.error("error while updating symptomdurationpoint: " + error)
      }
    }

    // load first more symptomduration, according to given page number
    /* -> set state.symptomDurationPoints append response 
        async loadMoreSymptomDurations({commit, dispatch}, symptom_duration : number, page : number) {
            try {
                const response = await SymptomRepository.getSymptomDurations(symptom_duration, page); 

            } catch(error) {
                /*eslint-disable no-console 
                console.error("error while fetching symptomdurationpoints: " + error);
            }
        }
        */
  },
  getters: {
    // returns the postion of of the given point in the appended point of the corresponding
    // duration object. if not found returns -1
    filterTimpestampDurationPoints:
      (state) =>
      (point: SymptomDurationPoint): Number => {
        // remove all non digits from 'datetime'
        // -> latest timestamp is the highest number, due to django timestamp format
        // -> f.e. "2021-01-31T17:00:00Z"
        const searchTS = parseInt(point.datetime.replace(/\D/g, ""))
        let index = -1

        // point can be only in active durations
        // use 'some' to interrupt the loop
        state.activeSymptomDurations.some((element) => {
          if (element.symptomDuration.id === point.symptom_duration) {
            index = element.symptomDurationPoints.findIndex(
              (p) => parseInt(p.datetime.replace(/\D/g, "")) <= searchTS
            )
            return true
          }
        })

        return index
      },

    // returns the first durationpoint in the list of a given duration (id)
    /* is active defines if it is an active duration or finished
    getFirstPoint: (state) => (duration_id: number, isActive: boolean): SymptomDurationPoint | null => {
      if (isActive) {
        state.activeSymptomDurations.forEach((element) => {
          if (element.symptomDuration.id === duration_id) {
            // TODO cannot return element in for each? 
            return element.symptomDurationPoints[0];
          }
        });
      } else {
        state.finishedSymptomDurations.forEach((fsd) => {
          fsd.finsihedSymptomDurations.forEach((d) => {
            if (d.symptomDuration.id === duration_id) {
              return d.symptomDurationPoints[0];
            }
          });
        });
      }

      return null;
    },*/
    getSymptoms: (state) => (): Array<Symptom> => {
      return state.symptoms
    },
    getActiveDurations: (state) => (): Array<DurationsWrapper> => {
      return state.activeSymptomDurations
    },
    activeDurations(state): Array<DurationsWrapper> {
      return state.activeSymptomDurations
    },
    getFinishedDurations:
      (state) =>
      (symptom: number): Array<FinishedDurationsWrapper> => {
        state.finishedSymptomDurations.forEach((element) => {
          if (element.symptom.id === symptom) {
            return element.finsihedSymptomDurations
          }
        })
        return []
      }
  }
}

// return -1 if there is no next page
function extractPageNumber(url: URL | null) {
  if (url === null || url === undefined) return -1

  return parseInt(url.toString().split("=")[1])
}

function mapSymptomDurationWrapper(symptomduration: any): DurationsWrapper {
  let nextPageNumber = 1
  // this case is onl for 'temperature' and 'weight' when user is new and there are no points yet
  if (symptomduration.count == 0) nextPageNumber = -1
  ///if (symptomduration.count == null || symptomduration.count == undefined || symptomduration.page_size == null) nextPageNumber = -1;
  //else if (symptomduration.page_size < symptomduration.count) nextPageNumber = 2;

  // if a symptomDuration is mapped, pageNumber is always 1
  // business logic prevents
  return {
    symptomDuration: mapSymptomDuration(symptomduration),
    nextPageNumber: nextPageNumber,
    symptomDurationPoints: []
  }
}

function mapFinishedSymptomWrapper(wrapper: any, nextPageNumber: Number): FinishedDurationsWrapper {
  // let nextPageNumber = 1;
  // if abfrage hat nichts mit aktueller seitenzahl zu tun !!!
  // if (wrapper.page_size < wrapper.count) nextPageNumber = 2;

  return {
    symptom: mapSymptom(wrapper.symptom),
    count: wrapper.count,
    nextPageNumber: nextPageNumber,
    finsihedSymptomDurations: []
  }
}

function mapSymptom(symptom: any): Symptom {
  return {
    id: symptom.id,
    name: "" + i18n.t("symptom." + symptom.name + ".title"),
    description: "" + i18n.t("symptom." + symptom.name + ".description"),
    is_searchable: symptom.is_searchable,
    unit: mapSymptomUnit(symptom.unit)
  }
}

function mapSymptomUnit(symptom_unit: any): SymptomUnit | null {
  if (symptom_unit === undefined || symptom_unit === "" || symptom_unit === null) return null

  return {
    id: symptom_unit.id,
    name: symptom_unit.name,
    description: symptom_unit.description
  }
}

function mapSymptomDuration(symptomduration: any): SymptomDuration {
  return {
    id: symptomduration.id,
    symptom: mapSymptom(symptomduration.symptom),
    symptom_start: symptomduration.symptom_start,
    symptom_end: symptomduration.symptom_end,
    count: symptomduration.count,
    page_size: symptomduration.page_size
  }
}

function mapSymptomDurationPoint(point: any): SymptomDurationPoint {
  return {
    id: point.id,
    symptom_duration: point.symptom_duration,
    datetime: point.datetime,
    value: point.value
  }
}

export default symptomModule
