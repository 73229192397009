import Repository from "@/api/Repository";
import { BugReport, BugReportBody } from "@/models/BugReport";
import { BrowserDetectMeta } from "vue-browser-detect-plugin";
const resource = "/v1/customerservice/report-bug";

class BugReportRepository {
  async submitBugReport(
    bugReportBody: BugReportBody,
    browsermeta: BrowserDetectMeta,
    screenshot: File | null
  ): Promise<void> {
    const body: BugReport = {
      bug_report_body: bugReportBody,
      browsermeta: browsermeta.ua,
    };

    let data = new FormData();
    data.append("body", JSON.stringify(body));
    if (screenshot) {
      const arrayBuffer = await screenshot.arrayBuffer();
      const blob = new Blob([new Uint8Array(arrayBuffer)], {
          type: screenshot.type,
        });
        data.append("image", blob);
      }
    const args: RequestInit = {
      method: "post",
      body: data,
    };
    const response = await Repository.post(
      resource,
      data,
      {},
      args
    );
    return await response.json();
  }
}

export default new BugReportRepository();
