































import { Component, Prop, Vue } from "vue-property-decorator"
import Category from "@/models/Category"

@Component({})
export default class CategoryInput extends Vue {
  @Prop({ type: Number }) oldCat: number | undefined
  @Prop({ type: Array, required: true }) categories!: Category[]
  @Prop({ type: String }) eventtype: string | undefined
}
