


















































import {Component, Vue, Prop, Watch} from 'vue-property-decorator'
import ExperienceBubble from '@/components/experience/ExperienceBubble.vue';
import HintDistanceSlider from '@/components/experience/HintDistanceSlider.vue';
import HintTypeChooser from '@/components/experience/HintTypeChooser.vue';
import TrackingRepository from '@/api/TrackingRepository';

@Component({
    components: {
        ExperienceBubble,
        HintDistanceSlider,
        HintTypeChooser
    }
})
export default class ExperienceWelcome extends Vue {
    @Prop({type: Number, required: true}) eventSize!: number
    private showPosQuestion: boolean = true
    private showDistQuestion: boolean = true
    private remember: boolean = false
    private hint_dist: number = 0
    private hint_type: string = ''

    get getExperienceSettings(): IExperienceSettings {
        return this.$store.getters.getExperienceSettings
    }
    get step(): number {
        // show initial hint
        if(this.getExperienceSettings.show_initial_hint)
            return 0;
        // ask for start from beginning or at last position
        else if(this.getExperienceSettings.last_pos >= this.eventSize && this.showPosQuestion)
            return 1;
        // ask for hint distance
        else if(this.getExperienceSettings.ask_preferences && this.showDistQuestion)
            return 2;
        // if no important hint but start hint is supposed to be shown -> show this
        else if(this.getExperienceSettings.show_start_hint && this.showPosQuestion && this.showDistQuestion)
            return 3;
        // close component
        else 
            return -1;
    }

    @Watch('step')
    onStepChange() {
        if (this.step < 0)
            this.$emit('closeStartHint')
    }

    updateExpSetting(type: string): void {
        let newSett = {}
        switch(type){
            case 'initial':
                newSett = {show_initial_hint: false};
                break;
            case 'start':
                newSett = {show_start_hint: false};
                break;
        }
        this.$store.dispatch('updateExperienceSettings', newSett);
    }
    setPreferences(): void {
        let newSett = {min_hint_distance: this.hint_dist,  ask_preferences: !this.remember, hint_type: this.hint_type};
        if(this.remember){
            this.$store.dispatch('updateExperienceSettings', newSett);
        }else{
            this.$store.dispatch('setExperiencePreferences', newSett);
        }
    }
    async logResume(resume: Boolean): Promise<void> {
        let groupID = "EXPE:"
        let suffix = "neu anfangen"
        if(resume)
            suffix = "fortsetzen"

        await TrackingRepository.createLog(groupID+"Erleben Modus "+suffix)
    }
    mounted(){
        this.hint_type = this.$store.getters.getExperienceHintType;
    }
}

interface IExperienceSettings {
    show_initial_hint: boolean
    show_start_hint: boolean
    last_pos: number
    min_hint_distance: number
    ask_preferences: boolean
    hint_type: 'all' | 'activities' | 'negative' | 'positive'
}
