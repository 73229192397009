





















import { Vue, Component, Prop } from 'vue-property-decorator'
import ImageStack from "@/components/ImageStack.vue";
import Event from '@/models/Event'

/*
  TODO: initialize theme on app wide level
*/
import { Theme, ThemeLoader, ThemeName } from "@/components/themes/theme";
const theme: Theme = ThemeLoader.getTheme(ThemeName.vector);

@Component({
    components: {
      ImageStack
    }
})
export default class HomeBack extends Vue {
  @Prop({type: Number, required: true}) fillup!: number
  @Prop({type: Boolean, default: false}) experienceMode!: boolean
  @Prop({type: Number, default: -1}) highlightEvent!: number

  get getEvents(): Event[] {
    return this.$store.getters.getEvents
  }
  get eventCountTotal(): number {
    return this.$store.getters.eventCountTotal
  }
  get getExperienceEvents(): Event[] {
    return this.$store.getters.getExperienceEvents
  }
  get loadingInProgress(): boolean {
    return this.$store.getters.loadingInProgress
  }
  get wrapperWidth(): string {
    const events = this.experienceMode ? this.getExperienceEvents : this.getEvents
    return `${(events.length + this.fillup) * 31}vh`
  }

  openEvent(event_id: number): void{
    this.$emit('openEvent', event_id);
  }

  eventIndex(index: number): number {
    // Index of array in regards to ALL events of user
    return this.eventCountTotal - index - 1;
  }

  fillupIndex(index: number, totalFillup: number): number {
    // calculate fake eventIndex to fill space up
    // rightmost number must result in an ending part for seamless transition
    let fillupParts = Math.ceil(totalFillup / theme.getPartsPerLayer()) * theme.getPartsPerLayer();
    return fillupParts - index; // the first (from right) is a multiple of <parts>
  }
  // method to calculate correct fillup at the end of mountain line in experience mode
  fillupIndexBack(index: number): number {
    return this.getExperienceEvents.length + index-1;
  }
  // method to calculate correct fillup in the beginning of mountain line in experience mode
  fillupIndexFront(index: number, totalFillup: number): number {
    let maxIndex = (totalFillup * theme.getPartsPerLayer()) -1;
    return maxIndex-(totalFillup-index);
  }
}
