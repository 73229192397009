import { Module } from "vuex"
import Chat from "@/models/Chat"
import {
  ChatState,
  RootState,
  IChatParticipant,
  // IChatMessage,
  //IChat,
  ICM,
  ICR,
  ICRM
  //ChatUser
} from "../types"
import ChatRepository from "@/api/ChatRepository"
import UserRepository from "@/api/UserRepository"
// import MediaRepository from "@/api/MediaRepository";
import ChatMessage from "@/models/ChatMessage"
import ChatParticipant from "@/models/ChatParticipant"
import i18n from "@/locales/i18n"
import { getLastMessageTimestamp } from "@/utils/utils"

const initialState = {
  chats: [],
  activeRoomId: null,
  partner: -1,
  messages: [],
  unreadChats: []
}

var counter = 1

const chat: Module<ChatState, RootState> = {
  namespaced: true,
  state: initialState,
  mutations: {
    setChats(state, chats: Array<ICR>) {
      const curChats = state.chats.map((x) => x.roomId)
      const newChats = chats.map((x) => x.roomId)
      // Only set chats if chat rooms have been added or removed
      if (
        curChats.length != newChats.length ||
        curChats.some((value, index) => value != newChats[index])
      ) {
        state.chats = chats
      }
    },
    addChatToChats(state, chat: ICR) {
      const index = state.chats.findIndex((_chat: ICR) => _chat.roomId === chat.roomId)
      if (index !== -1) {
        state.chats[index] = chat
      }
    },
    addChat(state, chat: ICR) {
      state.chats.push(chat)
    },
    emptyMessages(state) {
      state.messages = []
    },
    updateChat(state, payload: ICR) {
      const index = state.chats.findIndex((x) => x.roomId === payload.roomId)
      if (index > -1) {
        state.chats[index] = { ...state.chats[index], ...payload }
        state.chats = [...state.chats]
      }
    },
    setActiveChat(state, chatRoomId: string) {
      state.activeRoomId = chatRoomId
      /*
      const activeChat = state.chats.find((chatRoom: ICR) => chatRoom.roomId === ''+chatRoomId);
      if(activeChat){
        const index = state.chats.indexOf(activeChat);
        
        // TODO implement is_read option
        //state.chats[index].is_read = true;
      }
      */
    },
    setPartner(state, partner: number) {
      state.partner = partner
    },
    setMessages(state, messages: Array<ICM>) {
      state.messages = messages
    },
    addMessage(state, message: ICM) {
      state.messages.push(message)
    },
    updateMessage(state, payload: { message: ICM; index: number }) {
      Object.assign(state.messages[payload.index], payload.message)
    },
    setUnreadChats(state, chats: string[]) {
      state.unreadChats = chats
    }
  },
  actions: {
    async fetch({ dispatch, commit, state, rootState }) {
      try {
        const chats = await ChatRepository.get()

        const mappedChatsPromises = chats.results.map(
          async (chat: Chat, index: number) => await mapApiChat(chat, rootState.account.user.id)
        )
        const mappedChats = await Promise.all(mappedChatsPromises)

        commit("setChats", mappedChats)
        //commit("setActiveChat", mappedChats[0].roomId);
      } catch (error) {
        dispatch("alert/error", i18n.t("error_messages.chatlist"))
        /*eslint-disable no-console */
        console.error("error while loading chats: " + error)
      }
    },

    leave({ dispatch, commit, state }) {
      // deactivate chat
      commit("setActiveChat", null)
      // clear chat room
      commit("setChats", [])
      // clear messages
      commit("setMessages", [])
    },

    /*
    async setPartner({ commit, dispatch }, partnerId: number) {
      try{
        const user = await UserRepository.getUser(partnerId);
        const anonymousAvatar = require("@/assets/avatars/anonymous.png")
        const partner: IChatParticipant = {
          _id: user.id,
          username: user.name,
          avatar: user.avatar || anonymousAvatar,
          status: undefined
        };
        commit("setPartner", partner);
      }catch(error){
        dispatch('alert/error', i18n.t('error_messages.start_chat'));
        /*eslint-disable no-console 
        console.error('error while setting chat partner: '+error);
      }
    },
    */

    /*
    new({ dispatch, commit, state, rootState }, partner: IChatParticipant) {
      // creates a new chat that is not persited yet
      const newChat: ICR = {
        roomId: "-1",
        roomName: "4",
        avatar: "assets/imgs/snow.png",
        index: "0", // TODO ??? 
        users: [
          {
              _id: 13,
              username: "Testuser",
              avatar: "assets/imgs/doe.png",
              status: undefined
          },
          {
              _id: 4,
              username: "hans",
              avatar: "assets/imgs/snow.png",
              status: undefined
          },
        ],
      };

      /* TODO get myself
      {
        roomId: "-1", 
        roomName: string; 
        avatar: string;  
        index: string; 
        users: ChatUser[];
      }
      
      commit("addChat", newChat);
      dispatch("activate", newChat.roomId);
    },
    */

    async activate({ dispatch, commit, state, rootState }, roomObj: any) {
      commit("setActiveChat", roomObj.room.roomId)

      // empty messages, because switching to other room, displays messages of old room for a second
      commit("emptyMessages")

      const partner = roomObj.room.users.find((obj: any, index: any) => {
        return obj._id !== rootState.account.user.id
      })

      commit("setPartner", partner._id)

      const activeChat = state.chats.find(
        (chatRoom: ICR) => chatRoom.roomId === "" + roomObj.room.roomId
      )
      if (activeChat != undefined && roomObj.room.roomId !== "-1") {
        try {
          const response = await ChatRepository.markChatRead(roomObj.room.roomId)

          const chat = await mapApiChat(response, rootState.account.user.id)
          commit("addChatToChats", chat)
        } catch (error) {
          /* eslint-disable no-console */
          console.error("Chat with id " + roomObj.room.roomId + " could not be marked as read.")
        }
      }
    },

    async create({ dispatch, commit, state, rootState }, chatPartnerId: number) {
      try {
        const createdChat = await ChatRepository.createChat(chatPartnerId)
        /*const chat = await mapApiChat(createdChat, rootState.account.user.id);
        commit("updateChat", {
          chat: chat,
          index: state.chats.length - 1
        });
        */

        //dispatch("activate", createdChat.id);
        //dispatch("fetchMessages");
      } catch (error) {
        dispatch("alert/error", i18n.t("error_messages.start_chat"))
        /*eslint-disable no-console */
        console.error("error while creating chat: " + error)
      }
    },

    async sendMessage({ dispatch, commit, state }, message: any) {
      if (state.activeRoomId == null) {
        dispatch("alert/error", i18n.t("error_messages.chat"))
        return
      }
      // add messsage to state, update uploaded status when api call was succesfull
      // commit("addMessage", message);

      // save index to update later
      const index = state.messages.length - 1
      try {
        const sentMessage = await ChatRepository.sendMessage(state.activeRoomId, message.content)
        /*
        commit("updateMessage", {
          message: mapApiMessage(sentMessage),
          index: index
        });
        */
        commit("addMessage", {
          message: mapApiMessage(sentMessage)
        })
      } catch (error) {
        dispatch("alert/error", i18n.t("error_messages.send_message"))
        /*eslint-disable no-console */
        console.error("Error while sending message: " + error)
      }
    },

    async fetchMessages({ dispatch, commit, state }) {
      // fetches messages from server
      if (state.activeRoomId == null) {
        // dispatch("alert/error", i18n.t("error_messages.chat"));
        return
      }
      try {
        const messages = await fetchAllMessages(state.activeRoomId)

        // TODO is this performant? all messages in a chat get polled and mapped all the time??
        commit(
          "setMessages",
          messages.map((message: ChatMessage) => mapApiMessage(message)).reverse()
        )
        /*
        if (messages.length != state.messages.length) {
          commit(
            "setMessages",
            messages
              .map((message: ChatMessage) => mapApiMessage(message))
              .reverse()
          );
          */
      } catch (error) {
        dispatch("alert/error", i18n.t("error_messages.load_messages"))
        console.error("Error while loading messages: " + error)
      }
    },

    async fetchUnread({ dispatch, commit, state, rootState }) {
      try {
        const chats = await ChatRepository.getUnread()
        commit("setUnreadChats", chats)
      } catch (error) {
        dispatch("alert/error", i18n.t("error_messages.chatlist"))
        /*eslint-disable no-console */
        console.error("error while loading chats: " + error)
      }
    }

    /*
    pollUnreadChats({ dispatch, commit }) {
      dispatch("fetchUnread");
      commit(
        "setUnreadPolling",
        setInterval(() => {
          dispatch("fetchUnread");
        }, UNREAD_MESSAGES_POLLING)
      );
    }
    */
  },
  getters: {
    myself(state, getters, rootState): IChatParticipant {
      return {
        _id: rootState.user.userdata.id,
        username: rootState.user.userdata.name,
        avatar: undefined,
        status: undefined
      }
    },
    getChatRooms(state, getters, rootState): ICR[] {
      return state.chats
    },
    getMessages(state, getters, rootState): ICM[] {
      return state.messages
    },
    getPartnerId(state): number | null {
      return state.partner
    },
    getActiveRoomId(state): string | null {
      return state.activeRoomId
    },
    getRoomIds(state): Array<string> {
      return state.chats.map((chat: ICR) => chat.roomId)
    }
  }
}

// HELPERS to convert api types to advanced-chat types

async function mapApiChat(chat: Chat, myself: number): Promise<ICR> {
  const isMeUserOne = chat.user1.id == myself
  const me: ChatParticipant = isMeUserOne ? chat.user1 : chat.user2
  const partner: ChatParticipant = !isMeUserOne ? chat.user1 : chat.user2
  const user1 = await mapApiUser(me, false)
  const user2 = await mapApiUser(partner, true)

  const chatRoom = {
    roomId: chat.uuid,
    roomName: partner.name,
    avatar: user2.avatar,
    unreadCount: chat.is_read ? 0 : " ",
    index: chat.latest_message?.timestamp,
    users: [user1, user2],
    lastMessage: mapChatMessage(chat.latest_message),
    is_blocked: chat.is_blocked
  } as ICR

  return chatRoom
}

function mapChatMessage(lastMessage: ChatMessage | undefined): ICRM | undefined {
  if (lastMessage === null || lastMessage === undefined) return undefined

  return {
    content: lastMessage.text,
    senderId: lastMessage.send_by.id,
    username: lastMessage.send_by.name,
    timestamp: getLastMessageTimestamp(lastMessage.timestamp),
    saved: true,
    distributed: true,
    seen: true,
    new: true
  }
}

async function mapApiUser(partner: ChatParticipant, isPartner: boolean): Promise<IChatParticipant> {
  const participant = {
    _id: partner.id,
    username: partner.name,
    avatar: require("@/assets/user_avatars/anonymous.png"),
    status: undefined
  }

  try {
    const user = await UserRepository.getUser(partner.id, isPartner)

    if (user) participant.avatar = user.avatar!

    return participant
  } catch (error) {
    /* eslint-disable no-console */
    console.error("Error while fetching foreign user from backend.")
    return participant
  }
}
function formatTimestampMessage(timestamp: string): string {
  const d = new Date(timestamp)
  return d.toLocaleTimeString(undefined, {
    hour: "2-digit",
    minute: "2-digit"
  })
}
function formatTimestampDate(timestamp: string): string {
  const today = new Date()
  const from = new Date(timestamp)
  if (today.toDateString() === from.toDateString()) return <string>i18n.t("today")
  return from.toLocaleDateString(undefined, {
    year: "numeric",
    month: "2-digit",
    day: "2-digit"
  })
}

function timestampToIndex(timestamp: string): number {
  // discard miliseconds
  timestamp = timestamp.split(".")[0]
  // remove non digit characters and convert to integer
  return parseInt(timestamp.replaceAll(/[\D]+/gi, ""))
}

function mapApiMessage(message: ChatMessage): ICM {
  // const date = new Date(message.timestamp);

  counter = counter + 1
  return {
    //_id: message.uuid,
    _id: counter,
    indexId: timestampToIndex(message.timestamp),
    content: message.text,
    senderId: message.send_by.id,
    username: message.send_by.name,
    avatar: message.send_by.profilePicture,
    date: formatTimestampDate(message.timestamp),
    timestamp: formatTimestampMessage(message.timestamp),

    // needed props to set the functionality of messages
    system: false,
    saved: true,
    distributed: true,
    seen: false,
    deleted: false,
    disableActions: false,
    disableReactions: true
  }
}

async function fetchAllMessages(roomId: string, limit = 20): Promise<Array<ChatMessage>> {
  const messages = await ChatRepository.getMessages(roomId, limit, limit - 20)
  const data = messages.results
  const next = messages.next

  if (next) {
    return data.concat(await fetchAllMessages(roomId, limit + 20))
  } else {
    return data
  }
}

export default chat
