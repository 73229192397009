



















import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({})
export default class InfoDialog extends Vue {
  @Prop({type: Boolean, default: false}) showInfoDialog!: boolean
  @Prop({type: String, required: true}) headline!: string
  @Prop({type: String, required: true}) description!: string

  get show(): boolean {
    return this.showInfoDialog
  }
  set show(value: boolean) {
    if(!value)
      this.$emit('close')
  }
}
