























import { Component, Vue } from "vue-property-decorator"
import GuideFirefox from "./GuideFirefox.vue"
import GuideSafari from "./GuideSafari.vue"
import GuideOpera from "./GuideOpera.vue"

@Component({
  components: {
    GuideFirefox,
    GuideSafari,
    GuideOpera
  }
})
export default class PWAInstallPrompt extends Vue {
  showDialog: boolean = true
  showInstallGuide: boolean = false

  setCookie(installed: boolean): void {
    this.$cookies.set("pwa_install", installed, Infinity)
  }

  async install(): Promise<void> {
    if (!this.isSupportedBrowser) {
      this.showInstallGuide = true
      return void 0
    }
    const { pwaPrompt } = this.$store.state.mobile
    pwaPrompt.prompt()
    this.close((await pwaPrompt.userChoice) === "accepted")
  }

  close(installed: boolean): void {
    this.setCookie(installed)
    this.showDialog = false
    this.showInstallGuide = false
  }

  get isSupportedBrowser(): boolean {
    return this.$store.state.mobile.pwaPrompt
  }
}
