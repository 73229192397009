/* eslint-disable no-console */

// Load every image from the theme folder into context
const THEME_CONTEXT = require.context('@/assets/themes/', true, /\.(png|jpg|jpeg|gif)$/);
import green_valley from "@/assets/themes/green_valley/theme.json";
import vector from "@/assets/themes/vector/theme.json";

const THEMES = [
    green_valley,
    vector
] as ThemeDefinition[];

export enum ThemeName {
    green_valley = 0,
    vector = 1
}

export const ThemeLoader: ThemeLoader = {
    getTheme: (themeName: ThemeName) => new ThemeImpl(THEMES[themeName] as ThemeDefinition)
}

export interface Theme {
    getImagesForEvent(eventIndex: number, difficulty: number): EventImages;
    getBackgroundImage(): string;
    getPartsPerLayer(): number;
}

export interface EventImages {
    /** paths for background layers (back to front) */
    backgroundImages: string[],
    /** paths for differen mountain variation images */
    mountainImage: string,
    /** paths for foreground layers (back to front) */
    foregroundImages: string[]
}


interface ThemeDefinition {
    name: string,
    fileExtension: string,
    partsPerLayer: number,
    backgroundLayers: number[], // Length = number of layers, value = number of variations per layer
    mountainStyles: number[], // Length = styles, Content = variations
    foregroundLayers: number[] // Length = number of layers, value = number of variations per layer
}

interface ThemeLoader {
    getTheme(themeName: ThemeName): Theme;
}

class ThemeImpl implements Theme {

    constructor(private theme: ThemeDefinition){}

    getImagesForEvent(eventIndex: number, difficulty: number): EventImages {
        return {
            backgroundImages: this.getImages(this.theme.backgroundLayers, eventIndex, true),
            mountainImage: difficulty >=0 ? this.getMountainImage(this.theme.mountainStyles, eventIndex, difficulty) : '',
            foregroundImages: this.getImages(this.theme.foregroundLayers, eventIndex)
        } as EventImages;
    }

    getBackgroundImage(): string {
        return `${this.theme.name}/background.${this.theme.fileExtension}`;
    }

    getPartsPerLayer(): number {
        return this.theme.partsPerLayer;
    }

    private getImages(layers: number[], eventIndex: number, is_background: boolean = false): string[] {
        return layers.map((numberOfVariations, layerIndex) => {
            const varNumber = this.getVariationNumber(numberOfVariations, layerIndex, eventIndex);
            const part = this.calculatePart(eventIndex, this.theme.partsPerLayer);
            return this.buildImagePath(layerIndex + 1, varNumber, part, is_background);
        }).map(img => THEME_CONTEXT(img)).reverse();
    }

    private getMountainImage(mountainDifficulties: number[], eventIndex: number, difficulty: number): string {        
        const variations = mountainDifficulties[difficulty - 1];
        const variation = eventIndex % variations + 1;
        const imagePath = this.buildMountainImagePath(difficulty, variation);

        return THEME_CONTEXT(imagePath);
    }

    private getVariationNumber(numberOfVariations: number, layerIndex: number, eventIndex: number): number {
        // TODO randomize variation selection
        return Math.floor(eventIndex / this.theme.partsPerLayer) % numberOfVariations + 1;
    }

    private buildImagePath(layer: number, variation: number, part: number, is_background: boolean = false): string {
        return `./${this.theme.name}/${is_background ? 'background' : 'foreground'}/${layer}/${variation}/${part}.${this.theme.fileExtension}`;
    }

    private buildMountainImagePath(difficulty: number, variation: number): string {
        return `./${this.theme.name}/mountains/${difficulty}/${variation}.${this.theme.fileExtension}`;
    }

    private calculatePart(eventIndex: number, parts: number) {
        return eventIndex % parts + 1;
    }
}