import { Module } from "vuex"
import { SettingsState, RootState } from "../types"
import SettingsRepository from "@/api/SettingsRepository"
// @ts-ignore
import SettingsObject from "@/models/SettingsObject.ts"
import OnboardingSettings from "@/models/OnboardingSettings"
import PrivacySettings from "@/models/PrivacySettings"
import i18n from "@/locales/i18n"

const use_social_preset = {
  diagnosis: {
    friend: false,
    foreign: false
  },
  initial_diagnosis: {
    friend: false,
    foreign: false
  },
  clinic: {
    friend: true,
    foreign: true
  },
  sex: {
    friend: true,
    foreign: true
  },
  birthdate: {
    friend: true,
    foreign: true
  },
  avatar: {
    friend: true,
    foreign: true
  },
  clinic_status: {
    friend: true,
    foreign: true
  },
  event_chain: {
    friend: false,
    foreign: false
  }
}

const hide_preset = {
  diagnosis: {
    friend: false,
    foreign: false
  },
  initial_diagnosis: {
    friend: false,
    foreign: false
  },
  clinic: {
    friend: false,
    foreign: false
  },
  sex: {
    friend: false,
    foreign: false
  },
  birthdate: {
    friend: false,
    foreign: false
  },
  avatar: {
    friend: false,
    foreign: false
  },
  clinic_status: {
    friend: false,
    foreign: false
  },
  event_chain: {
    friend: false,
    foreign: false
  }
}

const settings: Module<SettingsState, RootState> = {
  state: {
    // needs to be defined on initialization -> otherwise changes are not detected by getters
    onboarding: {
      onboarding_chat: true,
      profile_hints: true,
      information_hints: true,
      startpage_hints: true,
      chat_hints: true,
      tracking_hints: true
    },
    privacy: hide_preset,
    notifications: {
      daily_mail: true
    },
    experience: {
      show_initial_hint: true,
      show_start_hint: true,
      last_pos: 0,
      min_hint_distance: 2,
      ask_preferences: true,
      hint_type: "all"
    }
  } as SettingsState,
  mutations: {
    setSettings(state, newSettings: SettingsObject) {
      state.onboarding = { ...state.onboarding, ...newSettings.onboarding }
      state.privacy = { ...state.privacy, ...newSettings.privacy }
      state.notifications = { ...state.notifications, ...newSettings.notifications }
      state.experience = { ...state.experience, ...newSettings.experience }
    }
  },
  actions: {
    async integrateBackendSettings({ commit, dispatch }) {
      try {
        const beSettings = await SettingsRepository.getSettings()
        const corrSett = {
          onboarding: beSettings.onboarding,
          notifications: beSettings.notifications,
          privacy: beSettings.privacy,
          experience: beSettings.experience
        }
        commit("setSettings", corrSett)
      } catch (error) {
        dispatch("alert/error", i18n.t("error_messages.user"))
        /*eslint-disable no-console */
        console.error("error while fetching user: " + error)
      }
    },
    // this is update when onboarding is finished
    async updateOnboardingSettings({ commit, dispatch }, newSettings: Object) {
      try {
        const settObj = { onboarding: newSettings, notifications: {}, privacy: {}, experience: {} }
        await SettingsRepository.editSettings(settObj)
        commit("setSettings", settObj)
      } catch (error) {
        dispatch("alert/error", i18n.t("error_messages.update_user"))
        /*eslint-disable no-console */
        console.error("error while updating onboarding setting: " + error)
      }
    },
    async updateNotificationSetting({ commit, dispatch }, newSettings: Object) {
      try {
        const settObj = { privacy: {}, onboarding: {}, notifications: newSettings, experience: {} }
        await SettingsRepository.editSettings(settObj)
        commit("setSettings", settObj)
      } catch (error) {
        dispatch("alert/error", i18n.t("error_messages.update_user"))
        /*eslint-disable no-console */
        console.error("error while updating privacy setting: " + error)
      }
    },
    async updatePrivacySettings({ commit, dispatch }, newSettings: Object) {
      try {
        const settObj = { privacy: newSettings, onboarding: {}, notifications: {}, experience: {} }
        await SettingsRepository.editSettings(settObj)
        commit("setSettings", settObj)
      } catch (error) {
        dispatch("alert/error", i18n.t("error_messages.update_user"))
        /*eslint-disable no-console */
        console.error("error while updating privacy setting: " + error)
      }
    },
    async updateExperienceSettings({ commit, dispatch }, newSettings: Object) {
      try {
        const settObj = { privacy: {}, onboarding: {}, notifications: {}, experience: newSettings }
        await SettingsRepository.editSettings(settObj)
        commit("setSettings", settObj)
      } catch (error) {
        dispatch("alert/error", i18n.t("error_messages.update_user"))
        /*eslint-disable no-console */
        console.error("error while updating experience setting: " + error)
      }
    },
    setExperiencePreferences({ commit }, preferences: Object) {
      const settObj = { privacy: {}, onboarding: {}, notifications: {}, experience: preferences }
      commit("setSettings", settObj)
    }
  },
  getters: {
    getOnboardingSetting: (state) => (key: keyof OnboardingSettings) => {
      return state.onboarding[key]
    },
    getPrivacySetting: (state) => (key: keyof PrivacySettings, friend: boolean) => {
      return friend ? state.privacy[key].friend : state.privacy[key].foreign
    },
    getPrivacySettings: (state) => {
      return state.privacy
    },
    getDailyMailSetting: (state) => {
      return state.notifications.daily_mail
    },
    getExperienceSettings: (state) => {
      return state.experience
    },
    getExperienceHintType: (state) => {
      return state.experience.hint_type
    },
    getOnboardingPending: (state) => {
      return state.onboarding.onboarding_chat
    }
  }
}

export default settings
