import { Module } from "vuex"
import { ENotificationDisplayState, NotificationState, RootState } from "../types"
import {
  Notification,
  ENonBannerNotificationType,
  EBannerNotificationType,
  InfoTextNotification
} from "@/models/Notification"
import NotificationRepository from "@/api/NotificationRepository"
import i18n from "@/locales/i18n"
import { InfoText } from "@/models/InfoText"

//set notification timeout to 10 seconds for banner notifications
//after a user closes a notification, it takes 10 seconds for the n
//next notification to appear
//const NOTIFICATIONTIMEOUT = 10 * 100000;
const NOTIFICATIONTIMEOUT = 10 * 100

export const notificationModule: Module<NotificationState, RootState> = {
  state: () => ({
    notifications_hash: "initial",
    notifications: [] as Array<Notification>,
    displayState: ENotificationDisplayState.IDLE
  }),
  mutations: {
    updateNotificationsHash(state, payload: string): void {
      state.notifications_hash = payload
    },
    addNotifications(state, payload: Array<Notification>): void {
      //state.notifications.push(...payload);
      state.notifications = payload
    },
    removeNotificationFromStore(state, payload: Notification): void {
      const index = state.notifications.findIndex((n: Notification) => n.id == payload.id)
      //findIndex returns -1 if element is not found
      index !== -1 ? state.notifications.splice(index, 1) : state.notifications
    },
    removeInfoTextNotificationFromStore(state, payload: InfoText) {
      const index = state.notifications.findIndex((n: Notification) => {
        const notif = n as InfoTextNotification
        notif.data.id == payload.id
      })
      //findIndex returns -1 if element is not found
      index !== -1 ? state.notifications.splice(index, 1) : state.notifications
    },
    removeChatNotificationsFromStore(state) {
      const nonChatNotifications = state.notifications.filter((n: Notification) => {
        const notif = n as InfoTextNotification
        if (notif.type !== ENonBannerNotificationType.NEW_MESSAGE) return notif
      })

      state.notifications = nonChatNotifications
    },
    setNotificationDisplayState(state, payload: ENotificationDisplayState): void {
      state.displayState = payload
    }
  },
  actions: {
    async loadNotifications({ commit, dispatch, state }): Promise<void> {
      try {
        const response = await NotificationRepository.getNotifications(state.notifications_hash)
        if (response !== null) {
          //const response = await NotificationRepository.getMockedNotifications();
          commit("addNotifications", response.notifications)
          commit("updateNotificationsHash", response.notification_hash)
        }
      } catch (error) {
        dispatch("alert/error", i18n.t("error_messages.notifications"))
        console.error("error while loading Notifications: " + error)
      }
    },

    // Info Text Notifications
    async markInfoTextNotificationsAsSeen(
      { commit, dispatch, state },
      infoText: InfoText
    ): Promise<void> {
      try {
        const notification = state.notifications.find((n: Notification) => {
          const notif = n as InfoTextNotification
          if (
            notif.data.id == infoText.id &&
            notif.type === ENonBannerNotificationType.NEW_RECOMMENDED_INFO_TEXT
          )
            return notif
        })

        if (notification !== null || notification !== undefined) {
          //Delete Notification from Store, even if Server returns error code
          commit("removeInfoTextNotificationFromStore", infoText)

          const success = await NotificationRepository.deleteNotification(notification!)
        }
      } catch (error) {
        dispatch("alert/error", i18n.t("error_messages.delete_notifications"))
        console.error("error while deleting Notification: " + error)
      }
    },

    // Chat Message Notifications
    async markChatNotificationsAsSeen({ commit, dispatch, state }): Promise<void> {
      try {
        const notifications = state.notifications.filter((n: Notification) => {
          const notif = n as InfoTextNotification
          if (notif.type === ENonBannerNotificationType.NEW_MESSAGE) return notif
        })

        if (notifications !== null || notifications !== undefined) {
          //Delete Notification from Store, even if Server returns error code
          commit("removeChatNotificationsFromStore")

          await notifications.forEach((notification) => {
            const success = NotificationRepository.deleteNotification(notification!)
          })
        }
      } catch (error) {
        dispatch("alert/error", i18n.t("error_messages.delete_notifications"))
        console.error("error while deleting Notification: " + error)
      }
    },

    //Banner Notifications
    async markNotificationAsSeen({ commit, dispatch }, notification: Notification): Promise<void> {
      try {
        //Delete Notification from Store, even if Server returns error code
        commit("removeNotificationFromStore", notification)

        const success = await NotificationRepository.deleteNotification(notification)
      } catch (error) {
        dispatch("alert/error", i18n.t("error_messages.delete_notifications"))
        console.error("error while deleting Notification: " + error)
      }
    },
    displayBannerNotification({ commit }): void {
      commit("setNotificationDisplayState", ENotificationDisplayState.DISPLAY)
    },
    closeBannerNotification({ commit }): void {
      commit("setNotificationDisplayState", ENotificationDisplayState.BLOCK)
      setTimeout(() => {
        commit("setNotificationDisplayState", ENotificationDisplayState.IDLE)
      }, NOTIFICATIONTIMEOUT)
    }
  },
  getters: {
    //Chat Notifications
    getChatNotifications(state): Array<Notification> {
      return state.notifications.filter(
        (n: Notification) => n.type == ENonBannerNotificationType.NEW_MESSAGE
      )
    },
    getChatNotificationsCount(state, getters): number {
      return getters.getChatNotifications.length
    },

    //Info Text Notifications
    getInfoTextNotifications(state): Array<Notification> {
      return state.notifications.filter(
        (n: Notification) => n.type == ENonBannerNotificationType.NEW_RECOMMENDED_INFO_TEXT
      )
    },
    getInfoTextNotificationsCount(state, getters): number {
      return getters.getInfoTextNotifications.length
    },
    getNotifiedInfoTextIds(state, getters): Array<number> {
      const ids: Array<number> = []
      getters.getInfoTextNotifications.map((n: Notification) => {
        n = n as InfoTextNotification
        ids.push(n.data.id)
      })
      return ids
    },

    //BannerNotifications
    getBannerNotifications(state): Array<Notification> {
      return state.notifications.filter((n: Notification) =>
        Object.values(EBannerNotificationType).includes(n.type as EBannerNotificationType)
      )
    },
    getBannerNotificationsCount(state, getters): number {
      return getters.getBannerNotifications.length
    },
    getMostRecentBannerNotification(state, getters): Notification | null {
      if (
        getters.getBannerNotificationsCount == 0 ||
        state.displayState == ENotificationDisplayState.BLOCK
      ) {
        return null
      } else {
        return getters.getBannerNotifications[0]
      }
    },
    getIsNotificationBannerReadyForDisplay(state): boolean {
      return state.displayState == ENotificationDisplayState.IDLE
    }
  }
}
