import { Module } from "vuex";
import { EventState, RootState } from "../types";
import Event from "@/models/Event";
import EventRepository from "@/api/EventRepository";
import i18n from "@/locales/i18n";
import ActionRepository from "@/api/ActionRepository";
import TrackedAction, { ETrackedAction } from "@/models/Action";

function compareEvents(a: Event, b: Event) {
  if (a.datetime > b.datetime) return -1;
  else return 1;
}

export const eventModule: Module<EventState, RootState> = {
  state: {
    events: [],
    eventCountTotal: 0, // total number of events, even if not loaded yet
    trackedActionNewEntry: {
      key: ETrackedAction.EXPERIENCE_MODE_EVENT_OPENED,
      gained_xp: 0,
      xp_per_action: 0,
      max_xp: 0,
    },
  },
  mutations: {
    addEvent(state, payload: Event) {
      state.events.push(payload);
    },
    addEvents(state, payload: Array<Event>) {
      // let set = new Set(state.events);
      // payload.forEach(e => set.add(e));
      // state.events = [...set]; --> does not prevent events from being added twice -> manual fix
      payload.forEach(function (e) {
        if (!state.events.some((el) => el.id == e.id)) {
          state.events.push(e);
        }
      });
    },
    updateEvent(state, payload: Event) {
      const index = state.events.findIndex(
        (event: Event) => event.id === payload.id
      );
      Object.assign(state.events[index], payload);
    },
    updateEventCount(state, payload) {
      state.eventCountTotal = payload;
    },
    removeEvent(state: any, payload: number) {
      const index = state.events.findIndex(
        (event: Event) => event.id === payload
      );
      state.events.splice(index, 1);
    },
    emptyEvents(state) {
      state.events = [];
    },
    updateNewEntryTrackedAction(state, payload: TrackedAction) {
      state.trackedActionNewEntry = payload;
    },
  },
  actions: {
    async loadInitialEvents({ commit, dispatch }) {
      try {
        dispatch("changeIsLoading", true);
        commit("emptyEvents");
        const response = await EventRepository.getLastEvents(
          calculateBlockSize(),
          0
        );
        commit("addEvents", response.results);
        commit("updateEventCount", response.count);
      } catch (error) {
        dispatch("alert/error", i18n.t("error_messages.events"));
        /*eslint-disable no-console */
        console.error("error while loading event: " + error);
      } finally {
        dispatch("changeIsLoading", false);
      }
    },
    async loadAdditionalEvents({ state, commit, dispatch }) {
      try {
        dispatch("changeIsLoading", true);
        const response = await EventRepository.getLastEvents(
          calculateBlockSize(),
          state.events.length
        );
        commit("addEvents", response.results);
        state.events.sort(compareEvents);
        // commit('updateEventCount', response.count);
      } catch (error) {
        dispatch("alert/error", i18n.t("error_messages.events"));
        /*eslint-disable no-console */
        console.error("error while loading event: " + error);
      } finally {
        dispatch("changeIsLoading", false);
      }
    },
    async deleteEvent({ state, commit, dispatch }, id: number) {
      try {
        await EventRepository.deleteEvent(id);
        commit("removeEvent", id);
        commit("updateEventCount", state.eventCountTotal.valueOf() - 1);
      } catch (error) {
        dispatch("alert/error", i18n.t("error_messages.delete_event"));
        /*eslint-disable no-console */
        console.error("error while deleting event: " + error);
      }
    },
    // in case neighbor hasn't been loaded by scrolling -> load
    async updateForNeighbors({ state, dispatch }, id: number) {
      const index = state.events.findIndex((e) => e.id == id);
      if (index > -1) {
        if (
          index + 1 >= state.events.length &&
          state.eventCountTotal > state.events.length
        ) {
          await dispatch("loadAdditionalEvents");
        }
      }
    },
    async loadExperienceActions({ state, commit, dispatch, getters }) {
      try {
        const response = await ActionRepository.get();
        const newEntryTrackedAction = response.find(
          (action) => action.key == ETrackedAction.NEW_ENTRY
        );
        commit("updateNewEntryTrackedAction", newEntryTrackedAction);
      } catch (error) {
        dispatch("alert/error", i18n.t("error_messages.experience_mode_state"));
        /*eslint-disable no-console */
        console.error(
          "error while loading experience mode tracked action: " + error
        );
      }
    },
    updateEventCount({ commit }, payload) {
      commit("updateEventCount", payload);
    },
    addEvent({ commit, dispatch, state }, event) {
      commit("addEvent", event);
      commit("updateEventCount", state.eventCountTotal.valueOf() + 1);
      state.events.sort(compareEvents);
      dispatch("addSingleEvent", event);
    },
  },
  getters: {
    moreEventsAvailable(state) {
      return state.eventCountTotal > state.events.length;
    },
    getEvents(state) {
      return state.events;
    },
    getNeighbors: (state) => (id: number) => {
      const index = state.events.findIndex((e) => e.id == id);
      if (index > -1) {
        const neighbors = {
          before: state.events[index + 1],
          after: state.events[index - 1],
        };
        return neighbors;
      } else {
        return null;
      }
    },
    hasEvents(state) {
      return state.events.length > 0;
    },
    eventCountTotal(state) {
      return state.eventCountTotal;
    },
    eventCount(state) {
      return state.events.length;
    },
    getEventIndex: (state) => (id: number) => {
      return state.events.findIndex((e) => e.id == id);
    },
    getBlockSize() {
      return calculateBlockSize();
    },
    getEventXpGained(state) {
      return state.trackedActionNewEntry.gained_xp;
    },
    getEventXpMax(state) {
      return state.trackedActionNewEntry.max_xp;
    },
  },
};

function calculateBlockSize(): number {
  const sizePerEvent = window.innerHeight * 0.31; // event: 31vh
  const eventsToLoad = Math.ceil(window.innerWidth / sizePerEvent);
  return eventsToLoad;
}
