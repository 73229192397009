






























































































































































































































































































































































































































































































































































































































































































































































































import { Vue, Component, Prop, Watch } from "vue-property-decorator"
import User from "@/models/User"
import UserRepository from "@/api/UserRepository"
import BugReportRepository from "@/api/BugReportRepository"
import ClinicRepository from "@/api/ClinicRepository"
import DiagnosisRepository from "@/api/DiagnosisRepository"
import { passwordValidators } from "@/utils/password_validator"
import SettingsObject from "../../models/SettingsObject"
import Privacy from "@/components/social/Privacy.vue"
import LoadingSpinner from "@/components/LoadingSpinner.vue"
import Clinic from "@/models/Clinic"
import MailTo from "@/utils/MailTo"
import Diagnosis from "@/models/Diagnosis"
import { BugReportBody } from "@/models/BugReport"
import ClinicDropdown from "@/components/dropdowns/ClinicDropdown.vue"
import DiagnosisDropdown from "@/components/dropdowns/DiagnosisDropdown.vue"
import TrackingRepository from "@/api/TrackingRepository"
import { DateTimeFormatOptions } from "vue-i18n"

@Component({
  components: {
    Privacy,
    LoadingSpinner,
    ClinicDropdown,
    DiagnosisDropdown
  }
})
export default class ProfileSettings extends Vue {
  @Prop({ type: Boolean, default: false }) showSettings!: boolean
  user: User = {} as User
  editGender: boolean = false
  editBirthday: boolean = false
  editDiagnosis: boolean = false
  editFirstDiagnosis: boolean = false
  editClinic: boolean = false
  editNickname: boolean = false
  editEmail: boolean = false
  editPassword: boolean = false
  editLanguage: boolean = false
  notificationMail: boolean = true
  showNotificationMailHelp: boolean = false
  panel: number = 0
  genderoptions: object[] = []
  availableClinics: Clinic[] = []
  availableDiagnoses: Diagnosis[] = []
  userEdited: any = {}
  inputDay: number = 1
  inputMonth: number = 1
  inputYear: number = 2000
  days: number[] = Array.from(new Array(31), (x, i) => i + 1)
  months: { name: string; value: number }[] = []
  years: number[] = Array.from(new Array(35), (x, i) => i + (new Date().getFullYear() - 34))
  oldP: string = ""
  newP: string = ""
  passwordChangeSuceeded: boolean = false
  passwordChangeFailed: boolean = false
  showOldP: boolean = false
  showNewP: boolean = false
  error: any = {}
  rules: any = {
    required: (v: string) => !!v || v.trim().length > 0 || this.$t("password_validate.empty"),
    noBlanks: (v: string) =>
      v.length == v.replace(/\s/g, "").length || this.$t("password_validate.contains_whitespace"),
    lengthMin: (v: string) => v.trim().length >= 2 || this.$t("name_validate.to_short"),
    lengthMax: (v: string) => v.length <= 255 || this.$t("name_validate.to_long")
  }
  errorInPWOld: boolean = true
  errorInPWNew: boolean = true
  editFriendVisibility: boolean = true
  isLoading: boolean = true
  validName: boolean = false
  deleteDialog: boolean = false
  //support
  isSupportDialogOpened: boolean = false
  isReportBugFormDisplayed: boolean = false
  bugReportBody: BugReportBody = {
    which_feature: "",
    steps_to_reproduce: "",
    reproducable: ""
  } as BugReportBody
  screenshot: File | null = null
  buildId: string | undefined = process.env.VUE_APP_PIPELINE_ID || "local development"
  languageList: SelectField[] = []

  get today(): string {
    const now = new Date(Date.now())
    return now.toISOString().substr(0, 10)
  }
  get language(): string {
    return navigator.language
  }
  get passwordRules() {
    return passwordValidators((key: string) => this.$t(key))
  }
  get searchable(): boolean {
    return this.$store.getters.getUserData("is_searchable")
  }
  get showSettingsDialog(): boolean {
    return this.showSettings
  }
  set showSettingsDialog(value: boolean) {
    if (!value) {
      this.$emit("disableEventform")
      this.$emit("close")
    }
  }
  get userLocale(): string {
    return this.languageList.find((x) => x.value == this.user.language)?.text || "Unknown language"
  }

  async created() {
    for (let i = 0; i < 12; ) {
      this.months.push({
        name: <string>this.$t(`months[${i}]`),
        value: ++i
      })
    }
    Object.keys(this.$i18n.messages).forEach((value) => {
      this.languageList.push({
        text: <string>this.$i18n.messages[value].__language__,
        value: value
      })
    })
    this.fillGenderOptions()
    await Promise.all([
      this.loadClinics(),
      this.loadDiagnoses(),
      this.$store.dispatch("integrateBackendSettings")
    ])

    this.notificationMail = this.$store.getters.getDailyMailSetting
  }
  async mounted() {
    //this.integrateUserDataFromStore();

    await this.$store.dispatch("integrateUserDataFromBackend")
    this.user = this.$store.getters.getUser
    if (Object.keys(this.user).length == 0) {
      this.$store.dispatch("alert/error", "Integrating user data from store went wrong.")
    }
    this.userEdited = Object.assign({}, this.user)

    //setting 'not specified' if treated_at is null
    if (this.user.treated_at == null) {
      this.userEdited.treated_at = this.availableClinics.find((clinic) => clinic.id == -1)
    }
    if (this.user.diagnosis == null) {
      this.userEdited.diagnosis = this.availableDiagnoses.find((diagnosis) => diagnosis.id == -1)
    }

    if ((this.user as any).participant_code == null) {
      this.userEdited.participant_code = "---"
    }
    this.isLoading = false
  }
  showSupportDialog(): void {
    this.isSupportDialogOpened = true
  }
  showReportBugForm(): void {
    this.isReportBugFormDisplayed = !this.isReportBugFormDisplayed
  }
  resetSupportDialog(): void {
    this.isSupportDialogOpened = false
    this.isReportBugFormDisplayed = false
    this.screenshot = null
    this.bugReportBody = {
      which_feature: "",
      steps_to_reproduce: "",
      reproducable: ""
    }
  }
  buildSupportMailString(): string {
    const mailTo = new MailTo(this.$browserDetect.meta, this.$store.getters.getUser.name, "simple")
    return mailTo.toString()
  }
  setScreenshot(file: File): void {
    this.screenshot = file
  }
  formatDate(date: string): string {
    if (!date) return ""
    const dateObject: Date = new Date(date)
    const options: DateTimeFormatOptions = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric"
    }
    return dateObject.toLocaleDateString(this.language, options)
  }

  @Watch("notificationMail")
  updateNotificationMailSetting() {
    const newSetting = { daily_mail: this.notificationMail }
    this.$store.dispatch("updateNotificationSetting", newSetting)
  }

  async logout(): Promise<void> {
    let groupID = "SETT:"
    await TrackingRepository.createLog(groupID + "Logout")
    this.$store.dispatch("logout")
  }
  fillGenderOptions(): void {
    this.genderoptions = [
      { id: "F", description: this.$t("F") },
      { id: "M", description: this.$t("M") },
      { id: "D", description: this.$t("D") },
      { id: null, description: this.$t("not_specified") }
    ]
  }
  async loadClinics(): Promise<void> {
    try {
      const clinics = await ClinicRepository.get()
      this.availableClinics = clinics

      // add item for 'not specified'
      this.availableClinics.push({
        id: -1,
        name: this.$t("not_specified").toString(),
        address: null
      })
    } catch (error) {
      this.$store.dispatch("alert/error", this.$t("error_messages.clinics"))
      /* eslint-disable no-console */
      console.error("Error while fetching clinics: " + error)
    }
  }
  async loadDiagnoses(): Promise<void> {
    try {
      ;(await DiagnosisRepository.get()).forEach((item) => {
        this.availableDiagnoses.push(item)
      })

      // add item for 'not specified'
      this.availableDiagnoses.push({
        id: -1,
        name: "not_specified",
        diagnosis_group: null
      })
    } catch (error) {
      this.$store.dispatch("alert/error", this.$t("error_messages.diagnoses"))
      /* eslint-disable no-console */
      console.error("Error while fetching diagnoses: " + error)
    }
  }
  handleBirthDateNotSpecified(): void {
    this.userEdited.birthdate = null
    this.editBirthday = false
    this.saveUserChanges("birthdate")
  }
  handleDiagnoseNotSpecified(): void {
    this.userEdited.initial_diagnosis = null
    this.editFirstDiagnosis = false
    this.saveUserChanges("initial_diagnosis")
  }
  async saveUserChanges(changedField: string): Promise<void> {
    let payload: any = {}
    let setting = ""
    let settingValue = ""
    switch (changedField) {
      case "gender":
        if (this.userEdited.sex === "not_specified") this.userEdited.sex = null
        //this.matchGenderOption(this.userEdited.sex)
        payload = { sex: this.userEdited.sex }
        this.editGender = false
        setting = "Geschlecht"
        settingValue = "" + this.userEdited.sex
        break
      case "birthdate":
        // TODO delete option not possible because of design
        payload = { birthdate: this.userEdited.birthdate }
        this.editBirthday = false
        setting = "Geburtstag"
        settingValue = "" + this.userEdited.birthdate
        break
      case "diagnosis":
        payload = {
          diagnosis: this.userEdited.diagnosis.id === -1 ? null : this.userEdited.diagnosis.id
        }
        this.editDiagnosis = false
        setting = "Diagnose"
        settingValue = "" + this.userEdited.diagnosis.name
        break
      case "initial_diagnosis":
        // TODO delete option not possible because of design
        payload = { initial_diagnosis: this.userEdited.initial_diagnosis }
        this.editFirstDiagnosis = false
        setting = "Datum Diagnose"
        settingValue = "" + this.userEdited.initial_diagnosis
        break
      case "clinic":
        payload = {
          treated_at: this.userEdited.treated_at.id === -1 ? null : this.userEdited.treated_at.id
        }
        this.editClinic = false
        setting = "Klinik"
        settingValue =
          "" + this.userEdited.treated_at.name + " " + this.userEdited.treated_at.address
        break
      case "name":
        payload = { name: this.userEdited.name }
        this.editNickname = false
        setting = "Name"
        //settingValue = ""+this.userEdited.name
        settingValue = "XXX"
        break
      case "email":
        payload = { email: this.userEdited.email.toLowerCase() }
        this.editEmail = false
        setting = "Email"
        settingValue = "" + payload.email
        break
      case "is_searchable":
        payload = { is_searchable: this.userEdited.is_searchable }
        setting = "Privatsphäre"
        settingValue = "" + this.userEdited.is_searchable
        break
      case "language":
        payload = { language: this.userEdited.language }
        setting = "Sprache"
        settingValue = "" + this.userEdited.language
        this.$i18n.locale = payload.language
        this.editLanguage = false
        break
    }
    if (payload) {
      await this.$store.dispatch("updateUser", payload)
      this.$emit("user-updated")
      this.user = this.$store.getters.getUser
      let groupID = "SETT:"
      await TrackingRepository.createLog(
        groupID + "Ändern der Einstellung '" + setting + "' auf '" + settingValue + "'"
      )
      /*
              await this.$store.dispatch('updateUser', payload).then(() => {
                  this.integrateUserDataFromStore();
              });
              */
    }
  }
  async submitBugReport(): Promise<void> {
    await BugReportRepository.submitBugReport(
      this.bugReportBody,
      this.$browserDetect.meta,
      this.screenshot
    )
    this.isSupportDialogOpened = false
    this.bugReportBody = {
      which_feature: "",
      steps_to_reproduce: "",
      reproducable: ""
    }
  }
  async changePassword(): Promise<void> {
    if (this.oldP && this.newP) {
      try {
        await UserRepository.changePassword(this.user.id, this.oldP, this.newP)
        this.passwordChangeSuceeded = true
        this.passwordChangeFailed = false
      } catch (error) {
        this.passwordChangeSuceeded = false
        this.passwordChangeFailed = true
      }
    }
    this.editPassword = false
    this.oldP = ""
    this.newP = ""
    let groupID = "SETT:"
    await TrackingRepository.createLog(groupID + "Ändern des Passworts")
  }
  async updateSetting(settingspart: keyof SettingsObject, value: boolean): Promise<void> {
    let payload = { [settingspart]: value }
    this.$store.dispatch("updateSettings", payload)
  }
  async markForDeletion(): Promise<void> {
    let groupID = "SETT:"
    await TrackingRepository.createLog(groupID + "Profil löschen")
    await this.$store.dispatch("markForDeletion")
    this.deleteDialog = false
  }
}

interface SelectField {
  text: string
  value: any
}
