import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[(_vm.step>=0)?_c(VRow,[_c(VCol,{attrs:{"cols":"3"}},[_c('Companion',{staticClass:"yc_companion"})],1),(_vm.step == 0)?_c(VCol,[_c('TypingBubble')],1):_vm._e(),(_vm.step >= 1)?_c(VCol,[_c('Speechbubble',{attrs:{"text":_vm.$t('onboarding.companion.question_language')},on:{"rendered":function($event){return _vm.scroll()}}},[(_vm.step == 1)?_c('div',{staticClass:"yc_onb_select lang-select"},[_c(VSelect,{staticClass:"yc_onb_diagnosisselect",attrs:{"data-cy":"onb_lang","items":_vm.available},model:{value:(_vm.language),callback:function ($$v) {_vm.language=$$v},expression:"language"}}),_c(VCardActions,{staticClass:"onb-select-actions"},[_c(VBtn,{staticClass:"yc_btn_primary",attrs:{"outlined":"","text":""},on:{"click":function($event){_vm.step++}}},[_vm._v(_vm._s(_vm.$t('dialogs.submit')))])],1)],1):_vm._e()])],1):_vm._e()],1):_vm._e(),(_vm.step > 1)?_c(VRow,[_c(VCol,[_c('Speechbubble',{attrs:{"text":_vm.$t('onboarding.user.my_language_is', { lang: _vm.langName }),"left":""},on:{"rendered":function($event){return _vm.scroll()}}})],1),_c(VCol,{staticClass:"yc_user_avatar",attrs:{"cols":"3"}},[_c('img',{attrs:{"src":require('@/assets/avatars/anonymous.png')}})])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }