























import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({})
export default class EventButton extends Vue {
    @Prop({type: String, required: true}) location!: string
    @Prop({type: String, required: true}) icon!: string
    @Prop({type: Number, default: 0}) index!: number
    @Prop({type: Boolean, default: false}) highlighted!: boolean
    initialized: boolean = false

    created(): void {
        setTimeout(() => {
            this.initialized = true
        }, 500)
    }
}
