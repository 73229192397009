










































import { Component, Prop, Watch } from 'vue-property-decorator'
import VueStepper from './VueStepper'
import Speechbubble from '@/components/onboarding/Speechbubble.vue'
import Companion from '@/components/onboarding/Companion.vue'
import TypingBubble from '@/components/onboarding/onb_chat/TypingBubble.vue'

@Component({
    components: {
      Speechbubble,
      Companion,
      TypingBubble
    }
})
export default class InputName extends VueStepper {
    @Prop({type: String, default: ''}) avatarPath!: string
    stepTimes: number[] = [2000, 2000, 0, 1500, 2000, 1500] //typing, question name, name input, waiting after name, typing, response to name
    name: string = ''
    rules: any = {
        required: [(v: string) => !!v || this.$t('name_validate.not_empty')],
        length: [(v: string) => v.length >= 2 || this.$t('name_validate.to_short')]
    }
    validName: boolean = false
    
    created(): void{
        this.stepper();
    }

    @Watch('step')
    onStepChange() {
        if(this.step < this.stepTimes.length && this.step != 2)
            this.stepper();
        else if(this.step >= this.stepTimes.length)
            this.$emit('nextOnbPart');
    }
}
