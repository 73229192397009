


















































import { Component, Prop, Watch } from 'vue-property-decorator'
import VueStepper from './VueStepper'
import Speechbubble from "@/components/onboarding/Speechbubble.vue";
import Companion from "@/components/onboarding/Companion.vue";
import TypingBubble from "@/components/onboarding/onb_chat/TypingBubble.vue";
import OnboardingSelect from "@/components/onboarding/onb_chat/OnboardingSelect.vue";
import Diagnosis from "@/models/Diagnosis";

@Component({
  components: {
    Speechbubble,
    Companion,
    TypingBubble,
    OnboardingSelect,
  }
})
export default class InputDiagnosis extends VueStepper {
  @Prop({type: String, default: ''}) avatarPath!: string
  @Prop({type: String, default: ''}) companionText!: string
  diagnosis: Diagnosis | null = null
  stepTimes = [2000, 2000, 0, 1500] //typing, question diagnosis, diagnosis input, waiting after diagnosis

  created(): void {
    this.stepper();
  }

  @Watch('step')
  onStepChange() {
    if (this.step < this.stepTimes.length && this.step != 2) this.stepper();
    else if (this.step >= this.stepTimes.length) this.$emit("nextOnbPart");
  }

  getUserString_Diagnosis(): string {
    if (this.diagnosis)
      return (
        this.$t("onboarding.user.my_diagnosis_is") +
        " " +
        this.$t("diagnoses." + this.diagnosis.name) +
        "."
      );
    return this.$t("onboarding.user.not_tell_diagnosis") + "";
  }
}
