





























import { Component, Vue, Prop, Watch } from "vue-property-decorator"
import Avatar from "@/components/onboarding/Avatar.vue"
import TrackingRepository from "@/api/TrackingRepository"

@Component({
  components: {
    Avatar
  }
})
export default class ProfileAvatar extends Vue {
  @Prop({ type: Boolean, default: false }) ownprofile!: boolean
  @Prop({ type: String, default: "" }) avatar!: string
  chooseAvatar: boolean = false
  profileAvatar: string = ""
  key: number = 0

  @Watch("avatar")
  onAvatarChange(new_avatar: string) {
    this.profileAvatar = new_avatar
  }

  chooseNewAvatar(): void {
    this.chooseAvatar = true
    setTimeout(() => {
      this.key = 1
    }, 1)
  }
  async storeNewAvatar(path: string): Promise<void> {
    this.$store.dispatch("addUserAvatar", path)
    this.profileAvatar = path
    this.chooseAvatar = false
    const groupID = "PROF:"
    await TrackingRepository.createLog(groupID + "Profilbild ändern")
  }
  async mounted(): Promise<void> {
    this.profileAvatar = this.avatar
  }
}
