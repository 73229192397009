import Repository from "./Repository"
import MediaRepository from "@/api/MediaRepository"
import Paginated from "@/models/Paginated"
import User from "@/models/User"
const resource = "/v1/users/"

class UserRepository {
  async get(limit: number, offset: number): Promise<Paginated<User>> {
    const params = {
      limit: limit,
      offset: offset
    }
    const response = await Repository.get(resource, params)
    return await response.json()
  }

  async getUser(userId: number, isPartner: boolean): Promise<User> {
    const response = await Repository.get(`${resource}${userId}/`)
    return this.getUserAvatar(await response.json(), isPartner)
  }

  async getMyself(): Promise<User> {
    const response = await Repository.get(`${resource}loggedin/`)
    return this.getUserAvatar(await response.json(), false)
  }

  async updateUser(userId: number, payload: User): Promise<User> {
    const response = await Repository.patch(`${resource}${userId}/`, payload)
    return this.getUserAvatar(await response.json(), false)
  }

  async searchUser(searchParams: Object): Promise<Paginated<User>> {
    const response = await Repository.get(resource, searchParams)
    return await response.json()
  }

  async changePassword(userId: number, oldPassword: string, newPassword: string) {
    await Repository.post(`${resource}${userId}/change-password/`, {
      old_password: oldPassword,
      new_password: newPassword
    })
  }

  async addAvatar(userId: number, payload: FormData) {
    const args: RequestInit = {
      method: "post",
      body: payload
    }
    await Repository.post(`${resource}${userId}/avatar/`, payload, {}, args)
  }

  /*
  async deleteAvatar(userId: number) {
    await Repository.delete(`${resource}${userId}/avatar/`);
  }
  */

  async markForDeletion(userId: number) {
    return await Repository.put(`${resource}${userId}/deleteflag/`, null)
  }

  // perform request for avatar, because medias are served seperatly via nginx
  async getUserAvatar(user: User, isPartnerAvatar: boolean): Promise<User> {
    if (
      user.avatar &&
      user.avatar !== null &&
      user.avatar !== undefined &&
      user.avatar !== "" &&
      user.avatar.includes(".")
    ) {
      let blob = null
      if (isPartnerAvatar) blob = await MediaRepository.getPartner("profile", user.avatar, user.id)
      else blob = await MediaRepository.get("profile", user.avatar)

      user.avatar = URL.createObjectURL(blob)
      return user
    } else {
      user.avatar = require("@/assets/user_avatars/anonymous.png")
      return user
    }
  }
}

export default new UserRepository()
