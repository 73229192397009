import UserRepository from "@/api/UserRepository"
import User from "@/models/User"
import i18n from "@/locales/i18n"
import { Module } from "vuex"
import { OverlayState, RootState } from "../types"

const initialState: OverlayState = {
  userProfileOverlayState: {
    isDisplayed: false,
    userProfile: null
  },
  createEventOverlayState: {
    isDisplayed: false
  },
  experienceModeState: {
    isDisplayed: false
  }
}
export const overlayModule: Module<OverlayState, RootState> = {
  state: () => initialState,
  mutations: {
    // user profile overlay
    setUserProfileData(state, payload: User): void {
      state.userProfileOverlayState = {
        isDisplayed: true,
        userProfile: payload
      }
    },
    resetUserProfileData(state): void {
      state.userProfileOverlayState = {
        isDisplayed: false,
        userProfile: null
      }
    },

    //create event overlay
    setCreateEventOverlayDisplayed(state, payload: boolean): void {
      state.createEventOverlayState.isDisplayed = payload
    },

    //experience mode
    setExperienceModeDisplayed(state, payload: boolean): void {
      state.experienceModeState.isDisplayed = payload
    }
  },
  actions: {
    //user profile overlay
    async openUserProfileOverlay({ commit, dispatch }, userId: number): Promise<void> {
      try {
        const response = await UserRepository.getUser(userId, true)
        commit("setUserProfileData", response)
      } catch (error) {
        dispatch("alert/error", i18n.t("error_messages.user"))
      }
    },
    closeUserProfileOverlay({ commit }): void {
      commit("resetUserProfileData")
    },

    //create Event Overlay
    openCreateEventOverlay({ commit }): void {
      commit("setCreateEventOverlayDisplayed", true)
    },
    closeCreateEventOverlay({ commit }): void {
      commit("setCreateEventOverlayDisplayed", false)
    },

    //experience Mode

    openExperienceMode({ commit }): void {
      commit("setExperienceModeDisplayed", true)
    },
    closeExperienceMode({ commit }): void {
      commit("setExperienceModeDisplayed", false)
    }
  },
  getters: {
    //user profile overlay
    getIsUserProfileOverlayDisplayed(state): boolean {
      return state.userProfileOverlayState.isDisplayed
    },
    getUserProfileOverlayData(state): User | null {
      return state.userProfileOverlayState.userProfile
    },

    //crete Event Overlay
    getIsCreateEventOverlayDisplayed(state): boolean {
      return state.createEventOverlayState.isDisplayed
    },

    //experience Mode
    getIsExperienceModeDisplayed(state): boolean {
      return state.experienceModeState.isDisplayed
    }
  }
}
