





































import { AssetHelper } from "@/utils/AssetHelper"
import { Randomizer } from "@/utils/Randomizer"

import { Component, Vue, Prop } from "vue-property-decorator"

@Component({})
export default class Avatar extends Vue {
  @Prop({ type: Number, default: -1 }) avatarChoice!: number
  @Prop({ type: Boolean, default: false }) showCloseOption!: boolean
  @Prop({ type: Boolean, default: false }) showDialogTitle!: boolean

  userChoice: number = -1
  avatarIdx: any[] = Randomizer.shuffle(
    Array.apply(null, Array(55)).map(function (x, i) {
      return i + 1
    })
  )

  mounted() {
    this.userChoice = this.avatarChoice
    setTimeout(() => this.$emit("rendered"), 100)
  }

  setUserChoice(id: number) {
    this.userChoice = id
  }
  getAvatarPath(id: number) {
    return AssetHelper.getAvatarIcon(id, false)
  }
  avatarChosen() {
    this.$emit("updateAvatarPath", this.getAvatarPath(this.userChoice))
  }
}
