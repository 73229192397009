















import { Vue, Component } from 'vue-property-decorator'
import ModalBase from "@/components/offline/ModalBase.vue";
import { BASE_URL } from "@/api/Repository";

@Component({
  components: {
    ModalBase
  }
})
export default class UnreachableModal extends Vue {
  async tryConnect() {
    await fetch(BASE_URL)
      .then(() => window.dispatchEvent(new Event('server_reachable')))
      .catch(() => {});
  }
}
