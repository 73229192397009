




















import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

function formatDate(date: Date): string {
    return `${date.getFullYear()}-${(""+(date.getMonth() + 1)).padStart(2, '0')}-${(""+(date.getDate())).padStart(2, '0')}`;
}

@Component({})
export default class TextDateInput extends Vue {
    @Prop({type: String}) initialDate: string | undefined
    @Prop({type: Boolean, default: false}) dontTellOption!: boolean
    @Prop({type: String}) dontTellText: string | undefined
    @Prop({type: [String, Boolean]}) birthdate: string | boolean | undefined

    date: string = ''
    showDatePicker: boolean = false
    dateSetByPerson: boolean = false

    get today(): string{
        const now = new Date(Date.now());
        return formatDate(now);
    }
    get language(): string{
        return navigator.language;
    }
    get buttonText(): string{
        return this.dateSetByPerson ? new Date(this.date).toLocaleDateString() : "xx.xx.xxxx"
    }
    get defaultDate(): string{
        let d = new Date(Date.now());
        d.setFullYear(d.getFullYear()-15);
        return formatDate(d);
    }

    setDate(cancel: boolean): void {
        if(cancel)
            this.$emit('dateChanged', '');
        else
            this.$emit('dateChanged', new Date(this.date).toISOString());
        this.$emit('nextStep');
    }

    @Watch('date')
    onDateChange() {
        if(this.date != this.defaultDate)
            this.dateSetByPerson = true
    }

    mounted(){
        if(this.initialDate)
            this.date = this.initialDate;
        else if(this.birthdate){
            this.date = this.defaultDate;
        }
    }
}
