






































































































































































































































































import { Component, Prop, Vue } from "vue-property-decorator"
import UploadMediaContent from "@/models/UploadMediaContent"
import MediaRepository from "@/api/MediaRepository"

@Component({})
export default class MediaInput extends Vue {
  @Prop({ type: Array, required: true }) oldContents!: any[]
  @Prop({ type: Number, required: true }) uploadMaxId!: number
  @Prop({ type: String }) eventtype: string | undefined
  inputType: string = ""
  newText: string = ""
  temporaryChanges: UploadMediaContent[] = []
  currUploadId: number = -1
  uploadedHint: boolean = false
  showUploadSizeDialog: boolean = false
  uploadSizeMaxMB: number = 100
  uploadSizeWarning: string = ""

  get textAdded(): boolean {
    return this.$attrs.text != null && this.$attrs.text.length > 0
  }
  get imageAdded(): boolean {
    return this.displayImages.length > 0
  }
  get videoAdded(): boolean {
    return this.displayVideos.length > 0
  }
  get audioAdded(): boolean {
    return this.displayAudios.length > 0
  }
  get displayImages(): UploadMediaContent[] {
    return this.temporaryChanges.filter((i) => i.action_id >= 0 && i.content_type.includes("image"))
  }
  get displayVideos(): UploadMediaContent[] {
    return this.temporaryChanges.filter((i) => i.action_id >= 0 && i.content_type.includes("video"))
  }
  get displayAudios(): UploadMediaContent[] {
    return this.temporaryChanges.filter((i) => i.action_id >= 0 && i.content_type.includes("audio"))
  }

  mounted(): void {
    if (this.$attrs.text != ("" || null)) this.newText = this.$attrs.text
    this.loadOldContents()
  }

  save() {
    if (this.inputType === "text") {
      this.$emit("textChange", this.newText)
    } else {
      this.$emit("fileChange", this.temporaryChanges)
      if (this.currUploadId != this.uploadMaxId) this.$emit("uploadIdChanged", this.currUploadId)
    }
    this.inputType = ""
    this.$emit("changeEnableBottomButton")
  }
  cancel() {
    this.inputType = ""
    this.loadOldContents()
  }
  fileSelected(files: File[]) {
    files.forEach((f) => {
      if (f.size / 1000000 > this.uploadSizeMaxMB) {
        if (files.length > 1)
          this.uploadSizeWarning = "" + this.$t("dialogs.upload_size.description_multiple")
        else this.uploadSizeWarning = "" + this.$t("dialogs.upload_size.description_single")

        this.showUploadSizeDialog = true
        return
      }
      let newContent: UploadMediaContent = {
        media_id: this.currUploadId,
        action_id: 1,
        url: "",
        file: f,
        content_type: this.inputType
      }

      newContent = this.readUrl(newContent)
      let existingImage = this.temporaryChanges.find((i) => {
        if (i.file)
          if (
            i.file.name === newContent.file.name &&
            i.file.lastModified == newContent.file.lastModified
          )
            return i
      })

      if (existingImage) {
        if (existingImage.action_id >= 0) {
          this.uploadedHint = true
          setTimeout(() => (this.uploadedHint = false), 3000)
        }
        existingImage.action_id = 1
        return
      }
      this.temporaryChanges.push(newContent)
      this.currUploadId--
    })
  }
  async getContent(content: UploadMediaContent): Promise<string> {
    if (content.media_id > 0) {
      const encoded = await MediaRepository.getEncodedContent("content", content.url)
      return encoded.toDataURI()
    } else return content.url
  }
  readUrl(file: UploadMediaContent): UploadMediaContent {
    const reader = new FileReader()
    reader.onload = () => {
      file.url = reader.result as string
    }
    reader.readAsDataURL(file.file)
    return file
  }
  removeContentTemporarily(mediaId: number) {
    //Call delete endpoint, if successful
    //Remove content locally
    this.temporaryChanges.forEach((i) => {
      if (i.media_id == mediaId) {
        i.action_id = -1
        if (i.file) this.temporaryChanges.splice(this.temporaryChanges.indexOf(i), 1)
      }
    })
  }

  loadOldContents() {
    const initialContents: Array<UploadMediaContent> = []
    this.temporaryChanges = []

    this.oldContents.forEach((i) =>
      initialContents.push(Object.assign({}, i) as UploadMediaContent)
    )

    initialContents.map((mediaContent) =>
      this.getContent(mediaContent).then((dataUri) =>
        this.temporaryChanges.push({ ...mediaContent, dataUri })
      )
    )
    this.currUploadId = this.uploadMaxId
  }
  isVideoCompatibleWithBrowser(video: UploadMediaContent) {
    /*       We are only supporting mp4 for any browser and mov for safari only. 
    In any other case, display of video needs to be blocked */
    const isMp4: boolean = video.url.startsWith("data:video/mp4")
    const isMov: boolean = video.url.startsWith("data:video/quicktime")
    const isSafari: boolean = this.$browserDetect.isSafari
    return isMp4 || (isMov && isSafari)
  }
}
