











import { Vue, Component } from 'vue-property-decorator'

@Component({})
export default class HintTypeChooser extends Vue {
    hint_type: string = 'all'
    mounted(){
        this.hint_type = this.$store.getters.getExperienceHintType;
    }
}
