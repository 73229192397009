import Repository from "@/api/Repository";
import { InfoText, InfoTextGroup } from "@/models/InfoText";
import MediaRepository from "./MediaRepository";

const resource = "/v1/infotext/";
class InfoTextRepository {

  async get(): Promise<Array<InfoTextGroup>> {
    const response = await Repository.get(resource);
    return response.json();
  }

  async setInfoTextImage(infoText: InfoText) : Promise<InfoText> {
    if(!infoText.image) {
      return infoText;
    }
    else {
      const blob = await MediaRepository.get('info', infoText.image);
      infoText.image = URL.createObjectURL(blob);
      return infoText;
    }
   }
}

export default new InfoTextRepository();