import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import vuetify from "./plugins/vuetify"
import i18n from "./locales/i18n"
import "@/static/css/font-style.scss"
import store from "@/store/index"
import "./registerServiceWorker"
import VueCookies from "vue-cookies"
import AuthImage from "@/utils/auth_image"
import LottieAnimation from "lottie-web-vue"

Vue.config.productionTip = false

Vue.use(VueCookies)
Vue.use(AuthImage)
Vue.use(LottieAnimation)

if (["1", "true"].includes(process.env.VUE_APP_NO_CONSOLE || "0")) {
  ;["debug", "log", "warn", "error"].forEach((type) => {
    ;(console as any)[type] = () => {}
  })
}

const app = new Vue({
  router,
  vuetify,
  i18n,
  store,
  render: (h) => h(App)
}).$mount("#app")
