





















































































import { Component, Prop, Vue } from "vue-property-decorator"
import Diagnosis from "@/models/Diagnosis"
import Clinic from "@/models/Clinic"
import DiagnosisRepository from "@/api/DiagnosisRepository"
import ClinicRepository from "@/api/ClinicRepository"
import ClinicDropdown from "@/components/dropdowns/ClinicDropdown.vue"
import DiagnosisDropdown from "@/components/dropdowns/DiagnosisDropdown.vue"

@Component({
  components: {
    DiagnosisDropdown,
    ClinicDropdown
  }
})
export default class OnboardingSelect extends Vue {
  @Prop({ type: String }) userChoice: string | undefined
  @Prop({ type: Boolean, default: false }) dontTellOption!: boolean
  @Prop({ type: Boolean, default: false }) isClinicInput!: boolean
  @Prop({ type: Boolean, default: false }) isGenderInput!: boolean

  availableDiagnoses: Diagnosis[] = []
  availableClinics: Clinic[] = []
  availableGenders: object[] = []
  chosenItem: string | null = null
  showDialog: boolean = false

  async mounted(): Promise<void> {
    if (this.isClinicInput && this.isGenderInput == false) await this.loadClinics()
    else if (this.isClinicInput == false && this.isGenderInput == false) await this.loadDiagnoses()
    else if (this.isClinicInput == false && this.isGenderInput == true) this.fillGenderOptions()

    if (this.userChoice) this.chosenItem = this.userChoice
  }
  fillGenderOptions(): void {
    this.availableGenders = [
      { id: "F", description: this.$t("F") },
      { id: "M", description: this.$t("M") },
      { id: "D", description: this.$t("D") }
    ]
  }
  async loadDiagnoses(): Promise<void> {
    try {
      this.availableDiagnoses = await DiagnosisRepository.get()
    } catch (error) {
      this.$store.dispatch("alert/error", this.$t("error_messages.diagnoses"))
      /*eslint-disable no-console*/
      console.error("Error fetching diagnoses: " + error)
    }
  }
  async loadClinics(): Promise<void> {
    try {
      this.availableClinics = await ClinicRepository.get()
    } catch (error) {
      this.$store.dispatch("alert/error", this.$t("error_messages.clinics"))
      /*eslint-disable no-console*/
      console.error("Error fetching clinics: " + error)
    }
  }
  async sumbitChosenItem(cancel: boolean): Promise<void> {
    let payload = cancel ? "" : this.chosenItem
    /* 
          if(this.mode === 'diagnosis')
              this.$emit('selectedChanged', payload);
          else if (this.mode === 'clinic')
              this.$emit('selectedChanged', payload);
          */
    this.$emit("selectedChanged", payload)
    this.$emit("nextStep")
  }
}
