


























import { Component, Prop, Vue } from 'vue-property-decorator'
import Activity from "@/models/Activity";

@Component({})
export default class ActivityInput extends Vue {
  @Prop({type: Object, default: null}) value!: Activity | null
  @Prop({type: Array, required: true}) activities!: Activity[]
  @Prop({type: String}) eventtype: string | undefined

  isSelected(activity: Activity): boolean {
    return this.value !== null && this.value.id === activity.id
  }
}
