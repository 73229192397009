



















































































































































import { Vue, Component, Watch } from "vue-property-decorator"
import SymptomTracker from "@/components/symptom/SymptomTracker.vue"
import { DurationsWrapper, FinishedDurationsWrapper, NewSymptomDuration } from "@/store/types"
import Symptom from "@/models/Symptom"
import { getTimestamp } from "@/utils/utils"
import { NewSymptomDurationPoint } from "@/store/types"
import AddPointDialog from "@/components/dialogs/AddPointDialog.vue"
import InfoDialog from "@/components/dialogs/InfoDialog.vue"
import OnbTrackingHints from "@/components/onboarding/OnbTrackingHints.vue"
import TrackingRepository from "@/api/TrackingRepository"

@Component({
  components: {
    SymptomTracker,
    AddPointDialog,
    InfoDialog,
    OnbTrackingHints
  }
})
export default class SymptomForm extends Vue {
  activeTab: number = 0
  infoTitle: string = ""
  infoText: string = ""
  disabledPanel: boolean = false
  showDiscardDialog: boolean = false
  showActiveSymptoms: boolean = true
  showInitialPointDialog: boolean = false
  showTrackingHints: boolean = false
  showInfoDialog: boolean = false
  errorMessages: string[] = []
  error: boolean = false
  temperatureValue: string = ""
  weightValue: string = ""
  openedPanels: number[] = []
  symptoms: Symptom[] = []
  selectedSymptom: Symptom | null = {} as Symptom
  createdSymptom: Symptom | null = {} as Symptom

  @Watch("trackingHintStatus")
  onTrackingHintStatusChange() {
    this.showTrackingHints = this.trackingHintStatus
  }

  get finishedSymptoms(): FinishedDurationsWrapper[] {
    return this.$store.state.symptom.finishedSymptomDurations
  }
  get trackingHintStatus(): boolean {
    return this.$store.getters.getOnboardingSetting("tracking_hints")
  }
  get activeSymptoms(): DurationsWrapper[] {
    return this.$store.getters.activeDurations
  }

  async created(): Promise<void> {
    await this.$store.dispatch("loadSymptoms")
    this.symptoms = this.$store.state.symptom.symptoms

    await this.$store.dispatch("loadActiveSymptomDurations")

    await this.$store.dispatch("loadFinishedSymptoms")
  }
  mounted(): void {
    this.showTrackingHints = this.$store.getters.getOnboardingSetting("tracking_hints")
  }
  disableLoadMore(pageNumber: number): boolean {
    if (pageNumber > 1) return false
    return true
  }
  async loadFirstDurations(
    symptom: number,
    panel_index: number,
    next_page_number: number
  ): Promise<void> {
    if (this.finishedSymptoms[panel_index].finsihedSymptomDurations.length == 0) {
      this.loadMoreDurations(symptom, panel_index, next_page_number)
    }
  }
  async loadMoreDurations(
    symptom: number,
    panel_index: number,
    next_page_number: number
  ): Promise<void> {
    if (next_page_number > 0) {
      await this.$store.dispatch("loadFinishedSymptomDurations", {
        symptom: symptom,
        pageNumber: next_page_number
      })

      // load first points for all finished symptoms
      for (const finSymp of this.finishedSymptoms[panel_index].finsihedSymptomDurations) {
        if (finSymp.symptomDurationPoints.length === 0) {
          await this.loadDurationPoints(finSymp.symptomDuration.id, finSymp.nextPageNumber)
        }
      }
    }
  }
  async loadDurationPoints(duration: Number, next_page_number: Number): Promise<void> {
    if (next_page_number > 0) {
      await this.$store.dispatch("loadSymptomDurationPoints", {
        symptom_duration: duration,
        nextPageNumber: next_page_number,
        is_active: false
      })
    }
  }
  async showHelp(symptom: any): Promise<void> {
    this.infoTitle = symptom.name
    this.infoText = symptom.description
    this.showInfoDialog = true
    const groupID = "TRAC:"
    await TrackingRepository.createLog(groupID + "Hilfe zu Symptom " + symptom.name + " aufgerufen")
  }
  async addSymptom(symptom: Symptom): Promise<void> {
    this.createdSymptom = this.selectedSymptom
    this.error = false
    this.errorMessages = []

    // check if no symptom selected
    if (symptom == undefined) {
      this.errorMessages.push("Bitte ein Symptom wählen")
      this.error = true
      return
    }

    // check if symptom already active
    Object.keys(this.activeSymptoms).forEach((key: string) => {
      if (
        this.activeSymptoms[parseInt(key)].symptomDuration.symptom.id ==
        (this.selectedSymptom as Symptom).id
      ) {
        this.errorMessages.push("Dieses Symptom ist bereits aktiv")
        this.error = true
      }
    })

    if (this.error === false) {
      this.showInitialPointDialog = true
    } else {
      //clear searchfield if there is an error
      this.selectedSymptom = null
    }
  }
  async addSymptomPoint(data: { date: string; time: string; value: number }): Promise<void> {
    this.showInitialPointDialog = false

    const newSymptom: NewSymptomDuration = {
      symptom: this.selectedSymptom!.id,
      symptom_start: getTimestamp(data.date, data.time)
    }

    await this.$store.dispatch("addActiveSymptomDuration", newSymptom).then(
      (promise) => {
        const point: NewSymptomDurationPoint = {
          symptom_duration: promise.id,
          datetime: getTimestamp(data.date, data.time),
          value: data.value
        }

        // nextPage is '-1‘, because this method is only used to add the first point
        this.$store.dispatch("addSymptomDurationPoint", {
          new_point: point,
          symptom_id: this.selectedSymptom!.id,
          nextPageNumber: -1
        })

        const groupID = "TRAC:"
        TrackingRepository.createLog(
          groupID + "Symptom " + this.selectedSymptom!.name + " angelegt"
        )
        TrackingRepository.createLog(
          groupID +
            "Messpunkt zu " +
            this.selectedSymptom!.name +
            " hinzufügen mit Wert " +
            point.value +
            " am " +
            point.datetime
        )
      },
      (error) => {}
    )

    this.selectedSymptom = null
  }
  async changeTab(): Promise<void> {
    const groupID = "TRAC:"

    if (this.activeTab === 0) {
      this.showActiveSymptoms = true
      await TrackingRepository.createLog(groupID + "Klick auf aktive Symptome")
    } else {
      this.showActiveSymptoms = false
      await TrackingRepository.createLog(groupID + "Klick auf beendete Symptome")
    }
  }
  // signal from SymptomTracker, to start a new tracker like the one clicked
  startSameTracker(symptom: Symptom): void {
    this.selectedSymptom = symptom
    this.activeTab = 0
    this.showActiveSymptoms = true
    this.addSymptom(symptom)
  }
}
