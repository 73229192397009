







import { Component, Vue } from "vue-property-decorator"
import UserProfileOverlay from "@/components/overlays/UserProfileOverlay.vue"
import CreateEventOverlay from "@/components/overlays/CreateEventOverlay.vue"

@Component({
  components: {
    UserProfileOverlay,
    CreateEventOverlay
  }
})
export default class InAppOverlay extends Vue {
  get isUserProfileOverlayDisplayed(): boolean {
    return this.$store.getters.getIsUserProfileOverlayDisplayed
  }
  get isCreateEventOverlayDisplayed(): boolean {
    return this.$store.getters.getIsCreateEventOverlayDisplayed
  }
}
