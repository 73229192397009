
















import { Vue, Component } from 'vue-property-decorator'
import { ChainEvent, renderEventChain, EventChain } from "@/components/eventchain/eventchain";
import EventRepository from '@/api/EventRepository';
import { ChainableEvent } from '@/models/Event';
import ShowEvent from "@/views/ShowEvent.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import { Route, NavigationGuardNext } from 'vue-router'
const ICONS = require("@/static/css/_icon_variables.scss");

const EVENT_COLORS = {
    'clinic': '#577DB3',
    'home': '#5BA4AE'
} as Record<string, string>;

function mapToChainEvent(event: ChainableEvent): ChainEvent {
    const chainable = (event as any) as ChainEvent;
    chainable.color = EVENT_COLORS[event.location];
    chainable.icon = ICONS[event.icon].substring(1, 2)
    return chainable;
}

@Component({
    components: {
        LoadingSpinner,
        ShowEvent
    }
})
export default class EventChainComponent extends Vue {
    isLoading: boolean = true
    chain: EventChain | null = null
    showEvent: boolean = false
    showEventId: number | null = null

    async mounted(): Promise<void> {
        try {
            const events = await EventRepository.getChainable();
            const chainEvents = events.map(mapToChainEvent);
            this.chain = renderEventChain(this.$refs.chaincanvas as HTMLCanvasElement, chainEvents);
            this.chain.addEventListener('eventclick', (e: any) => {
                this.showEventId = +e.detail;
                this.showEvent = true;
            });
        } catch (err) {
            /* eslint-disable no-console */
            console.error('unable to fetch events for chain', err);
        } finally {
            this.isLoading = false;
        }
    }
    beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext): any {
        if(this.chain) {
            this.chain.stop(); // Cancel any calculations
        }
        next();
    }
    closeDialog() {
        this.showEvent = false
        this.showEventId = null
    }
}
