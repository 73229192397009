



















import { Component, Watch, Vue } from "vue-property-decorator"
import { AssetHelper } from "@/utils/AssetHelper"
import { Notification } from "@/models/Notification"
import NotificationHelper from "@/utils/NotificationHelper"

@Component({})
export default class BasicNotification extends Vue {
  notificationHelper: NotificationHelper | null = null

  get mostCurrentNotification(): Notification | null {
    const isBannerAllowedToBeLoaded = this.$store.getters.getIsNotificationBannerReadyForDisplay
    return isBannerAllowedToBeLoaded ? this.$store.getters.getMostRecentBannerNotification : null
  }

  get notificationText(): string {
    return this.notificationHelper?.getNotificationText() || ""
  }

  get notificationButton(): string | null {
    return this.notificationHelper?.getNotificationButtonText() || null
  }

  get notificationIcon(): NodeRequire {
    return AssetHelper.getCompanionIcon()
  }

  @Watch("mostCurrentNotification")
  onMostCurrentNotificationChange(mostCurentNotification: Notification | null): void {
    if (mostCurentNotification) {
      this.$store.dispatch("displayBannerNotification")
      this.notificationHelper = new NotificationHelper(mostCurentNotification)
    }
  }

  accept(): void {
    // do some custom stuff based on notification
    // ackknowledge notification
    this.notificationHelper?.triggerAccept()
    this.$store.dispatch("markNotificationAsSeen", this.notificationHelper?.notification)
    this.$store.dispatch("closeBannerNotification")
    this.notificationHelper = null
  }

  close(): void {
    this.$store.dispatch("markNotificationAsSeen", this.notificationHelper?.notification)
    this.$store.dispatch("closeBannerNotification")
    this.notificationHelper = null
  }
}
