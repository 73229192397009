export function passwordValidators($t: any): any[] {
  return [
    (v: string) => !!v || $t("password_validate.empty"),
    (v: string) => /^(?=.*[0-9]).*$/.test(v) || $t("password_validate.missing_digit"),
    (v: string) => /^(?=.*[a-zöäü]).*$/.test(v) || $t("password_validate.missing_lower"),
    (v: string) => /^(?=.*[A-ZÖÄÜ]).*$/.test(v) || $t("password_validate.missing_upper"),
    (v: string) =>
      /^(?=.*[~!@#$%^&*()_+{}":;=€'[\]]).*$/.test(v) || $t("password_validate.missing_special"),
    (v: string) => v.length >= 9 || $t("password_validate.too_short"),
    (v: string) => /^\S*$/.test(v) || $t("password_validate.contains_whitespace")
  ]
}

export function passwordConfirmValidators(comparing: string, $t: any): any[] {
  return [
    (v: string) => !!v || $t("password_validate.empty"),
    (v: string) => v === comparing || $t("password_validate.mismatch")
  ]
}
