

















import { Component } from 'vue-property-decorator'
import VueOnbHint from './VueOnbHint'
import Speechbubble from "@/components/onboarding/Speechbubble.vue";

@Component({
  components: {
    Speechbubble
  }
})
export default class OnbStartpageHints extends VueOnbHint {
  // TODO , "yc_nav_play" removed and replace with , "yc_nav_tracking"
  hintOrder: string[] = [
    "startpage_general",
    "startpage_add",
    "startpage_search",
    "startpage_experience",
    "information",
    "tracking",
    "chat",
    "social_general",
  ]
  hintClasses: string[] = [
    "yc_nav_home",
    "add-button",
    "search-button",
    "experience-button",
    "yc_nav_info",
    "yc_nav_tracking",
    "yc_nav_chat",
    "yc_nav_social",
  ]
  cssClass: string = "yc_onb_hint_circle"
  onboardingSetting: object = {startpage_hints: false}

  mounted() {
    this.changeFixedNav(true);
    this.disableAnchors(true);
    this.setHighlight();
  }

  beforeDestroy() {
    this.changeFixedNav(false);
    this.disableAnchors(false);
    this.removeAllHighlights();
  }
}
