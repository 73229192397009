import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"text-left px-sm-10"},[_c(VCardSubtitle,{staticClass:"text-center achievmentTitle"},[_vm._v(" "+_vm._s(_vm.$t("achievements.your_achievements"))+" ")]),_c(VList,_vm._l((_vm.achievementStages),function(name,possibleAchievement,index){return _c(VListItem,{key:index},[_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(_vm.$t('achievements.titles.'+possibleAchievement)))]),_c(VListItemSubtitle,[_vm._v(_vm._s(_vm.$t('achievements.explanations.'+possibleAchievement)))]),_c(VRow,[_vm._l((_vm.getAchievements(possibleAchievement)),function(gainedAchievement,index){return _c('div',{key:index + gainedAchievement.timestamp,staticClass:"text-center yc_achievement"},[_c('div',[_c('AchievementIcon',{attrs:{"level":index + 1,"icon":possibleAchievement}}),_c('br')],1)])}),(_vm.calculateNextAchievementStep(possibleAchievement) > 0)?_c('div',{staticClass:"text-center yc_achievement yc_achievement_disabled"},[_c('AchievementIcon',{attrs:{"level":0,"icon":possibleAchievement,"goalNumber":_vm.calculateNextAchievementStep(possibleAchievement),"goalName":_vm.$t('achievements.units.'+possibleAchievement)}}),_c('br')],1):_vm._e()],2),_c(VDivider,{staticClass:"mt-5"})],1)],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }