import Repository from "./Repository";
import Clinic from '@/models/Clinic';

const resource = "/v1/clinics/";

class ClinicRepository {
  async get(): Promise<Clinic[]> {
    const response = await Repository.get(resource);
    const clinics = await response.json();
    clinics.sort(function (a:Clinic, b:Clinic) {
      return a.id - b.id;
    });
    return clinics;
  }
}

export default new ClinicRepository();