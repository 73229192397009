








































import { Component, Vue } from "vue-property-decorator"
import MoodChart from "@/components/tracking/MoodChart.vue"
//import SymptomChart from "@/components/tracking/SymptomChart.vue"
import TimeSpanSelection from "@/components/tracking/TimeSpanSelection.vue"
import SymptomForm from "./SymptomForm.vue"

interface IDataDayRange {
  value: number
  text: string
}

@Component({
  components: {
    MoodChart,
    //SymptomChart,
    TimeSpanSelection,
    SymptomForm
  }
})
export default class Tracking extends Vue {
  tab: number = 0 // used as v-model for the tab navigation
  timespan = 30 // use one of the values in timeRanges array
  timeRanges: IDataDayRange[] = [
    { value: 7, text: <string>this.$t("date_range.days", { days: 7 }) },
    { value: 30, text: <string>this.$t("date_range.days", { days: 30 }) },
    { value: 90, text: <string>this.$t("date_range.days", { days: 90 }) },
    { value: 365, text: <string>this.$t("date_range.days", { days: 365 }) },
    { value: 730, text: <string>this.$t("date_range.years", { years: 2 }) }
  ]
}
