


















import { Vue, Component, Prop } from "vue-property-decorator"
import ProgressMarker from "@/models/ProgressMarker"

@Component({})
export default class ProgressMarkerInfo extends Vue {
  @Prop({ type: Object, required: true }) marker!: ProgressMarker
  @Prop({ type: String, default: "top" }) location!: string
  visible: boolean = false

  get texts(): { [key: string]: string } {
    const result: any = {}
    const messages: any = this.$i18n.messages[this.$i18n.locale].progressmarker_info
    for (const key of Object.keys(this.marker.info)) {
      const value: string = (this.marker.info as any)[key]
      result[messages[key].text] = this.getMessage(messages, key, value)
    }
    return result
  }

  get textsKeys(): string[] {
    // This is future-proof, so instead of just returning the order array that would work for mountains only we create the correct order for different types coming later
    const order = ["type", "mountain", "country", "continent"]
    const keys = Object.keys(this.marker.info)
    const result: string[] = []
    const messages: any = this.$i18n.messages[this.$i18n.locale].progressmarker_info
    order
      .filter((x) => keys.includes(x))
      .forEach((x) => result.push(this.getMessage(messages, x, "text")))
    return result
  }

  get _location(): string {
    const accept = ["top", "bottom"]
    if (!accept.includes(this.location.toLowerCase())) return accept[0]
    return this.location.toLowerCase()
  }

  getMessage(messages: any, key0: string, key1: string | string[]): string {
    if (Array.isArray(key1)) {
      let _message = ""
      for (const _input of key1) _message += this.getMessage(messages, key0, _input) + ", "
      return _message.length > 2 ? _message.substring(0, _message.length - 2) : _message
    }
    if (messages[key0][key1]) {
      return messages[key0][key1]
    }
    return key1
  }
}
