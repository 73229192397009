import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"yc_eventform_div"},[_c('h3',[_vm._v(_vm._s(_vm.$t("new_event_activity")))]),_c(VContainer,{staticClass:"yc_eventform_cards"},[_c(VRow,{attrs:{"justify":"center"}},_vm._l((_vm.categories),function(category){return _c(VCol,{key:category.id,staticClass:"col-sm-4 col-md-3 col-lg-3 col-xl-2",attrs:{"data-cy":"catagoryinputdetails","cols":6}},[_c(VCard,{staticClass:"yc_eventform_card",class:[
            { yc_eventform_card_selected: _vm.oldCat == category.id },
            _vm.eventtype === 'clinic' ? 'clinic_event' : 'home_event'
          ],attrs:{"outlined":"","raised":""},on:{"click":function($event){_vm.$emit('input', category.id), _vm.$emit('nextStep')}}},[_c('div',{staticClass:"yc_card_vertical_center"},[_c('i18n',{attrs:{"path":'categories.' + category.description}},[_vm._v("­")])],1)])],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }