import Vue from "vue"
import Vuex, { StoreOptions } from "vuex"
import { userModule as user } from "@/store/modules/user"
import { eventModule as event } from "@/store/modules/event"
import relation from "@/store/modules/relation"
import alert from "@/store/modules/alert"
import account from "@/store/modules/account"
import chat from "@/store/modules/chat"
import application from "@/store/modules/application"
import settings from "@/store/modules/settings"
import { moodModule as mood } from "@/store/modules/mood"
import { symptomModule as symptom } from "@/store/modules/symptom"
import { tagModule as tag } from "@/store/modules/tag"
import { infoTextModule as infoText } from "@/store/modules/infoText"
import connection from "@/store/modules/connection"
import experience from "@/store/modules/experience"
import { notificationModule as notification } from "@/store/modules/notification"
import { overlayModule as overlay } from "./modules/overlay"
import { RootState } from "./types"
import mobile from "@/store/modules/mobile"

Vue.use(Vuex)

const store: StoreOptions<RootState> = {
  modules: {
    user,
    event,
    relation,
    alert,
    account,
    chat,
    application,
    settings,
    mood,
    connection,
    experience,
    symptom,
    tag,
    infoText,
    notification,
    overlay,
    mobile
  }
}

export default new Vuex.Store<RootState>(store)
