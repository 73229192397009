/* eslint-disable no-console */
export interface ChainEvent {
  id: number
  icon: string
  color: string
}

export function renderEventChain(canvas: HTMLCanvasElement, events: ChainEvent[]): EventChain {
  const chain = new EventChain(canvas, events)
  chain.initCanvas()
  chain.draw()
  return chain
}

const BACKGROUND = "white"
const CENTER_COLOR = "#C4E698"
const SPIRAL_COLOR = "#D5DDDD"
const EVENT_RADIUS = 30
const PINCH_TO_ZOOM_FACTOR = 0.05
const SCALING_FACTOR = 1.1
const MAX_ZOOM = 3
const WHEEL_SENSITIVITY = 0.005

let DEBUG = false

export class EventChain extends EventTarget {
  private center: Coordinates = {
    x: 0,
    y: 0
  }

  private offset = {
    top: 0,
    left: 0
  }

  private isTrackpad: boolean | null = null
  private mouse = {
    x: undefined as undefined | number,
    y: undefined as undefined | number,
    down: false,
    dragStart: {
      x: 0,
      y: 0
    },
    click: {
      time: 0,
      x: undefined as undefined | number,
      y: undefined as undefined | number
    }
  }

  private zoom = {
    scale: 1,
    x: 0,
    y: 0,
    lastScale: 0,
    minscale: 1
  }
  private spiral = {} as Spiral
  private eventBubbles = [] as EventBubble[]
  private centerBubble = {} as Drawable

  private active = false

  private ctx: CanvasRenderingContext2D
  constructor(private canvas: HTMLCanvasElement, private events: ChainEvent[]) {
    super()
    const context = canvas.getContext("2d")
    if (context !== null) {
      this.ctx = context
    } else {
      throw new Error("Could not retrieve canvas 2d context")
    }
  }

  initCanvas() {
    this.registerListener()
    this.reset()
    this.active = true
  }

  stop() {
    this.active = false
  }

  initChain() {
    this.eventBubbles = []

    // CENTER POINT
    this.centerBubble = new Drawable(this.center, CENTER_COLOR)

    // COORDS OF LAST EVENT
    const lastEvent = {
      ...this.center
    }

    // SPIRAL DATA
    const spiralCoords = []
    let spiralRadius = 0
    let angle = 0

    for (let n = 0; this.eventBubbles.length < this.events.length; n++) {
      // CALCULATE SPIRAL
      spiralRadius += 0.8
      angle += (Math.PI * 2) / 100
      angle = angle % 360
      const x = this.center.x + spiralRadius * -Math.cos(angle)
      const y = this.center.y + spiralRadius * -Math.sin(angle)

      spiralCoords.push({ x: x, y: y })

      // CHECK FOR EVENT TO RENDER (PREVENT OVERLAP)

      const dX = lastEvent.x - x
      const dY = lastEvent.y - y
      const delta = Math.floor(Math.sqrt(Math.pow(dX, 2) + Math.pow(dY, 2)))

      if (delta > EVENT_RADIUS * 2.3) {
        const event = new EventBubble(x, y, EVENT_RADIUS, this.events[this.eventBubbles.length])
        this.eventBubbles.push(event)
        lastEvent.x = x
        lastEvent.y = y
      }
    }

    this.spiral = new Spiral(this.center.x, this.center.y, spiralCoords, SPIRAL_COLOR, spiralRadius)
    this.fitSpiral()
  }

  draw() {
    if (!this.active) return

    this.clear()

    // RENDER SPIRAL
    this.spiral.update(this.ctx)
    // RENDER CENTER
    this.centerBubble.update(this.ctx)
    // RENDER EVENTS
    this.eventBubbles.forEach((e) => {
      e.update(this.ctx)
    })

    if (DEBUG) this.printDebug()

    window.requestAnimationFrame(this.draw.bind(this))
  }

  private registerListener() {
    window.addEventListener("keypress", (e) => {
      if (e.shiftKey && e.code === "KeyD") {
        DEBUG = !DEBUG
      }
    })
    //window.addEventListener('resize', this.reset.bind(this), false);

    this.canvas.addEventListener("mousedown", this.mouseDown.bind(this))
    this.canvas.addEventListener("mouseup", this.mouseUp.bind(this))
    this.canvas.addEventListener("mouseleave", this.mouseUp.bind(this))
    this.canvas.addEventListener("mousemove", this.moveDrag.bind(this))
    this.canvas.addEventListener("wheel", this.handleScroll.bind(this))
    this.canvas.addEventListener("touchstart", (e) => {
      e.preventDefault()
      this.zoom.lastScale = 0
      this.dispatchMouseEvent("mousedown", e)
    })
    this.canvas.addEventListener("touchend", (e) => {
      e.preventDefault()
      this.dispatchMouseEvent("mouseup", e)
    })

    this.canvas.addEventListener("touchmove", (e) => {
      e.preventDefault()
      if (e.targetTouches.length == 2) {
        this.pinchZoom(e)
      } else if (e.targetTouches.length == 1) {
        this.dispatchMouseEvent("mousemove", e)
      }
    })
  }

  private dispatchMouseEvent(name: string, e: TouchEvent) {
    const touch = e.targetTouches[0] || e.changedTouches[0]
    const mouseEvent = new MouseEvent(name, {
      clientX: touch.clientX,
      clientY: touch.clientY
    })
    this.canvas.dispatchEvent(mouseEvent)
  }

  private calculateZoom(delta: number, x: number, y: number) {
    const zoomFactor = Math.pow(SCALING_FACTOR, delta)
    const mouseX = x - this.offset.left
    const mouseY = y - this.offset.top

    this.doZoom(zoomFactor, mouseX, mouseY)

    if (round(this.zoom.scale, 3) == round(this.zoom.minscale, 3)) {
      // IF ZOOMED OUT TO INITIAL VALUE, RESET CANVAS TO CENTER
      //this.reset();
    }
  }

  private doZoom(factor: number, x: number, y: number) {
    if (this.zoom.scale * factor < this.zoom.minscale) {
      factor = this.zoom.minscale / this.zoom.scale // LIMIT ZOOM OUT
    }
    if (this.zoom.scale * factor > MAX_ZOOM) {
      factor = MAX_ZOOM / this.zoom.scale // LIMIT ZOOM IN
    }

    const newZoomX = x / this.zoom.scale + this.zoom.x - x / (this.zoom.scale * factor)
    const newZoomY = y / this.zoom.scale + this.zoom.y - y / (this.zoom.scale * factor)

    this.ctx.translate(this.zoom.x, this.zoom.y)
    this.ctx.scale(factor, factor)
    this.ctx.translate(-newZoomX, -newZoomY)

    this.zoom.x = newZoomX
    this.zoom.y = newZoomY
    this.zoom.scale *= factor
  }

  private reset() {
    this.resizeCanvas()
    this.initChain()
  }

  private resizeCanvas() {
    const sizeY = window.innerHeight - this.canvas.getBoundingClientRect().top
    this.canvas.style.width = "100%"
    this.canvas.style.height = `${sizeY}px`
    this.canvas.width = this.canvas.offsetWidth
    this.canvas.height = this.canvas.offsetHeight
    this.resetMeta()
  }

  private resetMeta() {
    this.center.x = this.canvas.width / 2
    this.center.y = this.canvas.height / 2
    this.offset.top = this.canvas.offsetTop
    this.offset.left = this.canvas.offsetLeft
    this.zoom.scale = 1
    this.zoom.x = 0
    this.zoom.y = 0
  }

  private clear() {
    // SAVE TRANSLATIONS (DRAGGING)
    this.ctx.save()
    // RESET CONTEXT
    this.ctx.resetTransform()
    // DRAW BACKGROUND
    this.ctx.fillStyle = BACKGROUND
    this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height)
    // RESTORE TRANSLATIONS
    this.ctx.restore()
  }

  private getEventCoords(event: MouseEvent, scale = true) {
    return {
      x: (event.x - this.offset.left) / (scale ? this.zoom.scale : 1),
      y: (event.y - this.offset.top) / (scale ? this.zoom.scale : 1)
    }
  }

  private pointerMove(event: TouchEvent) {
    event.preventDefault()
    this.pinchZoom(event)
  }

  private mouseDown(event: MouseEvent) {
    event.preventDefault()
    this.mouse.down = true
    this.mouse.dragStart = this.getEventCoords(event)
    this.mouse.click.time = Date.now()
  }

  private mouseUp(event: MouseEvent) {
    event.preventDefault()
    this.mouse.down = false

    const timing = Date.now() - this.mouse.click.time

    if (event.type !== "mouseleave" && timing < 150) {
      this.handleClick(event)
    }
  }

  private handleClick(event: MouseEvent) {
    // only allow left clicks
    if (event.button !== 0) return

    const rect = this.canvas.getBoundingClientRect()
    const position = this.translatedPoint({
      x: event.clientX - rect.left,
      y: event.clientY - rect.top
    })
    this.mouse.click = { ...this.mouse.click, ...position }

    const bubbleClicked = this.eventBubbles.find(
      (event) => calcDistance(event.pos, position) < event.radius
    )

    if (bubbleClicked) {
      this.dispatchEvent(new CustomEvent("eventclick", { detail: bubbleClicked.event.id }))
    }
  }

  private moveDrag(event: MouseEvent) {
    event.preventDefault()
    this.mouse = { ...this.mouse, ...this.getEventCoords(event, false) }

    if (this.mouse.down) {
      const dragEnd = this.getEventCoords(event)
      let deltaX = dragEnd.x - this.mouse.dragStart.x
      let deltaY = dragEnd.y - this.mouse.dragStart.y

      this.ctx.translate(deltaX, deltaY)
      this.mouse.dragStart = dragEnd

      // TRANSLATE ZOOM ANCHOR
      this.zoom.x -= deltaX
      this.zoom.y -= deltaY
    }
  }

  private detectTrackpad(event: WheelEvent) {
    //this.isTrackpad = isTrackpad(event);
    this.isTrackpad = false
  }
  private handleScroll(event: WheelEvent) {
    if (this.isTrackpad == null) {
      this.detectTrackpad(event)
    }
    if (!this.isTrackpad) {
      const deltaY = event.deltaY * WHEEL_SENSITIVITY
      const zoomScale = deltaY ? -deltaY / 1.2 : event.detail ? -event.detail : 0
      if (zoomScale) this.calculateZoom(zoomScale, event.x, event.y)
    }
  }

  private pinchZoom(event: TouchEvent) {
    event.preventDefault()
    let zoomScale = 0
    const t0 = event.targetTouches[0]
    const t1 = event.targetTouches[1]

    const deltaX = t1.pageX - t0.pageX
    const deltaY = t1.pageY - t0.pageY
    const newZoomScale = Math.sqrt(Math.pow(deltaX, 2) + Math.pow(deltaY, 2))

    if (this.zoom.lastScale) {
      zoomScale = newZoomScale - this.zoom.lastScale
    }

    this.zoom.lastScale = newZoomScale

    this.calculateZoom(
      zoomScale * PINCH_TO_ZOOM_FACTOR,
      t0.pageX + deltaX / 2,
      t0.pageY + deltaY / 2
    )
  }

  private translatedPoint(point: Coordinates) {
    let invMat
    if (this.ctx.getTransform) {
      invMat = this.ctx.getTransform().inverse()
    } else {
      // WORKAROUND FOR MOZILLA ANDROID
      const moz = (this.ctx as any).mozCurrentTransformInverse
      invMat = {
        a: moz[0],
        b: moz[1],
        c: moz[2],
        d: moz[3],
        e: moz[4],
        f: moz[5]
      }
    }
    return {
      x: point.x * invMat.a + point.y * invMat.c + invMat.e,
      y: point.x * invMat.b + point.y * invMat.d + invMat.f
    }
  }

  private distanceDragged() {
    const wC = this.translatedPoint(this.center)
    return {
      x: (wC.x - this.center.x) * this.zoom.scale,
      y: (wC.y - this.center.y) * this.zoom.scale
    }
  }

  private printDebug() {
    const lines = []
    lines.push(`spiral r: ${round(this.spiral.radius * this.zoom.scale)}`)
    lines.push("---")
    lines.push(`c width: ${this.canvas.width}`)
    lines.push(`c height: ${this.canvas.height}`)
    lines.push("---")

    const wC = this.translatedPoint(this.center)

    lines.push(`wX: ${round(wC.x)}`)
    lines.push(`wY: ${round(wC.y)}`)
    lines.push("---")
    lines.push(`x: ${round(this.center.x)}`)
    lines.push(`y: ${round(this.center.y)}`)

    const dragged = this.distanceDragged()

    lines.push("---")
    lines.push(`drag x: ${round(dragged.x, 2)}`)
    lines.push(`drag y: ${round(dragged.y, 2)}`)

    this.ctx.save()
    this.ctx.beginPath()

    this.ctx.font = "16px mono"
    this.ctx.fillStyle = "red"
    this.ctx.textAlign = "center"
    this.ctx.textBaseline = "middle"
    this.ctx.fillText("x", wC.x, wC.y)
    this.ctx.fillText("o", this.center.x, this.center.y)

    this.ctx.beginPath()
    this.ctx.fillStyle = "rgba(0,255,0,0.1)"
    const ey = [...this.eventBubbles.map((a) => a.pos.y - EVENT_RADIUS)]
    const ex = [...this.eventBubbles.map((a) => a.pos.x - EVENT_RADIUS)]
    const rx = Math.min(...ex),
      ry = Math.min(...ey),
      rw = Math.max(...ex.map((a) => a - rx + EVENT_RADIUS * 2)),
      rh = Math.max(...ey.map((a) => a - ry + EVENT_RADIUS * 2))
    this.ctx.fillRect(rx, ry, rw, rh)

    this.ctx.resetTransform()
    this.ctx.beginPath()

    this.ctx.fillStyle = "#00000066"
    this.ctx.fillRect(5, 5, 300, 15 + lines.length * 16)
    this.ctx.fillStyle = "white"
    this.ctx.textAlign = "left"

    for (let i = 0; i < lines.length; i++) this.ctx.fillText(lines[i], 10, 20 + i * 16)

    this.ctx.restore()
  }

  private fitSpiral() {
    const zoomout =
      Math.min(this.canvas.width, this.canvas.height) / (2.2 * this.spiral.radius + EVENT_RADIUS)
    this.zoom.minscale = zoomout
    this.doZoom(Math.min(zoomout, 1), this.center.x, this.center.y)
  }
}

interface Coordinates {
  x: number
  y: number
}

class Drawable {
  constructor(public pos: Coordinates, protected color: string) {}

  draw(ctx: CanvasRenderingContext2D) {
    ctx.beginPath()
    ctx.fillStyle = this.color
    ctx.arc(this.pos.x, this.pos.y, 10, 0, Math.PI * 2)
    ctx.shadowColor = "#9E9E9E"
    ctx.shadowBlur = 2
    ctx.fill()
    ctx.shadowBlur = 0
  }

  update(ctx: CanvasRenderingContext2D) {
    this.draw(ctx)
  }
}

class EventBubble extends Drawable {
  constructor(x: number, y: number, public radius: number, public event: ChainEvent) {
    super({ x: x, y: y }, event.color)
  }

  draw(ctx: CanvasRenderingContext2D) {
    ctx.beginPath()

    ctx.fillStyle = this.color
    ctx.arc(this.pos.x, this.pos.y, this.radius, 0, Math.PI * 2)
    ctx.shadowColor = "#9E9E9E"
    ctx.shadowBlur = this.radius * 0.2
    ctx.fill()
    ctx.shadowBlur = 0

    ctx.fillStyle = "white"
    ctx.font = this.radius + "px youcanico"
    ctx.textBaseline = "middle"
    ctx.textAlign = "center"
    ctx.fillText(this.event.icon, this.pos.x, this.pos.y)

    if (DEBUG) {
      ctx.font = "16px mono"
      ctx.fillStyle = "red"
      ctx.textAlign = "center"
      ctx.textBaseline = "middle"
      ctx.fillText("x", this.pos.x, this.pos.y)

      ctx.font = "8px mono"
      ctx.fillStyle = "black"
      ctx.textAlign = "center"
      ctx.textBaseline = "middle"
      ctx.fillText(`${round(this.pos.x)}, ${round(this.pos.y)}`, this.pos.x, this.pos.y + 8)
    }
  }
}

class Spiral extends Drawable {
  constructor(
    x: number,
    y: number,
    private coords: Coordinates[],
    color: string,
    public radius: number
  ) {
    super({ x: x, y: y }, color)
  }

  draw(ctx: CanvasRenderingContext2D): void {
    ctx.beginPath()
    ctx.moveTo(this.pos.x, this.pos.y)
    for (const c of this.coords) {
      ctx.lineTo(c.x, c.y)
    }
    ctx.lineWidth = 2
    ctx.strokeStyle = this.color
    ctx.stroke()
  }
}

function round(num: number, decimals = 0) {
  const f = Math.pow(10, decimals)
  return Math.round(num * f) / f
}

function calcDistance(a: Coordinates, b: Coordinates) {
  return Math.abs(Math.sqrt(Math.pow(a.x - b.x, 2) + Math.pow(a.y - b.y, 2)))
}

function isTrackpad(e: any) {
  let isTrackpad = false
  if (e.wheelDeltaY) {
    if (e.wheelDeltaY === e.deltaY * -3) {
      isTrackpad = true
    } else if (e.deltaMode === 0) {
      isTrackpad = true
    }
  }
  return isTrackpad
}
