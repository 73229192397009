



































import Clinic from "@/models/Clinic";
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({})
export default class ClinicDropdown extends Vue {
  @Prop({type: Object, required: true}) value!: Clinic
  @Prop({type: Array, required: true}) clinicList!: Clinic[]
  @Prop({type: Boolean, default: false}) showPlaceholder!: boolean
  @Prop({type: String, default: ''}) placeholderText!: string

  get inputVal(): Clinic {
    return this.value
  }
  set inputVal(val: Clinic) {
    this.$emit('input', val)
  }
  get sortedClinics(): Clinic[] {
      const arr = this.clinicList;
      const arrNonNullAddress = arr.filter((clinic: Clinic) => clinic.address);
      const arrNullAddress = arr.filter((clinic: Clinic) => !clinic.address);
      arrNonNullAddress.sort((a: Clinic, b: Clinic) =>
        // @ts-ignore
        a.address.localeCompare(b.address)
      );
      return arrNonNullAddress.concat(arrNullAddress);
    }
}
