import { getAuthToken } from "@/utils/jwt_interceptor"
import { requestRefresh } from "@/api/LoginRepository";
import { parseParams } from "@/utils/utils";

const baseDomain = process.env.VUE_APP_API_HOST;
export const BASE_URL = `${baseDomain}/api`;

class Repository {

  constructor(private readonly authDecorator: (request: Request) => Promise<Request>){}

  async get(uri: string, queryParams?: object, args: RequestInit = {method: 'get'}): Promise<Response> {
    return this.getByRequest(buildRequest(uri, args, queryParams));
  }

  async getByRequest(request: Request): Promise<Response> {
    const decorated = await this.authDecorator(request);
    return await executeFetch(decorated);
  }

  async post(
    uri: string,
    body: any,
    queryParams?: object,
    args: RequestInit = {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json'
      }
    }
  ): Promise<Response> {
    const request = await this.authDecorator(buildRequest(uri, args, queryParams));
    return await executeFetch(request);
  }

  async delete(uri: string, queryParams?: object, args: RequestInit = {method: 'delete'}): Promise<Response> {
    const request = await this.authDecorator(buildRequest(uri, args, queryParams));
    return await executeFetch(request);
  }

  async put(
    uri: string,
    body: any,
    queryParams?: object,
    args: RequestInit = {
      method: 'PUT',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json'
      }
    }
  ): Promise<Response> {
    const request = await this.authDecorator(buildRequest(uri, args, queryParams));
    return await executeFetch(request);
  }

  async patch(
    uri: string,
    body: any,
    queryParams?: object,
    args: RequestInit = {
      method: 'PATCH',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json'
      }
    }
  ): Promise<Response> {
    const request = await this.authDecorator(buildRequest(uri, args, queryParams));
    return await executeFetch(request);
  }
}

export const NonAuthRepository = new Repository((req) => Promise.resolve(req));
export const MediaAccessRepository = new Repository(setMediaHeaders);
export default new Repository(setAuthTokenHeader);


// Utility functions

async function executeFetch(request: Request): Promise<Response> {
  try {
    const response = await fetch(request);

    if(!response.ok) {
      const message = await response.json() || response.statusText;
      return Promise.reject(message);
    }
    
    return response;

  } catch (error: any) {
    window.dispatchEvent(new Event('server_unreachable'));
    return Promise.reject(error.message);
  }
}

function buildRequestURL(resource: string, queryParams?: object): string {
  let url = BASE_URL;
  url += resource;
  url = url.replace(/\/?$/, '/');
  if(queryParams) {
    const params = parseParams(queryParams);
    url += `?${params.toString()}`;
  }
  return url;
}

async function setAuthTokenHeader(request: Request): Promise<Request> {
  try {
    request.headers.set('Authorization', await getAuthToken({requestRefresh}));
    //request.headers.set('Access-Control-Allow-Origin', '*');
    // TODO set credentials and redirect headers
    // request.headers.set('credentials', 'include');
    // request.headers.set('redirect', 'follow');
    // request.headers.set('mode','cors');
  } catch (error) {
    // notify global context that the authorization could not be refreshed
    window.dispatchEvent(new Event('auth_expired'));
    return Promise.reject(error);
  }
  return request;
}

async function setMediaHeaders(request: Request): Promise<Request> {
  try {
    request.headers.set('Authorization', await getAuthToken({requestRefresh}));
    //request.headers.set('Access-Control-Allow-Origin', '*');
    request.headers.set('Access-Control-Allow-Origin', '*');
    request.headers.set('Content-Type', 'image/*');

    
  } catch (error) {
    // notify global context that the authorization could not be refreshed
    window.dispatchEvent(new Event('auth_expired'));
    return Promise.reject(error);
  }
  return request;
}

function buildRequest(uri: string, args: RequestInit, queryParams?: object): Request {
  const url = buildRequestURL(uri, queryParams);
  return new Request(url, args);
}
