

































import {Component, Vue} from 'vue-property-decorator'
import HintDistanceSlider from '@/components/experience/HintDistanceSlider.vue';
import HintTypeChooser from '@/components/experience/HintTypeChooser.vue';
import TrackingRepository from '@/api/TrackingRepository';

@Component({
    components: {
        HintDistanceSlider,
        HintTypeChooser
    }
})
export default class ExperienceSettings extends Vue {
    hint_type: string = 'all'
    remember: boolean = false
    hint_dist: number = 0

    async savePreferences(): Promise<void> {
        let newPreferences = {min_hint_distance: this.hint_dist, hint_type: this.hint_type, ask_preferences: !this.remember}
        if(this.remember){
            this.$store.dispatch('updateExperienceSettings', newPreferences);
        }else{
            this.$store.dispatch('setExperiencePreferences', newPreferences);
        }
        let groupID = "EXPE:"
        const rememberOpt = (this.remember) ? "Ja" : "Nein"
        await TrackingRepository.createLog(groupID+"Erleben Einstellungen speichern. Anzahl Ereignisse "+this.hint_dist+", Art Ereignisse "+this.hint_type+", Alles merken "+rememberOpt)

        this.$emit('close');
    }
    mounted(): void {
        this.hint_type = this.$store.getters.getExperienceHintType;
    }
}
