import Repository from "./Repository"
import Event, { ChainableEvent } from "@/models/Event"
import Paginated from "@/models/Paginated"
import Content from "@/models/Content"
import { getFilenamefromPath } from "@/utils/pathUtils"
const resource = "/v1/events"

export interface EventSearchParams {
  offset?: number
  limit?: number
  ordering?: string
  search?: string
  date_min?: string
  date_max?: string
  location?: string
  difficulty?: string
  mood?: string
  activity?: string
}

class EventRepository {
  async get(limit: number, offset: number, ordering = "-datetime"): Promise<Paginated<Event>> {
    const params = {
      limit: limit,
      offset: offset,
      ordering: ordering
    }
    const response = await Repository.get(resource, params)
    return await response.json()
  }

  async getEvent(eventId: number): Promise<Event> {
    const response = await Repository.get(`${resource}/${eventId}/`)
    return await response.json()
  }

  async searchEvents(filter?: EventSearchParams): Promise<Paginated<Event>> {
    const response = await Repository.get(resource, filter)
    return await response.json()
  }

  async getContents(eventId: number): Promise<Content[]> {
    const response = await Repository.get(`${resource}/${eventId}/contents`)
    return await response.json()
  }

  async getLastEvents(numberOfEvents: number, offset: number): Promise<Paginated<Event>> {
    return this.get(numberOfEvents, offset)
  }

  async getFirstEvents(numberOfEvents: number, offset: number): Promise<Paginated<Event>> {
    const params = {
      limit: numberOfEvents,
      offset: offset,
      ordering: "datetime"
    }
    const response = await Repository.get(resource, params)
    return await response.json()
  }

  async createContent(eventId: number, payload: FormData): Promise<Content> {
    const args: RequestInit = {
      method: "post",
      body: payload
    }
    const response = await Repository.post(`${resource}/${eventId}/contents/`, payload, {}, args)
    return await response.json()
  }

  async deleteContent(eventId: number, filepath: string, mediaId: number) {
    const params = {
      filename: getFilenamefromPath(filepath),
      id: mediaId
    }
    await Repository.delete(`${resource}/${eventId}/contents/`, params)
  }

  async createEvent(payload: Event): Promise<Event> {
    const response = await Repository.post(`${resource}/`, payload)
    return await response.json()
  }

  async deleteEvent(eventId: number) {
    await Repository.delete(`${resource}/${eventId}/`)
  }

  async getLocations(): Promise<String[]> {
    const response = await Repository.get(`${resource}/locations/`)
    return await response.json()
  }

  async updateEvent(eventId: number, payload: Event): Promise<Event> {
    const response = await Repository.put(`${resource}/${eventId}/`, payload)
    return await response.json()
  }

  async getChainable(): Promise<ChainableEvent[]> {
    const response = await Repository.get(`${resource}/chainable/`)
    return await response.json()
  }
}

export default new EventRepository()
