






































import { isLoggedIn } from "@/utils/jwt_interceptor"
import { Component, Vue } from "vue-property-decorator"
import Navigation from "@/components/Navigation.vue"
import Lightbox from "vue-easy-lightbox"
import Carousel3d from "vue-carousel-3d"
import OfflineModal from "@/components/offline/OfflineModal.vue"
import UnreachableModal from "@/components/offline/UnreachableModal.vue"
import browserDetect from "vue-browser-detect-plugin"
import MoodInputDialog from "./components/dialogs/MoodInputDialog.vue"
import { AlertState } from "./store/types"
import PWAInstallPrompt from "@/components/install/PWAInstallPrompt.vue"
import BasicNotification from "./components/notifications/BasicNotification.vue"
import InAppOverlay from "./components/InAppOverlay.vue"
const VARIABLES = require("@/static/css/_variables.scss")

Vue.use(Lightbox)
Vue.use(Carousel3d)
Vue.use(browserDetect)

@Component({
  components: {
    Navigation,
    OfflineModal,
    UnreachableModal,
    MoodInputDialog,
    PWAInstallPrompt,
    BasicNotification,
    InAppOverlay
  }
})
export default class App extends Vue {
  onlineStatus: boolean = true
  unreachable: boolean = false
  navHeight: any = VARIABLES["height-bar-mobile"]
  showInstallPrompt: boolean = false
  notificationTimer: ReturnType<typeof setInterval> = setInterval(() => {})

  get mediaClass(): string {
    switch (this.$vuetify.breakpoint.name) {
      case "xs":
      case "sm":
        return "mobile"
      case "md":
      case "lg":
      case "xl":
      default:
        return "desktop"
    }
  }

  get alert(): AlertState {
    return this.$store.state.alert
  }
  get notify(): Notification {
    return this.$store.state.notification
  }
  get hideNav(): boolean | undefined {
    /*
      Using optional chaining, because 'meta' is undefined
      https://stackoverflow.com/questions/54884488/how-can-i-solve-the-error-ts2532-object-is-possibly-undefined/58401023#58401023
      */
    return this.$route?.meta?.hideNav
  }

  get isMobileDevice(): boolean {
    return /android|iphone|ipad|ipod/i.test(navigator.userAgent)
  }

  get isLoggedIn(): boolean {
    return Object.keys(this.$store.state.user.userdata).length > 0
  }

  created(): void {
    window.addEventListener("auth_expired", () => {
      if (!this.unreachable) this.$store.dispatch("logout")
    })
    window.addEventListener("server_unreachable", () => {
      this.unreachable = true
      this.onlineStatus = false
    })
    window.addEventListener("server_reachable", () => {
      this.unreachable = false
      this.onlineStatus = true
    })
    this.onlineStatus = navigator.onLine
    window.addEventListener("online", () => {
      this.onlineStatus = true
    })
    window.addEventListener("offline", () => {
      this.onlineStatus = false
    })

    addEventListener("beforeinstallprompt", (e) => {
      /*
        Windows: Chrome (supported), Edge (supported), Firefox (not supported)
        Android: Chrome (supported), Firefox (not supported), Opera (not supported)
        iOS: not supported (all browsers)
      */
      e.preventDefault()
      this.$store.commit("mobile/setPwaPrompt", e)
    })

    const params = new URLSearchParams(location.search)
    if (this.isMobileDevice && (params.has("installPWA") || !this.$cookies.isKey("pwa_install"))) {
      this.showInstallPrompt = true
    }

    // fetch notifications every 10 seconds
    this.notificationTimer = setInterval(() => {
      if (isLoggedIn()) {
        this.fetchNotifications()
      }
    }, 10000)
  }

  beforeDestroy(): void {
    this.cancelNotificationTimer()
  }

  clearAlert(): void {
    this.$store.dispatch("alert/clear")
  }

  fetchNotifications(): void {
    this.$store.dispatch("loadNotifications")
  }

  cancelNotificationTimer(): void {
    clearInterval(this.notificationTimer)
  }
}
