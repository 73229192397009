







































import { Component, Vue, Prop, Watch } from "vue-property-decorator"
import ProfileDetails from "@/components/social/ProfileDetails.vue"
import ProfileAchievements from "@/components/social/ProfileAchievements.vue"
import DailyXPProgress from "@/components/social/DailyXPProgress.vue"
import ProfileSettings from "@/components/social/ProfileSettings.vue"
import User from "@/models/User"
import UserRepository from "@/api/UserRepository"
import LoadingSpinner from "@/components/LoadingSpinner.vue"
import OnbProfileHints from "@/components/onboarding/OnbProfileHints.vue"
import { Route } from "vue-router"
import TrackingRepository from "@/api/TrackingRepository"

@Component({
  components: {
    ProfileAchievements,
    ProfileDetails,
    DailyXPProgress,
    LoadingSpinner,
    ProfileSettings,
    OnbProfileHints
  }
})
export default class Profile extends Vue {
  @Prop({ type: Number }) userId: number | undefined
  user: User = {} as User
  ownprofile: boolean = false
  timestamp: number = Date.now()
  isLoading: boolean = true
  showSettings: boolean = false
  showProfileHints: boolean = false

  get profileHintsStatus(): boolean {
    return this.$store.getters.getOnboardingSetting("profile_hints")
  }

  formatDate(date: string): string {
    let dateObject = new Date(date)
    return dateObject.toLocaleDateString()
  }
  async fetchForeignUser(userId: number): Promise<void> {
    try {
      this.user = await UserRepository.getUser(userId, true)
    } catch (error) {
      this.$store.dispatch("alert/error", this.$t("error_messages.user"))
      /* eslint-disable no-console */
      console.error("Error while fetching user: " + error)
    }
  }
  integrateUserDataFromStore(): void {
    this.user = this.$store.getters.getUser
    if (Object.keys(this.user).length == 0) {
      this.$store.dispatch("alert/error", this.$t("error_messages.user"))
      /* eslint-disable no-console */
      console.error("Error while integrating store data")
    }
  }
  async loadData(userId: number | undefined): Promise<void> {
    if (userId === undefined) {
      this.ownprofile = true
      await this.$store.dispatch("integrateUserDataFromBackend")
      this.integrateUserDataFromStore()
    } else {
      this.ownprofile = false
      await this.fetchForeignUser(userId)
    }
  }
  async log(): Promise<void> {
    let groupID = "SETT:"
    await TrackingRepository.createLog(groupID + "Wechsel zu Feature Einstellungen")
  }
  async mounted(): Promise<void> {
    await this.loadData(this.userId)
    this.isLoading = false
    this.showProfileHints = this.$store.getters.getOnboardingSetting("profile_hints")
  }

  @Watch("$route")
  onRouteChange(to: Route) {
    this.loadData(parseInt(to.params.userId))
  }

  @Watch("profileHintsStatus")
  onProfileHintsStatusChange() {
    this.showProfileHints = this.profileHintsStatus
  }

  @Watch("$store.getters.getUser")
  onUserUpdate() {
    if (this.ownprofile) this.integrateUserDataFromStore()
  }
}
