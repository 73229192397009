








































import { Vue, Prop, Component } from 'vue-property-decorator'
import { AssetHelper } from "@/utils/AssetHelper";

@Component({})
export default class DifficultyInput extends Vue {
  @Prop({type: Number}) value: number | undefined
  @Prop({type: String, required: true}) eventtype!: string

  getDifficultyImage(difficulty: number, isSelected: boolean): NodeRequire {
    return AssetHelper.getDifficultyIcon(
      difficulty,
      this.eventtype,
      isSelected
    );
  }
}
