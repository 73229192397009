







import {Component, Prop, Vue} from 'vue-property-decorator'
import Companion from "@/components/onboarding/Companion.vue"
import Speechbubble from "@/components/onboarding/Speechbubble.vue"

@Component({
  components: {
    Companion,
    Speechbubble
  }
})
export default class ExperienceBubble extends Vue {
  @Prop({type: String, required: true}) text!: string
}
