












import {Vue, Component} from 'vue-property-decorator'
import ModalBase from "@/components/offline/ModalBase.vue";

@Component({
  components: {
    ModalBase
  }
})
export default class OfflineModal extends Vue {}
