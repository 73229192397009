import { Module } from "vuex"
import LoginRepository from "@/api/LoginRepository"
import UserRepository from "@/api/UserRepository"
import { ILoginRequest } from "@/models/ILoginRequest"
import { UserShort, AccountState, RootState } from "../types"
import router from "@/router/index"
import { isLoggedIn } from "@/utils/jwt_interceptor"
import i18n from "@/locales/i18n"

const sessionUser = localStorage.getItem("user")
const user: UserShort = sessionUser && JSON.parse(sessionUser)

const state = user
  ? { status: { isAuthenticated: true }, user }
  : { status: {}, user: { id: -1, email: "", name: "" } }

const account: Module<AccountState, RootState> = {
  state,
  mutations: {
    loginRequest(state) {
      state.status = { loggingIn: true }
    },
    loginSuccess(state, user: UserShort) {
      state.status = { isAuthenticated: true }
      state.user = user
    },
    loginFailure(state) {
      state.status = {}
    },
    logout(state) {
      state.status = {}
      state.user = { id: -1, email: "", name: "" }
    },
    updateUserName(state, payload) {
      state.user.name = payload.name
    }
  },
  actions: {
    async login({ dispatch, commit, state, rootGetters }, request: ILoginRequest) {
      commit("loginRequest")
      try {
        await LoginRepository.login(request)
      } catch (err: any) {
        commit("loginFailure")
        const message = err.detail
          ? i18n.t("error_messages.wrong_email_password")
          : i18n.t("error_messages.logging_in")
        dispatch("alert/error", message)
        return
      }
      if (isLoggedIn()) {
        // get user id by searching with email
        const email = request.email
        const user = await UserRepository.getMyself()
        const { id, name } = user
        if (id == undefined) {
          dispatch("alert/error", i18n.t("error_messages.user"))
          commit("loginFailure")
        }
        localStorage.setItem("user", JSON.stringify({ id: id, email: email, name: name }))
        commit("loginSuccess", { id: id, email: email, name: name })
        await dispatch("integrateBackendSettings")
        await dispatch("integrateUserDataFromBackend")
        // poll unread chats once, to display 'unread messages dot' on chat tab
        dispatch("chat/fetchUnread")

        if (rootGetters.getOnboardingPending) router.push({ name: "onboarding" })
        else router.push({ name: "home" })
        return
      }
      commit("loginFailure")
      dispatch("alert/error", i18n.t("error_messages.wrong_email_password"))
    },
    async logout({ commit }, redirect = true) {
      await LoginRepository.logout()
      commit("chat/clearUnreadPolling")
      if (redirect) {
        router.push({ name: "login" })
      }
      localStorage.removeItem("user")
      commit("logout")
      commit("clearRelations")
      // dispatch("clearSettings");
    },
    updateUserName({ commit }, payload) {
      commit("updateUserName", payload)
    }
  },
  getters: {
    isAuthenticated(state) {
      return state.status.isAuthenticated || false
    },
    userId(state) {
      return state.user.id
    }
  }
}

export default account
