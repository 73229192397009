






















import {Component, Watch} from 'vue-property-decorator'
import VueStepper from './VueStepper'
import Speechbubble from '@/components/onboarding/Speechbubble.vue'
import Companion from '@/components/onboarding/Companion.vue'
import Avatar from '@/components/onboarding/Avatar.vue'
import TypingBubble from '@/components/onboarding/onb_chat/TypingBubble.vue'

@Component({
    components: {
        Speechbubble,
        Companion,
        Avatar,
        TypingBubble
    }
})
export default class InputAvatar extends VueStepper {
    stepTimes: number[] = [2000, 2000, 0, 1500] // typing, speech, showing Avatar choice carousel, show selected avatar
    avatarPath: string = ''

    created(): void {
        this.stepper()
    }

    @Watch('step')
    onStepChange() {
        if(this.step >= this.stepTimes.length)
            this.$emit('nextOnbPart');
        else if(this.step != 2)
            this.stepper();

    }
}
