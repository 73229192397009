import Repository from "./Repository";
import Paginated from '@/models/Paginated';
import Chat from '@/models/Chat';
import ChatMessage from '@/models/ChatMessage';
const resource = "/v1/chats/";

class ChatRepository {
  async get(limit?: number, offset?: number): Promise<Paginated<Chat>> {
    // default limit is 10
    const params = {
      'limit': limit,
      'offset': offset
    };
    const response = await Repository.get(resource, params);
    return await response.json();
  }

  async getChat(chatId: number): Promise<Chat> {
    const response = await Repository.get(`${resource}${chatId}/`);
    return await response.json();
  }

  async createChat(receiver: number, ): Promise<Chat> {
    const response = await Repository.post(resource, {
      user2: receiver,
      //message: message
    });
    return await response.json();
  }

  async getMessages(roomId: string, limit: number, offset: number): Promise<Paginated<ChatMessage>> {
    // default limit is 10
    const params = {
      'limit': limit,
      'offset': offset
    };
    const response = await Repository.get(`${resource}${roomId}/messages/`, params);
    return await response.json();
  }

  async sendMessage(roomId: string, message: string): Promise<ChatMessage> {
    const response = await Repository.post(`${resource}${roomId}/messages/`, {
      text: message
    });
    return await response.json();
  }

  async deleteChat(chatId: number) {
    await Repository.delete(`${resource}${chatId}/`);
  }

  async markChatRead(chatId: number): Promise<Chat>{
    const params = {
      'is_read': true
    }
    const response = await Repository.patch(`${resource}${chatId}/`, params);
    return await response.json();
  }

  async getUnread(): Promise<string[]> {
    const response = await Repository.get(`${resource}unread/`);
    return await response.json();
  }
}

export default new ChatRepository();
