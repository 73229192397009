import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"fullscreen":false,"scrollable":""},on:{"click:outside":_vm.closeOverlay},model:{value:(_vm.user),callback:function ($$v) {_vm.user=$$v},expression:"user"}},[_c(VCard,[_c(VCardTitle,{staticClass:"yc_top_bar noPadding"},[_c('div',{staticClass:"dialogTitle"},[_vm._v(_vm._s(_vm.$t("foreign_profile")))]),_c(VBtn,{attrs:{"id":"eventform-closebutton","fab":"","small":""},on:{"click":_vm.closeOverlay}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VCardText,[_c('ProfileDetails',{staticClass:"yc_profile_details",attrs:{"user":_vm.user,"ownprofile":false,"is-overlay":true}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }