




























import { Component, Prop, Vue } from "vue-property-decorator"
import Diagnosis from "@/models/Diagnosis"

interface TranslatedDiagnoses extends Diagnosis {
  nameTranslated: string
  diagnosisGroupTranslated: string | null
  _displayName: string
}

@Component({})
export default class DiagnosisDropdown extends Vue {
  @Prop({ type: Object, required: true }) value!: Diagnosis
  @Prop({ type: Array, required: true }) diagnosisList!: Diagnosis[]
  @Prop({ type: Boolean, default: false }) showPlaceholder!: boolean
  @Prop({ type: String }) placeholderText: string | undefined

  get inputVal(): Diagnosis {
    return this.value
  }
  set inputVal(val: any) {
    delete val.nameTranslated
    delete val.diagnosisGroupTranslated
    delete val._displayName
    this.$emit("input", val)
  }
  get translatedDiagnoses(): TranslatedDiagnoses[] {
    return this.diagnosisList.map((diagnosis: Diagnosis) => {
      const nameTranslated = <string>this.$t("diagnoses." + diagnosis.name)
      const diagnosisGroupTranslated = diagnosis.diagnosis_group
        ? <string>this.$t("diagnosisGroups." + diagnosis.diagnosis_group)
        : null
      return {
        ...diagnosis,
        nameTranslated,
        diagnosisGroupTranslated,
        _displayName:
          (diagnosisGroupTranslated ? diagnosisGroupTranslated + " - " : "") + nameTranslated
      }
    })
  }
  get nonNullDiagnosisGroupDiagnoses(): TranslatedDiagnoses[] {
    return this.translatedDiagnoses.filter(
      (diagnosis: TranslatedDiagnoses) => diagnosis.diagnosis_group
    )
  }
  get nullDiagnosisGroupDiagnoses(): TranslatedDiagnoses[] {
    return this.translatedDiagnoses.filter(
      (diagnosis: TranslatedDiagnoses) => !diagnosis.diagnosis_group
    )
  }
  get sortedDiagnoses(): Diagnosis[] {
    const arr = this.nonNullDiagnosisGroupDiagnoses
    arr.sort((a: TranslatedDiagnoses, b: TranslatedDiagnoses) =>
      // @ts-ignore
      a.diagnosisGroupTranslated.localeCompare(b.diagnosisGroupTranslated)
    )
    const translatedDiagnosesFull = arr.concat(this.nullDiagnosisGroupDiagnoses)
    return translatedDiagnosesFull
  }
}
