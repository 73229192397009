




































import { Vue, Component, Prop, Watch } from "vue-property-decorator"
import EditMoodInputDialog from "@/components/dialogs/EditMoodInputDialog.vue"
import DailyMood from "@/models/DailyMood"
import Chart, { ChartItem } from "chart.js/auto"
import DailyMoodChart from "@/DailyMoodChart"
import { numDays } from "@/utils/dateUtils"
import TrackingRepository from "@/api/TrackingRepository"

/*eslint-disable no-console*/

@Component({
  components: {
    EditMoodInputDialog
  }
})
export default class MoodChart extends Vue {
  @Prop({ type: Number }) timespan!: number
  isMoodInputDialogDisplayed: boolean = false
  moodChart: Chart | null = null

  get moodYesterday(): DailyMood | undefined {
    return this.$store.state.mood.yesterday
  }
  get moods(): DailyMood[] {
    const dailyMoods = this.$store.getters.moods() as DailyMood[]
    return dailyMoods.filter(
      (dailyMood: DailyMood) =>
        numDays(Date.now(), Date.parse(dailyMood.date)) < this.timespan && dailyMood.mood > -1
    )
  }

  @Watch("timespan")
  onTimespanChange() {
    this.logOptionChange()
    this.buildMoodChart()
  }

  async mounted(): Promise<void> {
    //Load all moods when the component mounts
    this.buildMoodChart()
  }
  async moodSelected(): Promise<void> {
    const groupID = "PROF:"
    await TrackingRepository.createLog(groupID + "Tagesstimmung eintragen im Profil")
    await this.$store.dispatch("loadMoods")
    this.isMoodInputDialogDisplayed = false
    this.buildMoodChart()
  }
  async logOptionChange(): Promise<void> {
    const groupID = "PROF:"
    await TrackingRepository.createLog(
      groupID + "Zeitraum im Diagramm ändern auf " + this.timespan + " Tage"
    )
  }
  buildMoodChart(): void {
    if (this.moods.length > 1) {
      const dates: Array<Date> = this.moods.map((moodObj) => new Date(Date.parse(moodObj.date)))
      const moods: Array<number> = this.moods.map((moodObj) => moodObj.mood)
      const moodChart = new DailyMoodChart(dates, moods, this.timespan)
      const ctx = document.getElementById("moodChart") as ChartItem
      this.moodChart ? this.moodChart.destroy() : null
      this.moodChart = new Chart(ctx, moodChart.generateChart())
    }
  }
}
