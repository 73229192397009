import MediaAccessRepository from "./Repository"
const resource = "/v1/protectedmedia"

export class EncodedContent {
  constructor(readonly mimetype: string, readonly data: string) {}

  /**
   * form data URI scheme for string encoded data
   */
  toDataURI(): string {
    return `data:${this.mimetype};base64,${this.data}`
  }
}
class MediaRepository {
  async getPartnerEncodedContent(
    folder: string,
    filepath: string,
    partner_id: number
  ): Promise<EncodedContent> {
    const filename = filepath.split("/").pop()
    const params = {
      filename: filename,
      folder: folder,
      avatar_folder: partner_id
    }
    const response = await MediaAccessRepository.get(resource, params)
    const buffer: ArrayBuffer = await response.arrayBuffer()

    const contentType = response.headers.get("content-type")
    if (!contentType) {
      throw new Error("Missing content type for response")
    }

    return new Promise((resolve) => {
      // USE WEB WORKER TO STOP BLOCKING UI WHEN ENCODING LARGE FILES
      const worker = new Worker("../encoder.worker.js", { type: "module" })
      worker.onmessage = (event) => {
        resolve(new EncodedContent(contentType, event.data))
      }
      worker.postMessage(buffer)
    })
  }

  async getEncodedContent(folder: string, filepath: string): Promise<EncodedContent> {
    const filename = filepath.split("/").pop()
    const params = {
      filename: filename,
      folder: folder
    }
    const response = await MediaAccessRepository.get(resource, params)
    const buffer: ArrayBuffer = await response.arrayBuffer()

    const contentType = response.headers.get("content-type")
    if (!contentType) {
      throw new Error("Missing content type for response")
    }

    return new Promise((resolve) => {
      // USE WEB WORKER TO STOP BLOCKING UI WHEN ENCODING LARGE FILES
      const worker = new Worker("../encoder.worker.js", { type: "module" })
      worker.onmessage = (event) => {
        resolve(new EncodedContent(contentType, event.data))
      }
      worker.postMessage(buffer)
    })
  }

  async getPartner(folder: string, filepath: string, partner_id: number): Promise<Blob> {
    const filename = filepath.split("/").pop()
    const params = {
      filename: filename,
      folder: folder,
      avatar_folder: partner_id
    }

    const response = await MediaAccessRepository.get(resource, params)

    const buffer: ArrayBuffer = await response.arrayBuffer()
    const bytes: Uint8Array = new Uint8Array(buffer)
    const blob = new Blob([bytes], { type: String(response.headers.get("content-type")) })
    return blob
  }

  async get(folder: string, filepath: string): Promise<Blob> {
    const filename = filepath.split("/").pop()
    const params = {
      filename: filename,
      folder: folder
    }
    const response = await MediaAccessRepository.get(resource, params)

    const buffer: ArrayBuffer = await response.arrayBuffer()
    const bytes: Uint8Array = new Uint8Array(buffer)
    const blob = new Blob([bytes], { type: String(response.headers.get("content-type")) })
    return blob
  }
}

export default new MediaRepository()
