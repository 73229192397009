export interface IMood {
  index: number
}

export const MOODS: Array<IMood> = [
  {
    index: 1
  },
  {
    index: 2
  },
  {
    index: 3
  },
  {
    index: 4
  },
  {
    index: 5
  },
  {
    index: 6
  }
]
