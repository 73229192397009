





























































import { Vue, Component, Prop, Watch } from "vue-property-decorator"
import TrackingRepository from "@/api/TrackingRepository"

@Component({})
export default class Navigation extends Vue {
  @Prop({ type: String, required: true }) styleClass!: string

  isChatBubbleDisplayed: boolean = false
  isInfoTextBubbleDisplayed: boolean = false

  async log(location: String, groupID: String): Promise<void> {
    groupID += ":"
    await TrackingRepository.createLog(groupID + "Wechsel zu Feature " + location)
  }

  clearNotifications(hasNotifications: boolean): void {
    if (hasNotifications) {
      this.$store.dispatch("markChatNotificationsAsSeen")
    }
  }

  get currentRouteName(): string | null | undefined {
    return this.$route.name
  }
  get unreadChats(): number {
    return this.$store.getters.getChatNotificationsCount
  }

  get unreadInfoTexts(): number {
    return this.$store.getters.getInfoTextNotificationsCount
  }

  @Watch("unreadChats")
  onUnreadChatsChange(): void {
    this.isChatBubbleDisplayed = this.unreadChats > 0
  }

  @Watch("unreadInfoTexts")
  onUnreadInfoTextsChange(): void {
    this.isInfoTextBubbleDisplayed = this.unreadInfoTexts > 0
  }
}
