














import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class BooleanButtons extends Vue {
    @Prop({type: String, required: true}) trueText!: string
    @Prop({type: String, required: true}) falseText!: string
}
