export function valueInputValidators(minValue: string, maxValue: string, $t: any): any[] {
  return [
    (v: string) => !!v || $t("validation.not_empty"),
    (v: string) =>
      Math.round(parseFloat(v) * 10000) < Math.round(parseFloat(maxValue) * 10000) ||
      $t("validation.value_too_high"),
    (v: string) =>
      Math.round(parseFloat(v) * 10000) > Math.round(parseFloat(minValue) * 10000) ||
      $t("validation.value_too_low")
  ]
}

/*
takes two django format timestamps. if 'endTS' is not given, the according validation will be ignored 
validates the following conditions: 
- 'startTS cannot be in future 
-  'endTS' cannot be smaller than 'startTS 
*/
export function dateInputValidator(
  startTS: string,
  endTS: string,
  nowTS: string,
  $t: any
): string[] {
  // remove all non digits from 'datetime'
  // -> latest timestamp is the highest number, due to django timestamp format
  // -> f.e. "2021-01-31T17:00:00Z"
  const start = parseInt(startTS.replace(/\D/g, ""))
  const now = parseInt(nowTS.replace(/\D/g, ""))

  const errors = []
  if (start > now) errors.push("" + $t("validation.startdate_is_in_future"))

  if (endTS !== undefined || endTS !== null || endTS !== "") {
    const end = parseInt(endTS.replace(/\D/g, ""))

    if (end < start) errors.push("" + $t("validation.date_smaller_start"))
    if (end > now) errors.push("" + $t("validation.enddate_is_in_future"))
  }

  return errors
}
