














import {Component, Vue, Prop} from 'vue-property-decorator'
import Speechbubble from '@/components/onboarding/Speechbubble.vue';
import Companion from '@/components/onboarding/Companion.vue';

@Component({
    components: {
        Speechbubble,
        Companion
    }
})
export default class XPNotification extends Vue {
    @Prop({type: Boolean, default: false}) show!: boolean
}
