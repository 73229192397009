











































import { Vue, Component, Prop } from 'vue-property-decorator'
import Achievement from '@/models/Achievement';
import AchievementIcon from '@/components/social/AchievementIcon.vue';
import User from '@/models/User'

@Component({
    components: {
        AchievementIcon
    }
})
export default class ProfileAchievements extends Vue {
    @Prop({type: Object, required: true}) user!: User
    achievements: Achievement[] = []
    achievementStages: Record<string, number[]> = {
        event_streak: [10, 30, 60, 180, 360],
        total_events: [3, 7, 14, 30],
        hard_events: [5, 10, 25],
        good_events: [5, 10, 25],
        treatment_completed: [],
        good_mood: [3, 7],
        good_again: [3, 7]
    } as Record<string, number[]>

    getAchievements(achievementName: string): Achievement[]{
        if(achievementName.includes('treatment')){
            // return all achievements for treatment_completed and treatment_cycle_completed
            return this.achievements.filter(a => a.name.includes('treatment'));
        }
        return this.achievements.filter(a => a.name.includes(achievementName));
    }
    getNumberOfAchievement(name: String): string{
        if(name.includes('treatment'))
            return ''+this.getAchievements('treatment_completed').length;
        let splitted = name.split('_');
        return splitted[splitted.length-1];
    }
    calculateNextAchievementStep(achievement: string){
        if(achievement.includes('treatment')){
            return this.getAchievements(achievement).length+1;
        }
        let currNrOfAchieved = this.getAchievements(achievement).length;
        let stages = this.achievementStages[achievement];
        if(currNrOfAchieved < stages.length){
            return stages[currNrOfAchieved];
        }
        return 0;
    }
    mounted(): void{
        this.achievements = this.user.achievements;
    }
}
