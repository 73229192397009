




























































import { Component, Prop, Vue } from 'vue-property-decorator'
import ForeignProfileInteraction from "@/components/social/ForeignProfileInteraction.vue";
import User from '@/models/User'

@Component({
  components: {
    ForeignProfileInteraction
  }
})
export default class ForeignProfile extends Vue {
  @Prop({type: Object, required: true}) user!: User

  formatDate(date: string): string {
    let dateObject = new Date(date);
    return dateObject.toLocaleDateString();
  }
}
