import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VRow,{staticClass:"yc_xp_progess_row"},[_c(VCol,[_c(VCardSubtitle,{staticClass:"text-center my-2"},[_vm._v(" "+_vm._s(_vm.$t("xp_progress.your_progress_daily"))+" ")]),_vm._v(" "+_vm._s(_vm.$t("xp_progress.entries"))+" "),_c(VBtn,{attrs:{"icon":"","small":""},on:{"click":function($event){_vm.showEntryHint = !_vm.showEntryHint;
          _vm.showExpHint = false;}}},[_c(VIcon,[_vm._v("mdi-help-circle-outline")])],1),(_vm.showEntryHint)?_c('div',{staticClass:"text-justify font-small privacy_settings_explanation px-5"},[_vm._v(" "+_vm._s(_vm.$t("xp_progress.entry_explanation"))+" ")]):_vm._e(),_c(VCol,{staticClass:"yc_xp_entries"},[_c(VProgressCircular,{attrs:{"rotate":-90,"size":90,"width":10,"value":_vm.entryXpPercentage}},[_vm._v(" "+_vm._s(_vm.entryXpGained)+" / "+_vm._s(_vm.entryXpMax)+" ")])],1)],1),_c(VCol,[_c(VCardSubtitle,{staticClass:"text-center my-2"},[_vm._v(" "+_vm._s(_vm.$t("xp_progress.your_progress_weekly"))+" ")]),_vm._v(" "+_vm._s(_vm.$t("xp_progress.experience_mode"))+" "),_c(VBtn,{attrs:{"icon":"","small":""},on:{"click":function($event){_vm.showExpHint = !_vm.showExpHint;
          _vm.showEntryHint = false;}}},[_c(VIcon,[_vm._v("mdi-help-circle-outline")])],1),(_vm.showExpHint)?_c('div',{staticClass:"text-justify font-small privacy_settings_explanation px-5"},[_vm._v(" "+_vm._s(_vm.$t("xp_progress.exp_explanation"))+" ")]):_vm._e(),_c(VCol,{staticClass:"yc_xp_experience"},[_c(VProgressCircular,{attrs:{"rotate":-90,"size":90,"width":10,"value":_vm.experienceXPGainedPercentage}},[_vm._v(" "+_vm._s(_vm.experienceGainedXp)+" / "+_vm._s(_vm.experienceMaxXp)+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }