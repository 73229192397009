






































































































import { Component, Prop, Watch, Vue } from "vue-property-decorator"
import { valueInputValidators } from "@/utils/symptom_validator"
import { getNowTimestamp, formatDate, getTimestamp } from "@/utils/utils"
import FloatingPointTextfield from "@/components/utils/FlotaingPointTextfield"
import Symptom from "@/models/Symptom"
import SymptomUnit from "@/models/SymptomUnit"

@Component({
  components: {
    FloatingPointTextfield
  }
})
export default class AddPointDialog extends Vue {
  @Prop({ type: Boolean, default: false }) showDialog!: boolean
  @Prop({ type: String }) headline: string | undefined
  @Prop({ type: Object, required: true }) symptom!: Symptom
  @Prop({ type: Boolean, default: false }) isEditDialog!: boolean
  @Prop({ type: Object, default: () => ({}) }) point!: IPoint
  @Prop({ type: Number, default: null }) initialValue!: number | null

  isFloatingPoint: boolean = false
  isInvalid: boolean = false
  datemenu: boolean = false
  value: string = ""
  time: string = ""
  date: string = ""
  buttonText: string = ""
  intensityMax: number = 10
  intensityMin: number = 0
  showError: boolean = false
  errorLabel: string = ""
  validForm: boolean = true

  updateInitialValue(): void {
    // first point for a symptom
    if (this.initialValue == null) {
      if (!this.isFloatingPoint) this.value = "5"
      else if (this.unit.description === "Temperatur") this.value = "36,5"
      else this.value = "50"
    } else this.value = this.initialValue.toString()
  }
  valueCorrection(): void {
    if (!this.isFloatingPoint) {
      if (parseInt(this.value) > this.intensityMax) this.value = this.intensityMax.toString()
      if (parseInt(this.value) < this.intensityMin) this.value = this.intensityMin.toString()
    }
  }
  addSymptomPoint(): void {
    this.showError = false

    // time cannot be in the future
    // remove all non digits from 'datetime'
    // -> latest timestamp is the highest number, due to django timestamp format
    // -> f.e. "2021-01-31T17:00:00Z"
    if (
      parseInt(getTimestamp(this.date, this.time).replace(/\D/g, "")) >
      parseInt(getNowTimestamp().replace(/\D/g, ""))
    ) {
      this.showError = true
      this.errorLabel = "" + this.$t("dialogs.no_future_time")
      return
    }

    let _value = this.value

    if (this.isFloatingPoint) {
      _value = _value.replace(",", ".")
      _value = Number(_value).toFixed(2)
    }

    const data = {
      date: this.date,
      time: this.time,
      value: _value
    }

    this.$emit("callback", data)
  }
  created(): void {
    if (this.unit.description !== "Intensität") this.isFloatingPoint = true

    if (this.isEditDialog) {
      this.date = this.point.date
      this.time = this.point.time
      this.value = this.point.value
      this.buttonText = "" + this.$t("dialogs.save")
    } else {
      this.date = formatDate(getNowTimestamp(), false)
      this.time = formatDate(getNowTimestamp(), true)
      this.buttonText = "" + this.$t("dialogs.add")

      this.updateInitialValue()
    }
  }
  @Watch("time")
  onTimeChange(newValue: string | null): void {
    this.isInvalid = !newValue
  }
  @Watch("initialValue")
  onInitialValueChange(): void {
    this.updateInitialValue()
  }
  @Watch("unit")
  onUnitChange(newUnit: SymptomUnit) {
    this.isFloatingPoint = newUnit.description !== "Intensität"
    this.updateInitialValue()
  }

  get valueRules(): any[] {
    if (this.unit.description === "Gewicht")
      return valueInputValidators("30", "120", (key: string) => this.$t(key))
    else if (this.unit.description === "Temperatur")
      return valueInputValidators("33", "43", (key: string) => this.$t(key))
    else return valueInputValidators("-1", "11", (key: string) => this.$t(key))
  }
  get today(): string {
    const date = new Date(Date.now())
    return `${date.getFullYear()}-${("" + (date.getMonth() + 1)).padStart(2, "0")}-${(
      "" + date.getDate()
    ).padStart(2, "0")}`
  }
  get symptomUnit(): number {
    if (this.symptom.unit != null) {
      return this.symptom.unit.id - 1
    }
    return 1
  }
  get unit(): SymptomUnit {
    return this.symptom?.unit || <SymptomUnit>{}
  }
  get show(): boolean {
    return this.showDialog
  }
  set show(value: boolean) {
    if (!value) this.$emit("close")
  }
}

interface IPoint {
  date: string
  time: string
  value: string
}
