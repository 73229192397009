









import { Component, Vue } from "vue-property-decorator"
import EventForm from "@/views/EventForm.vue"

@Component({
  components: {
    EventForm
  }
})
export default class CreateEventOverlay extends Vue {
  closeOverlay(): void {
    this.$store.dispatch("closeCreateEventOverlay")
  }
}
