export interface NotificationDTO {
  notification_hash: string
  notifications: Array<Notification>
}

export interface INotification {
  id: number
  createdDate: string
  notificationDate: string
  type: NotificationType
  key: string
  data: NotificationBody
}

export interface InfoTextNotification extends INotification {
  data: INotificationTextMeta
}

export interface SymptomNotification extends INotification {
  data: INotificationSymptomMeta
}

export interface UserNotification extends INotification {
  data: INotificationUserMeta
}

export interface LastUsedNotification extends INotification {
  data: INotificatationLastUsedFeatureMeta
}

export interface MountainReachedNotification extends INotification {
  data: INotificationMountainReachedMeta
}

export interface BasicNotification extends INotification {
  notification: NotificationBody
}

export type Notification =
  | InfoTextNotification
  | SymptomNotification
  | UserNotification
  | LastUsedNotification
  | MountainReachedNotification
  | BasicNotification

export enum EBannerNotificationType {
  REMINDER_ONGOING_SYMPTOM = "reminder_ongoing_symptom",
  REMINDER_ENTER_TEMP_AND_WEIGHT = "reminder_enter_temp_and_weight",
  REMINDER_TODAY_NO_EVENT = "reminder_today_no_event",
  REMINDER_YESTERDAY_NO_EVENT = "reminder_yesterday_no_event",
  MOTIVATION_BAD_EVENTS = "motivation_bad_events",
  REMINDER_VISIT_EXPERIENCE_MODE = "reminder_visit_experience_mode",
  REMINDER_OPEN_XP = "reminder_open_xp",
  NEW_FRIEND_REQUEST = "new_friend_request",
  FRIEND_REQUEST_ACCEPTED = "friend_request_accepted",
  FRIEND_IN_CLINIC = "friend_in_clinic",
  FOREIGN_USER_IN_CLINIC = "foreign_user_in_clinic",
  MOTIVATION_BAD_DAILY_MOOD = "motivation_bad_daily_mood",
  REMINDER_ADJUST_STATUS = "reminder_adjust_status",
  REMINDER_VISIT_EVENT_CHAIN = "reminder_visit_event_chain",
  REACHED_PROGRESS_MARKER = "reached_progress_marker",
  BIRTHDAY = "birthday",
  REMINDER_SURVEY = "reminder_survey"
}

export enum ENonBannerNotificationType {
  NEW_RECOMMENDED_INFO_TEXT = "new_recommended_info_text",
  NEW_MESSAGE = "new_message"
}

export type NotificationType = EBannerNotificationType | ENonBannerNotificationType

interface NotificationBody {}

interface INotificationTextMeta extends NotificationBody {
  id: number
  title: string
  type: string
}

interface INotificationSymptomMeta extends NotificationBody {
  id: number
  name: string
  start_date: string
  last_measurement: string
}

interface INotificationUserMeta extends NotificationBody {
  user_id: number
  name: string
  treatedAt: string
}
interface INotificatationLastUsedFeatureMeta extends NotificationBody {
  last_used: string
}

interface INotificationMountainReachedMeta extends NotificationBody {
  name: string
  value: number
}
