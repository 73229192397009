

































































import { Component, Prop, Watch } from "vue-property-decorator"
import VueStepper from "./VueStepper"
import Speechbubble from "@/components/onboarding/Speechbubble.vue"
import Companion from "@/components/onboarding/Companion.vue"
import TypingBubble from "@/components/onboarding/onb_chat/TypingBubble.vue"
import OnboardingSelect from "@/components/onboarding/onb_chat/OnboardingSelect.vue"
import Gender from "@/models/Gender"

@Component({
  components: {
    Speechbubble,
    Companion,
    TypingBubble,
    OnboardingSelect
  }
})
export default class InputGender extends VueStepper {
  @Prop({ type: String, default: "" }) avatarPath!: string
  stepTimes: number[] = [2000, 2000, 0, 1500, 2000, 2000] //typing, question gender, gender input, waiting after diagnosis, typing, speech
  gender: Gender | null = null

  @Watch("step")
  onStepChange(): void {
    if (this.step < this.stepTimes.length && this.step != 2) {
      if (this.step == 3 && this.gender !== null && this.gender !== undefined) {
        this.$emit("nextOnbPart")
        return
      }
      this.stepper()
    } else if (this.step >= this.stepTimes.length) this.$emit("nextOnbPart")
  }

  created(): void {
    this.stepper()
  }
  getUserString_Gender(): string {
    if (this.gender != null)
      return this.$t("onboarding.user.my_gender_is") + " " + this.gender + "."
    return this.$t("onboarding.user.not_tell_gender") + ""
  }
}
