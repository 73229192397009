






































































/*

Button texts i18n

trackerEndDate: Used for date validators. If this field is empty, then this DurationPoint belongs to an active Tracker.
Therefore, the validator uses the today date instead of trackerEndDate

*/

import SymptomDurationPoint from "@/models/SymptomDurationPoint"
import SymptomDuration from "@/models/SymptomDuration"
import { Vue, Component, Prop, Watch } from "vue-property-decorator"
import ConfirmationDialog from "@/components/dialogs/ConfirmationDialog.vue"
import { formatDate } from "@/utils/utils"
import TrackingRepository from "@/api/TrackingRepository"
import InfoDialog from "@/components/dialogs/InfoDialog.vue"
import AddPointDialog from "@/components/dialogs/AddPointDialog.vue"

@Component({
  components: {
    ConfirmationDialog,
    InfoDialog,
    AddPointDialog
  }
})
export default class DurationPoint extends Vue {
  @Prop({ type: Object, required: true }) point!: SymptomDurationPoint
  @Prop({ type: Boolean, default: false }) isLastPoint!: boolean
  @Prop({ type: Number }) nextPageNumber: number | undefined
  @Prop({ type: Object, required: true }) symptom_duration!: SymptomDuration
  @Prop({ type: [String, Number], required: true }) valueBefore!: string | number
  @Prop({ type: Object, required: true }) unit!: { name: string; description: string }
  @Prop({ type: Boolean, default: false }) isFinishedPoint!: boolean
  showDeleteDialog: boolean = false
  showEditDialog: boolean = false
  showLastPointDialog: boolean = false
  trackerStartDate: string = "" // used for validators
  trackerEndDate: string = "" // used for validators
  intensityMax: number = 10
  intensityMin: number = 0

  get valueDifferenceNumerical(): number {
    return this.point.value - (this.valueBefore as number)
  }
  get valueTextColor(): "black" | "green" | "red" {
    if (this.valueDifferenceNumerical == 0) return "black"
    return this.valueDifferenceNumerical < 0 ? "green" : "red"
  }
  get valueDifference(): string {
    if (this.valueDifferenceNumerical == 0) return "-"
    const prefix = this.valueDifferenceNumerical > 0 ? "+" : ""
    const numDecimalValues = this.symptom_duration.symptom.is_searchable ? 0 : 2
    return `${prefix}${this.valueDifferenceNumerical.toFixed(numDecimalValues)}`
  }
  get valueStyleClass(): "intensity" | "temperature" | "weight" | "" {
    switch (this.unit.description) {
      case "Intensität":
        return "intensity"
      case "Temperatur":
        return "temperature"
      case "Gewicht":
        return "weight"
      default:
        return ""
    }
  }
  get date(): string {
    return formatDate(this.point.datetime, false)
  }
  get time(): string {
    return formatDate(this.point.datetime, true)
  }
  get value(): number {
    return this.point.value
  }

  evalDelete(): void {
    if (this.isLastPoint) this.showLastPointDialog = true
    else this.showDeleteDialog = true
  }
  async updateSymptomPoint(data: { date: string; time: string; value: Number }): Promise<void> {
    const v = String(data.value).replace(",", ".")
    const symptomPoint: SymptomDurationPoint = {
      id: this.point.id,
      symptom_duration: this.point.symptom_duration,
      datetime: `${data.date}T${data.time}Z`,
      value: Number(v)
    }

    await this.$store.dispatch("updateSymptomDurationPoint", {
      point: symptomPoint,
      isActive: !this.isFinishedPoint
    })

    const groupID = "TRAC:"
    await TrackingRepository.createLog(
      groupID +
        "Messpunkt von " +
        this.symptom_duration.symptom.name +
        " ändern mit Wert " +
        symptomPoint.value +
        " am " +
        symptomPoint.datetime
    )
  }
  async deleteSymptomPoint(): Promise<void> {
    this.showDeleteDialog = false
    await this.$store.dispatch("deleteSymptomDurationPoint", {
      point: this.point,
      symptom_id: this.symptom_duration.symptom.id,
      isActive: !this.isFinishedPoint,
      nextPageNumber: this.nextPageNumber
    })
    const groupID = "TRAC:"
    await TrackingRepository.createLog(
      groupID +
        "Messpunkt von " +
        this.symptom_duration.symptom.name +
        " löschen mit Wert " +
        String(this.value).replace(",", ".") +
        " am " +
        `${this.date}T${this.time}Z`
    )
  }
}
