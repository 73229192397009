import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"yc_top_bar"},[_c('div',{staticClass:"dialogTitle"},[_vm._v(_vm._s(_vm.$t('settings.headline')))]),_c(VBtn,{attrs:{"fab":"","small":"","id":"eventform-closebutton"},on:{"click":function($event){return _vm.$emit('close')}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VCard,{staticClass:"yc_exp_settings"},[_c('div',{staticClass:"hint_distance"},[_c('p',[_vm._v(_vm._s(_vm.$t('experience_mode.min_hint_dist')))]),_c('hint-distance-slider',{on:{"valueChanged":function($event){_vm.hint_dist=$event}}})],1),_c(VDivider),_c('div',{staticClass:"hint_type"},[_c('p',[_vm._v(_vm._s(_vm.$t('experience_mode.hint_type')))]),_c('hint-type-chooser',{on:{"hintTypeChanged":function($event){_vm.hint_type=$event}}})],1),_c(VDivider),_c('div',{staticClass:"save"},[_c(VCheckbox,{attrs:{"label":_vm.$t('experience_mode.remember_all')},model:{value:(_vm.remember),callback:function ($$v) {_vm.remember=$$v},expression:"remember"}}),_c(VCardActions,{staticClass:"actionButtons"},[_c(VSpacer),_c(VBtn,{staticClass:"yc_btn_primary",attrs:{"outlined":"","text":""},on:{"click":function($event){return _vm.savePreferences()}}},[_vm._v(_vm._s(_vm.$t('save')))])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }