export class AssetHelper {
  public static getDifficultyIcon(
    difficulty: number,
    eventType: string | null,
    isSelected: boolean
  ) {
    const icon = new DifficultyIcon(difficulty);
    if (isSelected) {
      return icon.getAsset(EDifficultyIconType.ACTIVE);
    } else {
      switch (eventType) {
        case "clinic":
          return icon.getAsset(EDifficultyIconType.CLINIC);
        case "home":
          return icon.getAsset(EDifficultyIconType.HOME);
        default:
          return icon.getAsset(EDifficultyIconType.PETROL);
      }
    }
  }

  public static getMoodIcon(
    mood: number,
    eventType: string | null,
    isSelected: boolean
  ) {
    const icon = new MoodIcon(mood);
    switch (eventType) {
      case "home": {
        if (isSelected) {
          return icon.getAsset(EMoodIconType.HOME_ACTIVE);
        } else {
          return icon.getAsset(EMoodIconType.HOME_DEFAULT);
        }
      }
      case "clinic": {
        if (isSelected) {
          return icon.getAsset(EMoodIconType.CLINIC_ACTIVE);
        } else {
          return icon.getAsset(EMoodIconType.CLINIC_DEFAULT);
        }
      }
      default:
        return icon.getAsset(EMoodIconType.PETROL);
    }
  }

  public static getAvatarIcon(index: number, isAnonymous = false) {
    const icon = new AvatarIcon(isAnonymous ? 0 : index);
    return icon.getAsset();
  }

  public static getDefaultInfoCardImage(): NodeRequire {
    return require("@/assets/landscape.png");
  }

  public static getCompanionIcon(): NodeRequire{
    return require("@/assets/companion/wizard1.svg");
  }
}

class DifficultyIcon {
  index: number;

  constructor(index: number) {
    this.index = index;
  }

  getAsset(type: EDifficultyIconType): NodeRequire {
    const icon: IDifficultyAsset | undefined = DIFFICULTY_ICONS.find(
      (asset: IDifficultyAsset) =>
        asset.difficultyIndex == this.index && asset.type == type
    );
    if (icon) {
      return icon.src;
    } else {
      return ERROR_ICON.src;
    }
  }
}
class MoodIcon {
  index: number;

  constructor(index: number) {
    this.index = index;
  }

  getAsset(type: EMoodIconType): NodeRequire {
    const icon: IMoodAsset | undefined = MOOD_ICONS.find(
      (asset: IMoodAsset) => asset.moodIndex == this.index && asset.type == type
    );
    if (icon) {
      return icon.src;
    } else {
      return ERROR_ICON.src;
    }
  }
}

class AvatarIcon {
  index: number;
  constructor(index: number) {
    this.index = index;
  }

  getAsset(): NodeRequire {
    const icon: IAvatarAsset | undefined = AVATAR_ASSETS.find(
      (asset: IAvatarAsset) => asset.avatarIndex == this.index
    );
    if (icon) {
      return icon.src;
    } else {
      return ERROR_ICON.src;
    }
  }
}

enum EDifficultyIconType {
  ACTIVE = "active",
  CLINIC = "clinic",
  HOME = "home",
  PETROL = "petrol",
}

enum EMoodIconType {
  CLINIC_ACTIVE = "clinic_active",
  CLINIC_DEFAULT = "clinic_default",
  HOME_ACTIVE = "home_active",
  HOME_DEFAULT = "home_default",
  PETROL = "petrol",
}

interface IAsset {
  src: NodeRequire;
}

interface IMoodAsset extends IAsset {
  moodIndex: number;
  type: EMoodIconType;
}

interface IDifficultyAsset extends IAsset {
  difficultyIndex: number;
  type: EDifficultyIconType;
}

interface IAvatarAsset extends IAsset {
  avatarIndex: number;
}
const ERROR_ICON: IAsset = {
  src: require("@/assets/icons/error.jpeg"),
};

const MOOD_ICONS: Array<IMoodAsset> = [
  {
    moodIndex: 1,
    type: EMoodIconType.CLINIC_ACTIVE,
    src: require("@/assets/icons/moods/clinic_active/mood_1.svg"),
  },
  {
    moodIndex: 2,
    type: EMoodIconType.CLINIC_ACTIVE,
    src: require("@/assets/icons/moods/clinic_active/mood_2.svg"),
  },
  {
    moodIndex: 3,
    type: EMoodIconType.CLINIC_ACTIVE,
    src: require("@/assets/icons/moods/clinic_active/mood_3.svg"),
  },
  {
    moodIndex: 4,
    type: EMoodIconType.CLINIC_ACTIVE,
    src: require("@/assets/icons/moods/clinic_active/mood_4.svg"),
  },
  {
    moodIndex: 5,
    type: EMoodIconType.CLINIC_ACTIVE,
    src: require("@/assets/icons/moods/clinic_active/mood_5.svg"),
  },
  {
    moodIndex: 6,
    type: EMoodIconType.CLINIC_ACTIVE,
    src: require("@/assets/icons/moods/clinic_active/mood_6.svg"),
  },
  {
    moodIndex: 1,
    type: EMoodIconType.CLINIC_DEFAULT,
    src: require("@/assets/icons/moods/clinic_default/mood_1.svg"),
  },
  {
    moodIndex: 2,
    type: EMoodIconType.CLINIC_DEFAULT,
    src: require("@/assets/icons/moods/clinic_default/mood_2.svg"),
  },
  {
    moodIndex: 3,
    type: EMoodIconType.CLINIC_DEFAULT,
    src: require("@/assets/icons/moods/clinic_default/mood_3.svg"),
  },
  {
    moodIndex: 4,
    type: EMoodIconType.CLINIC_DEFAULT,
    src: require("@/assets/icons/moods/clinic_default/mood_4.svg"),
  },
  {
    moodIndex: 5,
    type: EMoodIconType.CLINIC_DEFAULT,
    src: require("@/assets/icons/moods/clinic_default/mood_5.svg"),
  },
  {
    moodIndex: 6,
    type: EMoodIconType.CLINIC_DEFAULT,
    src: require("@/assets/icons/moods/clinic_default/mood_6.svg"),
  },

  {
    moodIndex: 1,
    type: EMoodIconType.HOME_ACTIVE,
    src: require("@/assets/icons/moods/home_active/mood_1.svg"),
  },
  {
    moodIndex: 2,
    type: EMoodIconType.HOME_ACTIVE,
    src: require("@/assets/icons/moods/home_active/mood_2.svg"),
  },
  {
    moodIndex: 3,
    type: EMoodIconType.HOME_ACTIVE,
    src: require("@/assets/icons/moods/home_active/mood_3.svg"),
  },
  {
    moodIndex: 4,
    type: EMoodIconType.HOME_ACTIVE,
    src: require("@/assets/icons/moods/home_active/mood_4.svg"),
  },
  {
    moodIndex: 5,
    type: EMoodIconType.HOME_ACTIVE,
    src: require("@/assets/icons/moods/home_active/mood_5.svg"),
  },
  {
    moodIndex: 6,
    type: EMoodIconType.HOME_ACTIVE,
    src: require("@/assets/icons/moods/home_active/mood_6.svg"),
  },

  {
    moodIndex: 1,
    type: EMoodIconType.HOME_DEFAULT,
    src: require("@/assets/icons/moods/home_default/mood_1.svg"),
  },
  {
    moodIndex: 2,
    type: EMoodIconType.HOME_DEFAULT,
    src: require("@/assets/icons/moods/home_default/mood_2.svg"),
  },
  {
    moodIndex: 3,
    type: EMoodIconType.HOME_DEFAULT,
    src: require("@/assets/icons/moods/home_default/mood_3.svg"),
  },
  {
    moodIndex: 4,
    type: EMoodIconType.HOME_DEFAULT,
    src: require("@/assets/icons/moods/home_default/mood_4.svg"),
  },
  {
    moodIndex: 5,
    type: EMoodIconType.HOME_DEFAULT,
    src: require("@/assets/icons/moods/home_default/mood_5.svg"),
  },
  {
    moodIndex: 6,
    type: EMoodIconType.HOME_DEFAULT,
    src: require("@/assets/icons/moods/home_default/mood_6.svg"),
  },
  {
    moodIndex: 1,
    type: EMoodIconType.PETROL,
    src: require("@/assets/icons/moods/petrol/mood_1.svg"),
  },
  {
    moodIndex: 2,
    type: EMoodIconType.PETROL,
    src: require("@/assets/icons/moods/petrol/mood_2.svg"),
  },
  {
    moodIndex: 3,
    type: EMoodIconType.PETROL,
    src: require("@/assets/icons/moods/petrol/mood_3.svg"),
  },
  {
    moodIndex: 4,
    type: EMoodIconType.PETROL,
    src: require("@/assets/icons/moods/petrol/mood_4.svg"),
  },
  {
    moodIndex: 5,
    type: EMoodIconType.PETROL,
    src: require("@/assets/icons/moods/petrol/mood_5.svg"),
  },
  {
    moodIndex: 6,
    type: EMoodIconType.PETROL,
    src: require("@/assets/icons/moods/petrol/mood_6.svg"),
  },
];

const DIFFICULTY_ICONS: Array<IDifficultyAsset> = [
  {
    difficultyIndex: 1,
    type: EDifficultyIconType.ACTIVE,
    src: require("@/assets/icons/difficulty/active/difficulty_1.png"),
  },
  {
    difficultyIndex: 2,
    type: EDifficultyIconType.ACTIVE,
    src: require("@/assets/icons/difficulty/active/difficulty_2.png"),
  },
  {
    difficultyIndex: 3,
    type: EDifficultyIconType.ACTIVE,
    src: require("@/assets/icons/difficulty/active/difficulty_3.png"),
  },
  {
    difficultyIndex: 4,
    type: EDifficultyIconType.ACTIVE,
    src: require("@/assets/icons/difficulty/active/difficulty_4.png"),
  },
  {
    difficultyIndex: 1,
    type: EDifficultyIconType.CLINIC,
    src: require("@/assets/icons/difficulty/clinic/difficulty_1.png"),
  },
  {
    difficultyIndex: 2,
    type: EDifficultyIconType.CLINIC,
    src: require("@/assets/icons/difficulty/clinic/difficulty_2.png"),
  },
  {
    difficultyIndex: 3,
    type: EDifficultyIconType.CLINIC,
    src: require("@/assets/icons/difficulty/clinic/difficulty_3.png"),
  },
  {
    difficultyIndex: 4,
    type: EDifficultyIconType.CLINIC,
    src: require("@/assets/icons/difficulty/clinic/difficulty_4.png"),
  },
  {
    difficultyIndex: 1,
    type: EDifficultyIconType.HOME,
    src: require("@/assets/icons/difficulty/home/difficulty_1.png"),
  },
  {
    difficultyIndex: 2,
    type: EDifficultyIconType.HOME,
    src: require("@/assets/icons/difficulty/home/difficulty_2.png"),
  },
  {
    difficultyIndex: 3,
    type: EDifficultyIconType.HOME,
    src: require("@/assets/icons/difficulty/home/difficulty_3.png"),
  },
  {
    difficultyIndex: 4,
    type: EDifficultyIconType.HOME,
    src: require("@/assets/icons/difficulty/home/difficulty_4.png"),
  },
  {
    difficultyIndex: 1,
    type: EDifficultyIconType.PETROL,
    src: require("@/assets/icons/difficulty/petrol/difficulty_1.png"),
  },
  {
    difficultyIndex: 2,
    type: EDifficultyIconType.PETROL,
    src: require("@/assets/icons/difficulty/petrol/difficulty_2.png"),
  },
  {
    difficultyIndex: 3,
    type: EDifficultyIconType.PETROL,
    src: require("@/assets/icons/difficulty/petrol/difficulty_3.png"),
  },
  {
    difficultyIndex: 4,
    type: EDifficultyIconType.PETROL,
    src: require("@/assets/icons/difficulty/petrol/difficulty_4.png"),
  },
];

const AVATAR_ASSETS: Array<IAvatarAsset> = [
  {
    avatarIndex: 1,
    src: require("@/assets/avatars/Avatar_1.png"),
  },
  {
    avatarIndex: 2,
    src: require("@/assets/avatars/Avatar_2.png"),
  },
  {
    avatarIndex: 3,
    src: require("@/assets/avatars/Avatar_3.png"),
  },
  {
    avatarIndex: 4,
    src: require("@/assets/avatars/Avatar_4.png"),
  },
  {
    avatarIndex: 5,
    src: require("@/assets/avatars/Avatar_5.png"),
  },
  {
    avatarIndex: 6,
    src: require("@/assets/avatars/Avatar_6.png"),
  },
  {
    avatarIndex: 7,
    src: require("@/assets/avatars/Avatar_7.png"),
  },
  {
    avatarIndex: 8,
    src: require("@/assets/avatars/Avatar_8.png"),
  },
  {
    avatarIndex: 9,
    src: require("@/assets/avatars/Avatar_9.png"),
  },
  {
    avatarIndex: 10,
    src: require("@/assets/avatars/Avatar_10.png"),
  },
  {
    avatarIndex: 11,
    src: require("@/assets/avatars/Avatar_11.png"),
  },
  {
    avatarIndex: 12,
    src: require("@/assets/avatars/Avatar_12.png"),
  },
  {
    avatarIndex: 13,
    src: require("@/assets/avatars/Avatar_13.png"),
  },
  {
    avatarIndex: 14,
    src: require("@/assets/avatars/Avatar_14.png"),
  },
  {
    avatarIndex: 15,
    src: require("@/assets/avatars/Avatar_15.png"),
  },
  {
    avatarIndex: 16,
    src: require("@/assets/avatars/Avatar_16.png"),
  },
  {
    avatarIndex: 17,
    src: require("@/assets/avatars/Avatar_17.png"),
  },
  {
    avatarIndex: 18,
    src: require("@/assets/avatars/Avatar_18.png"),
  },
  {
    avatarIndex: 19,
    src: require("@/assets/avatars/Avatar_19.png"),
  },
  {
    avatarIndex: 20,
    src: require("@/assets/avatars/Avatar_20.png"),
  },
  {
    avatarIndex: 21,
    src: require("@/assets/avatars/Avatar_21.png"),
  },
  {
    avatarIndex: 22,
    src: require("@/assets/avatars/Avatar_22.png"),
  },
  {
    avatarIndex: 23,
    src: require("@/assets/avatars/Avatar_23.png"),
  },
  {
    avatarIndex: 24,
    src: require("@/assets/avatars/Avatar_24.png"),
  },
  {
    avatarIndex: 25,
    src: require("@/assets/avatars/Avatar_25.png"),
  },
  {
    avatarIndex: 26,
    src: require("@/assets/avatars/Avatar_26.png"),
  },
  {
    avatarIndex: 27,
    src: require("@/assets/avatars/Avatar_27.png"),
  },
  {
    avatarIndex: 28,
    src: require("@/assets/avatars/Avatar_28.png"),
  },
  {
    avatarIndex: 29,
    src: require("@/assets/avatars/Avatar_29.png"),
  },
  {
    avatarIndex: 30,
    src: require("@/assets/avatars/Avatar_30.png"),
  },
  {
    avatarIndex: 31,
    src: require("@/assets/avatars/Avatar_31.png"),
  },
  {
    avatarIndex: 32,
    src: require("@/assets/avatars/Avatar_32.png"),
  },
  {
    avatarIndex: 33,
    src: require("@/assets/avatars/Avatar_33.png"),
  },
  {
    avatarIndex: 34,
    src: require("@/assets/avatars/Avatar_34.png"),
  },
  {
    avatarIndex: 35,
    src: require("@/assets/avatars/Avatar_35.png"),
  },
  {
    avatarIndex: 36,
    src: require("@/assets/avatars/Avatar_36.png"),
  },
  {
    avatarIndex: 37,
    src: require("@/assets/avatars/Avatar_37.png"),
  },
  {
    avatarIndex: 38,
    src: require("@/assets/avatars/Avatar_38.png"),
  },
  {
    avatarIndex: 39,
    src: require("@/assets/avatars/Avatar_39.png"),
  },
  {
    avatarIndex: 40,
    src: require("@/assets/avatars/Avatar_40.png"),
  },
  {
    avatarIndex: 41,
    src: require("@/assets/avatars/Avatar_41.png"),
  },
  {
    avatarIndex: 42,
    src: require("@/assets/avatars/Avatar_42.png"),
  },
  {
    avatarIndex: 43,
    src: require("@/assets/avatars/Avatar_43.png"),
  },
  {
    avatarIndex: 44,
    src: require("@/assets/avatars/Avatar_44.png"),
  },
  {
    avatarIndex: 45,
    src: require("@/assets/avatars/Avatar_45.png"),
  },
  {
    avatarIndex: 46,
    src: require("@/assets/avatars/Avatar_46.png"),
  },
  {
    avatarIndex: 47,
    src: require("@/assets/avatars/Avatar_47.png"),
  },
  {
    avatarIndex: 48,
    src: require("@/assets/avatars/Avatar_48.png"),
  },
  {
    avatarIndex: 49,
    src: require("@/assets/avatars/Avatar_49.png"),
  },
  {
    avatarIndex: 50,
    src: require("@/assets/avatars/Avatar_50.png"),
  },
  {
    avatarIndex: 51,
    src: require("@/assets/avatars/Avatar_51.png"),
  },
  {
    avatarIndex: 52,
    src: require("@/assets/avatars/Avatar_52.png"),
  },
  {
    avatarIndex: 53,
    src: require("@/assets/avatars/Avatar_53.png"),
  },
  {
    avatarIndex: 54,
    src: require("@/assets/avatars/Avatar_54.png"),
  },
  {
    avatarIndex: 55,
    src: require("@/assets/avatars/Avatar_55.png"),
  },
];
