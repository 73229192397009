







import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class LoadingSpinner extends Vue {
  @Prop({type: Boolean, default: false}) noText!: boolean
}
