














































import { Component, Prop, Watch } from 'vue-property-decorator'
import VueStepper from './VueStepper'
import Speechbubble from '@/components/onboarding/Speechbubble.vue'
import Companion from '@/components/onboarding/Companion.vue'
import TypingBubble from '@/components/onboarding/onb_chat/TypingBubble.vue'
import TextDateInput from '@/components/onboarding/onb_chat/TextDateInput.vue'

@Component({
    components: {
      Speechbubble,
      Companion,
      TypingBubble,
      TextDateInput
    }
})
export default class InputInitialDiagnosis extends VueStepper {
    @Prop({type: String, default: ''}) avatarPath!: string
    @Prop({type: String, default: ''}) companionText!: string
    @Prop({type: String, default: ''}) diagnosis!: string
    stepTimes: number[] = [2000, 2000, 0, 1500, 2000, 2000] //typing, question diagnosis, diagnosis input, waiting after diagnosis, typing, speech
    initialDiagnosis: string = ''

    getUserString_NotTellInitialDiagnosis(): string{
        if(this.diagnosis)
            return this.$t('onboarding.user.not_know_initial_diagnosis')+"";
        return this.$t('onboarding.user.not_tell_initial_diagnosis')+'';
    }
    getUserString_InitialDiagnosis(): string{
        if(this.initialDiagnosis)
            return this.$t('onboarding.user.my_initaldiagnosis_date_is')+' '+this.initialDiagnosisLocalString+'.';
        else if(this.diagnosis)
            return this.$t('onboarding.user.not_know_initial_diagnosis')+'';
        return this.$t('onboarding.user.not_tell_initial_diagnosis')+'';
    }
    getCompanionString_ReplyInitialDiagnosis(): string{
        if(this.initialDiagnosis)
            return this.$t("onboarding.companion.reply_initial")+'';
        return this.$t('onboarding.companion.reply_understanding')+'';
    }

    get initialDiagnosisLocalString(): string{
        if(this.initialDiagnosis)
            return new Date(this.initialDiagnosis).toLocaleDateString();
        return 'Error: Inital diagnosis not set.'
    }

    created(): void{
        this.stepper();
    }

    @Watch('step')
    onStepChange() {
        if(this.step < this.stepTimes.length && this.step != 2)
            this.stepper();
        else if(this.step >= this.stepTimes.length)
            this.$emit('nextOnbPart');
    }
}
