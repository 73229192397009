































import { Component, Watch } from 'vue-property-decorator'
import VueStepper from './VueStepper'
import Speechbubble from '@/components/onboarding/Speechbubble.vue'
import Companion from '@/components/onboarding/Companion.vue'
import TypingBubble from '@/components/onboarding/onb_chat/TypingBubble.vue'

@Component({
  components: {
    Speechbubble,
    Companion,
    TypingBubble
  }
})
export default class InputLanguage extends VueStepper {
  stepTimes: number[] = [2000, 0] // typing, speech, showing language choice carousel, show selected language
  language: string = navigator.language.substring(0, 2).toLowerCase()
  available: SelectField[] = []

  created(): void {
    Object.keys(this.$i18n.messages).forEach(value => {
      this.available.push({
        text: <string>this.$i18n.messages[value].__language__,
        value
      })
    })
    if (!this.available.find(x => x.value == this.language)) {
      const { value } = this.available[0]
      this.language = value
      this.$i18n.locale = value
    }
      
    this.stepper()
  }

  get langName(): string {
    return this.available.find(x => x.value == this.language)?.text || this.available[0].text
  }

  @Watch('step')
  onStepChange() {
    if(this.step >= this.stepTimes.length)
      this.$emit('nextOnbPart');
    else if(this.step != 1)
      this.stepper();
  }

  @Watch('language')
  onLanguageChange(lang: string) {
    this.$emit('languageChanged', lang)
  }
}

interface SelectField {
  text: string
  value: string
}
