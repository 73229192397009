






















































































































































































































import { Component, Prop, Watch, Vue } from "vue-property-decorator"
import EventRepository from "@/api/EventRepository"
import EventForm from "@/views/EventForm.vue"
import Content from "@/models/Content"
import Event from "@/models/Event"
import VueEasyLightbox from "vue-easy-lightbox"
import StepperBar from "@/components/StepperBar.vue"
import LoadingSpinner from "@/components/LoadingSpinner.vue"
import MediaRepository from "@/api/MediaRepository"
import { AssetHelper } from "@/utils/AssetHelper"
import TrackingRepository from "@/api/TrackingRepository"
import { YCMediaSource } from "@/models/Media"
import EventVideo from "@/components/EventVideo.vue"

@Component({
  components: {
    EventForm,
    VueEasyLightbox,
    StepperBar,
    LoadingSpinner,
    EventVideo
  }
})
export default class ShowEvent extends Vue {
  @Prop({ type: Number }) id!: number | null
  @Prop({ type: Boolean, default: true }) showNav!: boolean
  @Prop({ type: Boolean, default: true }) showEdit!: boolean
  @Prop({ type: Boolean, default: false }) showMap!: boolean
  @Prop({ type: Boolean, default: false }) value!: boolean
  showDialog: boolean = false
  event: Event = {} as Event
  contents: Content[] = []
  images: string[] = []
  video: YCMediaSource[] = []
  audio: string[] = []
  deleteDialog: boolean = false
  editMode: boolean = false
  loaded: boolean = false
  edited: boolean = false
  timestamp: number = Date.now()
  index: number = 0
  visible: boolean = false
  neighbors: EventNeighbor = {} as EventNeighbor
  isLoading: ILoading = {
    images: false,
    audio: false,
    video: false
  }

  get getDate(): Date {
    return new Date(this.event.datetime)
  }
  get day(): string {
    var options = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric"
    } as const

    return new Date(this.event.datetime).toLocaleDateString("de-DE", options)
  }
  get time(): string {
    return new Date(this.event.datetime).toLocaleTimeString().substring(0, 5)
    //return this.date.getHours() + ":" + (this.date.getMinutes() < 10 ? "0"+this.date.getMinutes() : this.date.getMinutes());
  }
  get weekday(): string {
    let date = new Date(this.event.datetime)
    let weekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
    return weekdays[date.getDay()]
  }
  get totalEvents(): number {
    return this.$store.getters.eventCountTotal
  }

  @Watch("showDialog")
  async onShowDialogChange(newVal: boolean) {
    this.$emit("input", newVal)
    if (!newVal) {
      this.$emit("disableEventform")
      this._unload()
    } else if (!this.loaded) await this._load()
  }
  @Watch("value")
  onValueChange(newVal: boolean) {
    this.showDialog = newVal
  }
  @Watch("id")
  async onIdChange(newId: number) {
    this._unload()
    if (newId > 0) await this._load()
  }

  async mounted(): Promise<void> {
    this.showDialog = this.value
  }
  getMoodIcon(): NodeRequire {
    return AssetHelper.getMoodIcon(this.event.mood, this.event.location, false)
  }
  async loadEvent(): Promise<void> {
    try {
      this.event = await EventRepository.getEvent(this.id as number)
    } catch (error) {
      this.$store.dispatch("alert/error", this.$t("error_messages.event"))
      /*eslint-disable no-console */
      console.error("error while loading event: " + error)
    }
  }
  loadImages(): void {
    const cons = this.contents.filter((content) => content.content_type.includes("image"))
    this.isLoading.images = true
    //@ts-ignore
    this.getContents(cons, (imageDataUri: string) => {
      this.images.push(imageDataUri)
    }).finally(() => {
      this.isLoading.images = false
    })
  }
  loadAudios(): void {
    var cons = this.contents.filter((content) => content.content_type.includes("audio"))
    this.isLoading.audio = true
    //@ts-ignore
    this.getContents(cons, (audioDataUri: string) => {
      this.audio.push(audioDataUri)
    }).finally(() => (this.isLoading.audio = false))
  }
  loadVideos(): void {
    var cons = this.contents.filter((content) => content.content_type.includes("video"))
    this.isLoading.video = true
    cons.forEach((content) => {
      const filename = content.file.substr(content.file.lastIndexOf("/") + 1)
      const token = JSON.parse(
        localStorage.getItem(`auth-tokens-${process.env.NODE_ENV}`) || '{accessToken:""}'
      ).accessToken
      const url = `${process.env.VUE_APP_API_HOST}/api/v1/protectedmedia/?filename=${filename}&folder=content&token=${token}`
      this.video.push({
        url,
        type: content.content_type
      })
    })
    this.isLoading.video = false
  }
  changeEventView(event_id: number): void {
    this.$emit("changeEventId", event_id)
  }
  async deleteEvent(): Promise<void> {
    try {
      await this.$store.dispatch("deleteEvent", this.event.id)
      this.deleteDialog = false
      this.changeEventView(-2)
      this.$emit("eventDeleted", this.event.id)
      this.logDelete()
    } catch (error) {
      this.$store.dispatch("alert/error", this.$t("error_messages.delete_event"))
      /*eslint-disable no-console */
      console.error("error while deleting event: " + error)
    }
  }
  buildContentLog(): any {
    let groupID = "HOME:"
    let location = "im Homescreen"

    if (!this.showNav && this.showEdit && this.showMap) location = "in Eventsuche"
    if (!this.showNav && !this.showEdit && this.showMap) {
      location = "in Ereigniskette"
      groupID = "PROF:"
    }
    if (!this.showNav && !this.showEdit && !this.showMap) {
      location = "im Erlebenmodus"
      groupID = "EXPE:"
    }

    const hasText = this.event.text != "" ? " mit Text" : ""
    const numVideos = this.video.length > 0 ? ", " + this.video.length + " Video(s)" : ""
    const numImages = this.audio.length > 0 ? ", " + this.audio.length + " Audio(s)" : ""
    const numAudios = this.images.length > 0 ? ", " + this.images.length + " Bild(er)" : ""
    // @ts-ignore
    const activity = this.event.activity["description"]

    return {
      groupID: groupID,
      location: location,
      hasText: hasText,
      numVideos: numVideos,
      numImages: numImages,
      numAudios: numAudios,
      activity: activity
    }
  }
  async logView(): Promise<void> {
    const data = this.buildContentLog()
    await TrackingRepository.createLog(
      data.groupID +
        "Event ansehen " +
        data.location +
        " mit Stimmung '" +
        this.$t("moods.mood_" + this.event.mood) +
        "', Schwierigkeit '" +
        this.$t("difficultyLevel.difficulty_" + this.event.difficulty) +
        "', Timestamp '" +
        this.event.datetime +
        "', Ort '" +
        this.event.location +
        "', Event '" +
        this.$t("activities." + data.activity) +
        "'" +
        data.hasText +
        data.numImages +
        data.numVideos +
        data.numAudios
    )
  }
  async logEdit(): Promise<void> {
    const data = this.buildContentLog()
    await TrackingRepository.createLog(
      data.groupID +
        "Event editieren " +
        data.location +
        " mit Stimmung '" +
        this.$t("moods.mood_" + this.event.mood) +
        "', Schwierigkeit '" +
        this.$t("difficultyLevel.difficulty_" + this.event.difficulty) +
        "', Timestamp '" +
        this.event.datetime +
        "', Ort '" +
        this.event.location +
        "', Event '" +
        this.$t("activities." + data.activity) +
        "'" +
        data.hasText +
        data.numImages +
        data.numVideos +
        data.numAudios
    )
  }
  async logDelete(): Promise<void> {
    const data = this.buildContentLog()
    await TrackingRepository.createLog(
      data.groupID +
        "Event löschen " +
        data.location +
        " mit Stimmung '" +
        this.$t("moods.mood_" + this.event.mood) +
        "', Schwierigkeit '" +
        this.$t("difficultyLevel.difficulty_" + this.event.difficulty) +
        "', Timestamp '" +
        this.event.datetime +
        "', Ort '" +
        this.event.location +
        "', Event '" +
        this.$t("activities." + data.activity) +
        "'" +
        data.hasText +
        data.numImages +
        data.numVideos +
        data.numAudios
    )
  }
  async load(): Promise<void> {
    await this.loadEvent()
    this.loaded = true
    this.contents = this.event.contents

    //deavtivated until loading is fixed
    this.loadImages()
    this.loadAudios()
    this.loadVideos()
  }
  showImg(index: number): void {
    this.index = index
    this.visible = true
    let dialog = document.getElementsByClassName("yc_dialog_eventview")[0] as HTMLElement
    dialog.style.overflowY = "hidden"
  }
  handleHide(): void {
    this.visible = false
    let dialog = document.getElementsByClassName("yc_dialog_eventview")[0] as HTMLElement
    dialog.style.overflowY = "scroll"
  }
  async openMap(): Promise<void> {
    const data = this.buildContentLog()
    await TrackingRepository.createLog(
      data.groupID +
        "Navigation " +
        data.location +
        " zu Homescreen mit Stimmung '" +
        this.$t("moods.mood_" + this.event.mood) +
        "', Schwierigkeit '" +
        this.$t("difficultyLevel.difficulty_" + this.event.difficulty) +
        "', Timestamp '" +
        this.event.datetime +
        "', Ort '" +
        this.event.location +
        "', Event '" +
        this.$t("activities." + data.activity) +
        "'" +
        data.hasText +
        data.numImages +
        data.numVideos +
        data.numAudios
    )

    this.changeEventView(-1)
    this.$router.push({
      name: "home",
      params: {
        currEvent: this.event.id
      } as any
    })
  }
  /*
  TODO this could be optimized into a bulk operation
  -> backend request returns an array of objects
  */
  async getContents(contents: Content[], onResult: () => void): Promise<void> {
    await Promise.all(contents.map((con) => this.getContent(con).then(onResult)))
  }
  async getContent(content: Content): Promise<string> {
    const encoded = await MediaRepository.getEncodedContent("content", content.file)
    return encoded.toDataURI()
  }
  async _load(): Promise<void> {
    if (!this.id) return void 0
    await this.load()
    await this.logView()
    await this.$store.dispatch("updateForNeighbors", this.event.id)
    this.neighbors = this.$store.getters.getNeighbors(this.event.id)
  }
  _unload(): void {
    this.event = <Event>{}
    this.loaded = false
    for (const f of ["contents", "images", "video", "audio"]) {
      ;(this as any)[f] = []
    }
  }
}

interface EventNeighbor {
  before: Event
  after: Event
}

interface ILoading {
  images: boolean
  audio: boolean
  video: boolean
}
