import { Module } from "vuex"
import Relation from "@/models/Relation"
import { RelationState, RootState } from "../types"
import RelationsRepository from "@/api/RelationsRepository"
import i18n from "@/locales/i18n"

const relation: Module<RelationState, RootState> = {
  state: { relations: [] as Relation[] },
  mutations: {
    addRelation(state: any, payload: Relation) {
      state.relations.push(payload)
    },
    addRelations(state: any, payload: Array<Relation>) {
      state.relations = state.relations.concat(payload)
    },
    updateRelation(state: any, payload: Relation) {
      const index = state.relations.findIndex((relation: Relation) => relation.id === payload.id)
      Object.assign(state.relations[index], payload)
    },
    removeRelation(state: any, relation_id: number) {
      const index = state.relations.findIndex((relation: Relation) => relation.id === relation_id)
      state.relations.splice(index, 1)
    },
    clearRelations(state: any) {
      state.relations = []
    }
  },
  actions: {
    async fetchRelations({ dispatch, commit, state, rootState }) {
      // check if userid has changed
      try {
        if (state.relations.length > 0) {
          commit("clearRelations")
        }
        const relations = await RelationsRepository.getRelations()
        const data = relations.map((relation: Relation) => {
          relation.userToDisplay =
            rootState.account.user.id !== relation.to_user.id ? "to_user" : "from_user"
          return relation
        })
        commit("addRelations", data)
      } catch (error) {
        dispatch("alert/error", i18n.t("error_messages.relations"))
      }
    },
    async block({ commit, state, dispatch }, payload: any) {
      try {
        if (payload.relation && payload.user) {
          const response = await RelationsRepository.blockUser(payload.user)
          commit("removeRelation", payload.relation.id)
          commit("addRelation", response)
        } else if (payload.user) {
          const response = await RelationsRepository.blockUser(payload.user)
          commit("addRelation", response)
        }
      } catch (error) {
        dispatch("alert/error", i18n.t("error_messages.block"))
      }
    },
    async deleteRequest({ commit, state, dispatch }, relationsId: number) {
      try {
        await RelationsRepository.deleteRelationRequest(relationsId)
        commit("removeRelation", relationsId)
      } catch (error) {
        dispatch("alert/error", i18n.t("error_messages.change_relation"))
        /* eslint-disable no-console */
        console.error("Error while deleting relation request: " + error)
      }
    }
  },
  getters: {
    relations: (state) => {
      return state.relations.filter((relation: Relation) => relation.status === "confirmed")
    },
    relationRequests: (state) => {
      return state.relations.filter((relation: Relation) => relation.status === "requested")
    },
    blocked: (state) => {
      return state.relations.filter((relation: Relation) => relation.status === "blocked")
    },
    sendRequests: (state, getters, rootState) => {
      return getters.relationRequests.filter(
        (req: any) => req.to_user.id !== rootState.account.user.id
      )
    },
    receivedRequests: (state, getters, rootState) => {
      return getters.relationRequests.filter(
        (req: any) => req.to_user.id === rootState.account.user.id
      )
    },
    blockedUsers: (state, getters, rootState) => {
      return getters.blocked
    }
  }
}

export default relation
