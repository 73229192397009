


























import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

/*
  TODO: initialize theme on app wide level
*/
import { Theme, ThemeLoader, ThemeName, EventImages } from "@/components/themes/theme";
import EventButton from "@/components/buttons/EventButton.vue";
import Event from '@/models/Event';

const theme: Theme = ThemeLoader.getTheme(ThemeName.vector);

// const SCALING_FACTOR = 75;

@Component({
  components: {
    EventButton
  }
})
export default class ImageStack extends Vue {
  @Prop({type: Object}) event: Event | undefined
  @Prop({type: Number, default: 0}) eventIndex!: number
  @Prop({type: Boolean, default: false}) isFirst!: boolean
  @Prop({type: Boolean, default: false}) isLast!: boolean
  @Prop({type: Boolean, default: false}) highlighted!: boolean
  showEvent: boolean = false
  images: EventImages = {} as EventImages
  initialized: boolean = false

  @Watch('eventIndex')
  onEventIndexChange() {
    this.getImagesForEvent()
  }

  get mountainZIndex(): number {
    return this.images.backgroundImages.length;
  }
  get mountainStyle(): object {
    let mountainHeight = 100;
    // let mountainHeight = 100 + (this.event.difficulty - 1) * SCALING_FACTOR;
    // Set other width for first/last mountain because of cut off
    let mountainWidth = (this.isFirst) ? 125 : 100;
    return {
      backgroundImage: `url(${this.getMountainImage()})`,
      backgroundSize: `${mountainWidth}% ${mountainHeight}%`,
      zIndex: this.mountainZIndex,
      // transitionDelay: this.eventIndex * 700 + 'ms'
    }
  }
  created(): void {
      this.getImagesForEvent();
      setTimeout(() => {
          this.initialized = true;
      }, 1000);
  }
  getMountainImage(): string {
    return this.images.mountainImage;
  }
  getImagesForEvent(): void {
    if(this.event) {
      if(this.event.difficulty > 1)
        this.images = theme.getImagesForEvent(this.eventIndex, this.event.difficulty - 1);
      else 
        this.images = theme.getImagesForEvent(this.eventIndex, -1);
    }
    else
      this.images = theme.getImagesForEvent(this.eventIndex, -1);
  }
  openEvent(event_id: number): void {
    this.$emit('openEvent', event_id);
  }
  toLocalDate(date: string): string {
    return new Date(date).toLocaleDateString();
  }
}
