






import { Component, Prop, Vue } from "vue-property-decorator"
import { TranslateResult } from "vue-i18n"
import { getDateDiffDays } from "@/utils/utils"
import SymptomDurationPoint from "@/models/SymptomDurationPoint"

@Component({})
export default class BasicNotification extends Vue {
  @Prop({ type: Object, default: () => null }) mostRecentSymptomPoint!: SymptomDurationPoint

  get notificationText(): TranslateResult | null {
    if (!this.mostRecentSymptomPoint) {
      return null
    }
    const numDays = getDateDiffDays(new Date(this.mostRecentSymptomPoint.datetime))
    if (numDays < 1) {
      return null
    }
    return this.$t("notificationsText.ongoing-symptom.trackerText", {
      numDays
    })
  }
}
