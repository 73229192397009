import { Module } from "vuex";
import { MoodState, RootState } from "../types";
import DailyMood from "@/models/DailyMood";
import DailyMoodRepository from "@/api/DailyMoodRepository";
import i18n from "@/locales/i18n";
import { lastDayOfDecade } from "date-fns";

const DAILY_MOOD_MUTATION = "SET_DAILY";
const SET_MOODS_MUTATION = "SET_MOODS";

export const moodModule: Module<MoodState, RootState> = {
  state: {
    yesterday: undefined,
    moods: [] as DailyMood[],
  },
  getters: {
    moods:
      (state) =>
      (range: number): DailyMood[] => {
        const length = state.moods.length;
        return state.moods.slice(length - range);
      },
    yesterdaysMood: (state): DailyMood | undefined => {
      return state.yesterday;
    },
  },
  mutations: {
    [DAILY_MOOD_MUTATION](state, mood: DailyMood) {
      state.yesterday = mood;
      const lastMood = state.moods[state.moods.length - 1];
      if (lastMood && lastMood.date === mood.date) {
        state.moods[state.moods.length - 1] = mood;
      } else {
        state.moods = [...state.moods, mood];
      }
    },
    [SET_MOODS_MUTATION](state, moods: DailyMood[]) {
      state.moods = moods;

      //set yestedays mood if available
      const lastMood = moods[moods.length - 1];
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      if (
        lastMood &&
        new Date(lastMood.date).toDateString() === yesterday.toDateString()
      ) {
        state.yesterday = lastMood;
      }
    },
  },
  actions: {
    async loadMoods({ commit }) {
      try {
        const moods = await DailyMoodRepository.get();
        commit(SET_MOODS_MUTATION, moods);
      } catch (error) {
        this.dispatch("alert/error", i18n.t("error_messages.moods"));
        /*eslint-disable no-console */
        console.error("error while fetching moods: " + error);
      }
    },
    async setDailyMood({ commit }, mood: number) {
      //Calculate date for day before, because user stores Daily Mood of previous day
      const date = new Date();
      date.setDate(date.getDate() - 1);
      try {
        const daily = await DailyMoodRepository.post(mood, date);
        commit(DAILY_MOOD_MUTATION, daily);
      } catch (error) {
        this.dispatch("alert/error", i18n.t("error_messages.daily_mood"));
        /*eslint-disable no-console */
        console.error("error while setting daily mood: " + error);
      }
    },
  },
};

export default moodModule;
