import Repository from "@/api/Repository";
import DailyMood from "@/models/DailyMood";

const resource = "/v1/moods/";

class DailyMoodRepository {

  async get(): Promise<DailyMood[]> {
    const response = await Repository.get(resource);
    return await response.json();
  }

  async getForDate(date: Date): Promise<DailyMood> {
    const uri = resource + date.toISOString().substring(0, 10);
    const response = await Repository.get(uri);
    return await response.json();
  }

  async post(mood: number, date: Date): Promise<DailyMood> {
    const response = await Repository.post(resource, {
        "mood": mood,
        "date": date.toISOString().split('T')[0],
    });
    return await response.json();
  }
}

export default new DailyMoodRepository();